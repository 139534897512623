import { useEffect, useState } from "react"
import CustomModal from "../../components/common/Modal"
import QrScanner from "../../components/common/QrScanner";

export const ProductPieceActions = ({
  modalOpen,
  setModalOpen,
}) => {
  const modes = {
    "update": "Scan to update Piece",
    "manualInsert":"Manual Write ID",
    "newUniqe": "Generate a new QR",
  };
  const [mode, setMode] = useState("manualInsert");
  const [scanner, setScanner] = useState(true);
  const [newID, setNewID] = useState();

  const handleScan = async (scannedItem) => {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/productPieces/get/${scannedItem}`);
    if (res.status !== 400) return alert("This QR is already in the database");
  }

  useEffect(() => {
    setNewID();
  },[mode])
  
  return (
    <CustomModal modalOpen={modalOpen} setModalOpen={setModalOpen} title={"Add"}>
      <div className="flex items-center justify-between border-2 border-gray-300 shadow-lg rounded-lg mb-6">
        <div className="bg-gray-300 rounded flex">
        {Object.keys(modes).map((key, idx) => (
          <div className={` text-white ${mode===key ? 'bg-blue-500 rounded-lg shadow-2xl ' : "bg-gray-300 !text-black cursor-pointer hover:bg-blue-300 rounded-lg transition-all"}  py-2 px-2`} onClick={()=>setMode(key)}>
            {modes[key]}
          </div>
        ))}
        </div>
      </div>
      {mode === 'scan' && <QrScanner onScanFn={handleScan} showScanner={scanner} setShowScanner={setScanner} getPieceData={false} />}
      {mode === "manualInsert" && (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <p className="text-red-500 font-bold">This Option is for inserting an ID that is on the sticker that is not considered available on the system!</p>
          <label className="font-bold mb-2">ID</label>
          <input type="text" value={newID} onChange={(e) => setNewID(e.target.value)} className="rounded" />
          <button className="rounded text-white px-2 py-2 bg-green-500 mt-4">Submit</button>
        </div>
      )}
    </CustomModal>
  )
}