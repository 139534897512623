import { ExpenseList } from "./ExpenseList";
import { InputField } from "../../../components/common/InputFields/InputField";

export const RightSide = ({ order, expenses, setOrder, setNoteError, noteError }) => {
  const handleDeliveryChange = (key, value)=>{
    setOrder((prev)=>({...prev, [key]:value}))
  }
  const addExpense = () => setOrder(prev=>({...prev, expenses: [...prev.expenses, {expenseID:"", amount:0}]}));

  // Delete an expense
  const deleteExpense = (expenseIndex) => setOrder(prev=>({...prev, expenses:prev.expenses.filter((_,idx)=>idx !== expenseIndex)}))

  const editExpense = (idx, key, value) => {
    const allExpenses = order.expenses
    allExpenses[idx][key] = value;
    setOrder((prev) => ({ ...prev, expenses: allExpenses}));
  }
  return (
    <div className="lg:col-span-4 col-span-12 border-t-2 border-black lg:border-t-0 lg:border-l-2 lg:pl-3">
      <h2 className="font-bold mb-3">Additional Expenses</h2>
      
      <ExpenseList 
        expenses={order?.expenses} 
        availableExpenses={expenses}
        onEditExpense={editExpense} 
        deleteExpense={deleteExpense}
        addExpense={addExpense}
        editExpense={editExpense}
      />
      
      <div className="mt-12">
        <InputField
          label="Delivery Amount"
          type="number"
          min={0}
          name="deliveryAmount"
          value={order?.deliveryAmount}
          onChange={(e) => handleDeliveryChange(e.target.name, e.target.value)}
          placeholder="0 - 999"
        />
      </div>
      <div className="mt-6 h-px my-4 bg-gray-200 border-t-1 border-black dark:bg-gray-700 w-full"></div>
      <div className="flex w-full items-start justify-center mt-4 flex-col">
        <label>Notes<span className="text-red-700">*</span></label>
        <textarea
          value={order?.notes}
          onChange={(e) => {
            setOrder(prev => ({ ...prev, notes: e.target.value }))
            setNoteError(false)
        }}
          className={`w-full rounded ${noteError && 'border-2 border-red-400'}`}
        />
      </div>
    </div>
  );
};
