import React from 'react';
import {Dropdown} from "../../../components/common/dropdowns/Dropdown" // Adjust the path according to your project structure
import {InputField} from "../../../components/common/InputFields/InputField" // Adjust the path according to your project structure
import { MdDelete } from 'react-icons/md'; // Assuming you are using react-icons

export const SellingPriceList = ({ sales, setProduct, product }) => {  
    const addSourcewithPrice=()=>{
        setProduct({ ...product, sellingPrices: [...product.sellingPrices, {sourceId:'', price:0}] });
      }
      // Delete a product
    const deleteSource = (productIndex) => {
        const updatedProducts = [...product.sellingPrices];
        updatedProducts.splice(productIndex, 1);
        setProduct({ ...product, sellingPrices: updatedProducts });
    };
    const handleSellingPriceChange = (index, field, value) => {
        const numValue = field === 'price' ? Number(value) : value; // Convert to number if the field is 'price'
      
        const updatedSellingPrices = product.sellingPrices.map((item, idx) => {
          if (idx === index) {
            return { ...item, [field]: numValue };
          }
          return item;
        });
      
        setProduct((prevProduct) => ({
          ...prevProduct,
          sellingPrices: updatedSellingPrices,
        }));
      };

    const disabledOptions = product.sellingPrices?.map(s=>s.sourceId);

  return (
    <>
      {product?.sellingPrices?.map((product, productIndex) => (
        <div key={productIndex} className="mb-4">
            <div className="flex flex-end items-center">
                <button
                    type="button"
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm p-2 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 mt-4 inline-flex items-center justify-between"
                    onClick={() => deleteSource(productIndex)}
                >
                    <MdDelete /> Sale Source
                </button>
            </div>
          <div className="grid gap-4 mb-4 items-center sm:grid-cols-12">
            <div className="col-span-6">
              <Dropdown
                label="Source"
                value={product?.sourceId}
                onChange={(e) => handleSellingPriceChange(productIndex, 'sourceId', e.target.value)}
                options={sales}
                disabledOptions={disabledOptions}
                placeholder="Select Source"
                name="sourceId"
              />
            </div>
            <div className="col-span-6">
              <InputField
                label="Price"
                type="number"
                name="price"
                id={`price-${productIndex}`}
                value={product?.price}
                onChange={(e) => handleSellingPriceChange(productIndex, 'price', e.target.value)}
                placeholder="0 - 999"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              />
            </div>
          </div>
          <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
        </div>
      ))}
      <button
        type="button"
        className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm text-center dark:bg-blue-600 py-2.5 px-2 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-4"
        onClick={addSourcewithPrice}
      >
        Add Source Price
      </button>
    </>
  );
};

export default SellingPriceList;
