import React, { useState, useEffect, useContext } from "react";
import { Dropdown } from "flowbite-react";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { orderStatusEnum, paymentStatusEnum } from "../../constants/status.enums";
import { FaEye, FaTrash } from "react-icons/fa";
import AuthContext from "../../AuthContext";
import { currencyMapper } from "../../utils/currency";
import CustomModal from "../../components/common/Modal";
import { InputField } from "../../components/common/InputFields/InputField";

function SalesContainer({isAdmin = false}) {
  const [sales, setAllSalesData] = useState([]);
  const [filters, setFilters] = useState([]); 
  const [currentPage, setCurrentPage] = useState(1); 
  const [totalPages, setTotalPages] = useState(1);
  const [updatePage, setUpdatePage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [cancellationModal, setCancellationModal] = useState(false);
  const [cancelObject, setCancelObject] = useState({saleID:"", note:""});
  const [products, setProducts] = useState();
  const [allStores, setAllStores] = useState();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext)

  const orderTypeMapper= {
    'new': "New",
    'refund':"Refund",
  }
 
  useEffect(() => {
    fetchSalesData();
  }, [currentPage,updatePage]);

  const fetchSalesData = () => {
    if (loading) return;
    setLoading(true);

    const query = filters
      .map((filter) => `${filter.key}=${filter.value}`)
      .join("&");
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/get?page=${currentPage}&limit=10&${query}&sortOrder=desc`)
      .then((response) => response.json())
      .then((data) => {
        setAllSalesData(data.salesData);
        setTotalPages(data.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err)
        setLoading(false);
      });
  };

  const fetchStoresData = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/store/get`)
      .then((response) => response.json())
      .then((data) => {
        setAllStores(data);
      });
  };
  const fetchAllProducts = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/get/${authContext.user}`)
      const data = await res.json();
      setProducts(data);
    } catch (e) {
      console.log(e);
      alert("Something went wrong fetching products, please contact the technical team!");
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
        fetchSalesData();
        setCurrentPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [filters]);
  
  
  useEffect(()=>{
    fetchStoresData();
    fetchAllProducts();
  },[])

  const addFilter = (filterKey) => {
    if (filters.some((filter) => filter.key === filterKey)) return;
    setFilters([...filters, { key: filterKey, value: "" }]);
  };

  const handleFilterChange = (index, key, value, color = null) => {
    const newFilters = filters.map((filter, i) => {
      if (i === index) {
        return { ...filter, [key]: value, ...(color && { color }) };
      }
      return filter;
    });
    setFilters(newFilters);
  };

  const removeFilter = (index) => {
    const newFilters = filters.filter((_, i) => i !== index);
    setFilters(newFilters);
  };

  const openModalSetting = (element) => {
    if(element.orderType === 'refund'){
      navigate(`/sales/addRefund/${element._id}`);
    }else{
      navigate(`/sales/add/${element._id}`);
    }
  };

  const updateSaleStatus = async(saleId, newStatus, key, oldSaleData) => {
    const user = localStorage.getItem('user');
    let parsedUser
    if (user) {
      parsedUser = JSON.parse(user);
      console.log(parsedUser, 'parsedUser');
    } else {
      console.log('No user found in local storage');
    }
    if ((oldSaleData.Status !== "pending" && (oldSaleData.Status !== "cancelled" && key === "Status") && newStatus !== "cancelled" ) || (key === "paymentStatus" && oldSaleData.Status === "fulfilled")) {      
      console.log(oldSaleData.Status , newStatus , 'newStatusnewStatusnewStatus')
      if(newStatus===oldSaleData.Status || (newStatus===oldSaleData.paymentStatus && key !=='Status')) return
      const updatedSaleData = {
        ...oldSaleData,
        newUserID: parsedUser._id,
        [key]: newStatus,
        saleId,
      };
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updatedSaleData),
      };
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/update`, requestOptions)
        .then(async (response) => {
          if (!response.ok) {
            const err = await response.json()            
            throw err;
          }
          setUpdatePage((prev) => !prev);
          return response.json();
        })
        .catch((error) => alert(`Error: ${error.message}`));
    }
    if(key === 'Status' && oldSaleData.Status === "pending" && newStatus === "cancelled"){
      setCancellationModal(true)
      setCancelObject((prev)=>({...prev, saleID:saleId}))
    }
  };
  const handleCancellation = async(value) =>{
    console.log(value , 'vall --------')
    setCancelObject((prev)=>({...prev, note:value}))
  }
  const submitCancellation = async() =>{
    const user = localStorage.getItem('user');
    let parsedUser
    if (user) {
      parsedUser = JSON.parse(user);
      console.log(parsedUser, 'parsedUser');
    } else {
      console.log('No user found in local storage');
    }
    try{
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/cancelPendingOrders`, {
        method: "PATCH",
        body:JSON.stringify({orderID : cancelObject.saleID, newUserID:authContext.user, cancellationNotes: cancelObject.note}),
        headers: {
          "Content-Type":"application/json"
        }
      });
      const result = res.json()
      if (res.status === 400) alert(result.message);
      if (!res.ok) throw (result.message || 'An Error Occurred');
      if(res.ok) {
        setCancellationModal(false)
        setCancelObject(()=>({saleID:"", note:""}))
        alert(`Order cancelled successfuly`);
      }
      setUpdatePage((prev) => !prev);
    }catch(error){
      alert(error || "An Error Occurred");
      console.log(error , 'errorrr')
    }
  }
  const dropDownStyle = {
    backgroundColor: "white",
    color: "black",
    padding: "0.5em 1em",
    borderBottom: "1px solid #ddd",
    cursor: "pointer",
  };

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return; 
    setCurrentPage(newPage);
  };
  const getPaymentStatusEnum = (orderType) => {
    const statusEnum = { ...paymentStatusEnum };
  
    // Conditionally add 'refund' if order type is 'refund'
    if (orderType === "refund") {
      statusEnum.refunded = { label: "refunded", color: "#fde047" };
    }
    return statusEnum;
  };
  
  return (
    <div className="col-span-12 lg:col-span-10 flex justify-center">
      {cancellationModal&&
      <CustomModal modalOpen={cancellationModal} setModalOpen={setCancellationModal} title={"Please Insert Cancellation note"}>
        <div>
          <div className="col-span-5">
            <InputField
              label="Note"
              type="string"
              name="Note"
              value={cancelObject.note}
              onChange={(e) => handleCancellation(e.target.value)}
              placeholder="Cancellation Note"
            />
          <span className="flex justify-end">
            <button onClick={submitCancellation} className="mt-2 bg-green-500 p-2 rounded text-white">Submit</button>
          </span>
          </div>
        </div>
      </CustomModal>}
      <div className="flex flex-col gap-5 w-11/12">
        <div className="rounded-lg border bg-white border-gray-200 ">
          <div className="flex justify-between pt-5 pb-3 px-3">
            <div className="flex gap-4 justify-center items-center ">
              <span className="font-bold">Orders</span>
            </div>
            <div className="flex gap-4 flex-wrap">
              <Link to={"/sales/add"}>
                <button className="bg-blue-500 text-white hover:bg-blue-700 h-[37px] font-bold p-2 text-xs rounded-md">
                  Add Order
                </button>
              </Link>
              <Link to={"/sales/addRefund"}>
                <button className="bg-orange-500 text-white hover:bg-orange-700 h-[37px] font-bold p-2 text-xs rounded-md">
                  Add Refund
                </button>
              </Link>
              <Dropdown
                label="Add Filter"
                className="font-bold text-xs p-2 rounded"
                style={{ color: "white" , background: isHovered ? "#4cae4c" : "#5cb85c" }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <Dropdown.Item 
                  onClick={() => addFilter("fullName")}
                  style={dropDownStyle}
                  onMouseEnter={(e) => e.target.style.backgroundColor = "#f3f4f6"}
                  onMouseLeave={(e) => e.target.style.backgroundColor = "white"}>
                  Customer Name
                </Dropdown.Item>
                <Dropdown.Item 
                  onClick={() => addFilter("phone")}
                  style={dropDownStyle}
                  onMouseEnter={(e) => e.target.style.backgroundColor = "#f3f4f6"}
                  onMouseLeave={(e) => e.target.style.backgroundColor = "white"}>
                  Phone
                </Dropdown.Item>
                <Dropdown.Item 
                  onClick={() => addFilter("manualOrderId")}
                  style={dropDownStyle}
                  onMouseEnter={(e) => e.target.style.backgroundColor = "#f3f4f6"}
                  onMouseLeave={(e) => e.target.style.backgroundColor = "white"}>
                  Order ID
                </Dropdown.Item>
                <Dropdown.Item 
                  onClick={() => addFilter("Status")}
                  style={dropDownStyle}
                  onMouseEnter={(e) => e.target.style.backgroundColor = "#f3f4f6"}
                  onMouseLeave={(e) => e.target.style.backgroundColor = "white"}>
                  Status
                </Dropdown.Item>
                <Dropdown.Item 
                  onClick={() => addFilter("paymentStatus")}
                  style={dropDownStyle}
                  onMouseEnter={(e) => e.target.style.backgroundColor = "#f3f4f6"}
                  onMouseLeave={(e) => e.target.style.backgroundColor = "white"}>
                  Payment Status
                </Dropdown.Item>
                <Dropdown.Item 
                  onClick={() => addFilter("productID")}
                  style={dropDownStyle}
                  onMouseEnter={(e) => e.target.style.backgroundColor = "#f3f4f6"}
                  onMouseLeave={(e) => e.target.style.backgroundColor = "white"}>
                  Product
                </Dropdown.Item>
                <Dropdown.Item 
                  onClick={() => addFilter("StoreID")}
                  style={dropDownStyle}
                  onMouseEnter={(e) => e.target.style.backgroundColor = "#f3f4f6"}
                  onMouseLeave={(e) => e.target.style.backgroundColor = "white"}
                >
                  Store
                </Dropdown.Item>
              </Dropdown>
            </div>
          </div>
          <div className="flex flex-wrap gap-4 p-3">
            {filters.map((filter, index) => (
              <div key={index} className="flex flex-col gap-2">
                <div className="flex justify-start items-center gap-2">
                  <label className="font-medium text-gray-700">
                    {filter.key === "fullName" && "Customer Name"}
                    {filter.key === "phone" && "Phone"}
                    {filter.key === "manualOrderId" && "Order ID"}
                    {filter.key === "Status" && "Status"}
                    {filter.key === "paymentStatus" && "Payment Status"}
                    {filter.key === "productID" && "Product"}
                    {filter.key === "StoreID" && "Store"}
                  </label>
                  <button
                  onClick={() => removeFilter(index)} 
                  className="text-red-500 font-bold">
                    <FaTrash className="h-4 w-4" />
                  </button>
                </div>
                {filter.key === "Status" || filter.key === "paymentStatus" || filter.key === "StoreID" ? (
                  filter.key=== 'StoreID'?
                  <select
                    value={filter.value}
                    onChange={(e) => {
                      handleFilterChange(index, "value", e.target.value);
                    }}
                    className={`border p-1 rounded w-[260px]`}
                  >
                    <option value="" data-color={'white'} style={{
                          backgroundColor: "white",
                          color: "black",
                          padding: "0.25em 0.6em",
                          borderRadius: "999px",
                          display: "inline-block",
                        }}> 
                      All 
                    </option>
                    { allStores.map((store,idx) => (
                        <option key={idx} value={store._id} style={{
                          color: "black",
                          padding: "0.25em 0.6em",
                          borderRadius: "999px",
                          display: "inline-block",
                          
                        }}>
                          {store.name}
                        </option>
                      ))
                    }
                  </select>
                  :
                  <select
                    value={filter.value}
                    onChange={(e) => {
                      const selectedOption = e.target.options[e.target.selectedIndex];
                      const selectedColor = selectedOption.getAttribute('data-color');
                      handleFilterChange(index, "value", e.target.value, selectedColor);
                    }}
                    className={`border p-1 rounded w-[260px]`}
                    style={{ backgroundColor: filter.color }}>
                    <option value="" data-color={'white'} style={{
                          backgroundColor: "white",
                          color: "black",
                          padding: "0.25em 0.6em",
                          borderRadius: "999px",
                          display: "inline-block",
                        }}> 
                      All
                    </option>
                    {filter.key === "Status" &&
                      Object.entries(orderStatusEnum).map(([key, { label,color }]) => (
                        <option key={key} value={key} data-color={color} style={{
                          backgroundColor: color,
                          color: "white",
                          padding: "0.25em 0.6em",
                          borderRadius: "999px",
                          display: "inline-block",
                        }}>
                          {label}
                        </option>
                      ))
                    }
                    {filter.key === "paymentStatus" &&
                      Object.entries(paymentStatusEnum).map(([key, { label,color }]) => (
                        <option key={key} value={key} data-color={color} style={{
                          backgroundColor: color,
                          color: "white",
                          padding: "0.25em 0.6em",
                          borderRadius: "999px",
                          display: "inline-block",
                        }}>
                          {label}
                        </option>
                      ))
                    }
                  </select>
                ) : (
                  filter.key !== "productID" && (<input
                    type="text"
                    value={filter.value}
                    onChange={(e) => handleFilterChange(index, "value", e.target.value)}
                    className="border p-1 rounded"
                    placeholder={filter.key === "fullName" ? "Enter name" : "Enter value"}
                    onInput={(e) => {
                      if (filter.key !== "fullName") {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }
                    }}           
                  />)
                )}
                {filter.key === "productID" && 
                  <select
                  value={filter.value}
                  onChange={(e) => {
                    const selectedOption = e.target.options[e.target.selectedIndex];
                    const selectedColor = selectedOption.getAttribute('data-color');
                    handleFilterChange(index, "value", e.target.value, selectedColor);
                  }}
                  className={`border p-1 rounded w-[260px]`}
                  style={{ backgroundColor: filter.color }}
                  >
                    <option value={""}>-- Select Product --</option>
                    {products && products.map(itm => (
                      <option value={itm._id}>{itm.name}</option>
                    ))}
                </select>
                }
              </div>
            ))}
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
              <thead>
                <tr>
                  {filters.find(itm => itm.key === "productID") && (
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Qty
                  </th>
                  )}
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Order ID
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Type
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Client Name
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Client Address
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Client Phone
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Client Country
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Cash To be Collected From Delivery
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Status
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Payment Status
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
              {loading ? (
                  <tr>
                    <td colSpan="10" className="text-center py-4">
                      Loading...
                    </td>
                  </tr>
                ) : sales.length > 0 ? (
                sales.map((element) => (
                  <tr key={element._id}>
                    
                    {filters.find(itm => itm.key === "productID") && <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      <p className="w-[24px] h-[24px] rounded-full !bg-green-500 inline-flex items-center justify-center text-white">
                        {element.products.find(itm=>itm.productID._id === (filters.find(fltr=>fltr.key==="productID").value))?.quantity}
                      </p>
                    </td>}
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {element.manualOrderId}
                    </td>
                    <td className={`whitespace-nowrap px-4 py-2 ${element.orderType === 'new' || !element.orderType ? "text-green-500":"text-red-500"}`}>
                      {orderTypeMapper[element.orderType] || "New"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {element.customerID?.fullName}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {element.deliveryAddress}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {element.customerID?.phone}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {typeof element?.customerID?.country === "object"
                        ? element?.customerID?.country?.label
                        : element?.customerID?.country || ""}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {-element.refundDetails?.cashToGiveDelivery || element.deliveryCashCollected }  {currencyMapper[element?.StoreID.country] || 'JOD'}
                    </td>
                    <td className="px-4 py-2">
                      <Dropdown
                        label={
                          <span
                            style={{
                              backgroundColor:
                                orderStatusEnum[element.Status]?.color,
                              color: "white",
                              padding: "0.25em 0.6em",
                              borderRadius: "999px",
                              display: "inline-block",
                            }}
                          >
                            {orderStatusEnum[element.Status]?.label}
                          </span>
                        }
                        disabled={element.orderType === "refund"}
                        align="center"
                      >
                        {Object.entries(orderStatusEnum).map(
                          ([key, { label, color }]) => (
                            <Dropdown.Item
                              key={key}
                              onClick={() =>
                                updateSaleStatus(element._id, key, "Status", element)
                              }
                              style={{
                                backgroundColor: color,
                                color: "white",
                                padding: "0.25em 0.6em",
                                borderRadius: "999px",
                                display: "inline-block",
                                margin: "2px 0",
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              {label}
                            </Dropdown.Item>
                          )
                        )}
                      </Dropdown>
                    </td>
                    <td className="px-4 py-2">
                      <Dropdown
                        label={
                          <span
                            style={{
                              backgroundColor:
                                getPaymentStatusEnum(element.orderType)[element.paymentStatus]?.color,
                              color: "white",
                              padding: "0.25em 0.6em",
                              borderRadius: "999px",
                              display: "inline-block",
                            }}
                          >
                            {getPaymentStatusEnum(element.orderType)[element.paymentStatus]?.label}
                          </span>
                        }
                        disabled={element.orderType === "refund"}
                        align="center"
                      >
                        {Object.entries(getPaymentStatusEnum(element.orderType)).map(
                          ([key, { label, color }]) => (
                            <Dropdown.Item
                              key={key}
                              onClick={() =>
                                isAdmin && updateSaleStatus(
                                  element._id,
                                  key,
                                  "paymentStatus",
                                  element
                                )
                              }
                              style={{
                                backgroundColor: color,
                                color: "white",
                                padding: "0.25em 0.6em",
                                borderRadius: "999px",
                                display: "inline-block",
                                margin: "2px 0",
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              {label}
                            </Dropdown.Item>
                          )
                        )}
                      </Dropdown>
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700 flex items-end justify-start">
                      <Link to={`${element.orderType === 'refund' ? '/salesRefundDetails':'/salesOrderDetails'}/${element._id}`}>
                        <FaEye size={25} color="grey" className="cursor-pointer" />
                      </Link>
                    {element.Status==='pending' && <MdEdit
                        className="cursor-pointer"
                        color="grey"
                        size={30}
                        onClick={() => openModalSetting(element)}
                      />}
                    </td>

                  </tr>
                ))):
                (<td colSpan="10" className="text-center py-4">
                  No results found
                </td>)}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end items-center mt-2 p-4 space-x-4 border-t-2">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1 || totalPages === 0}
              className="bg-blue-200 shadow-md text-gray-800 font-bold py-1 px-3 rounded-md disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-white hover:bg-gray-100">
              {'<'}
            </button>
            <span className="text-gray-700 ">
              Page {totalPages > 0 ? currentPage : 0} of {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages || totalPages === 0}
              className="bg-blue-200 shadow-md text-gray-800 font-bold py-1 px-3 rounded-md disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-white hover:bg-gray-100">
              {'>'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesContainer;
