import React, { useState, useEffect, useCallback } from 'react';
import { HiRefresh } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { InputField } from '../../../components/common/InputFields/InputField';
import debounce from "lodash/debounce";
import { currencyMapper } from '../../../utils/currency';

export const CancelledOrdersTable = ({fetchOrders, orders, selectedStore }) => {
    const [searchByName, setSearchByName] = useState('');
    const [searchByOrderID, setSearchByOrderID] = useState('');
    const [shouldRefetch, setShouldRefetch] = useState(false);
    const [selectedOrderType, setSelectedOrderType] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 1000000;
    const { salesData = [], totalPages = 1 } = orders;
    const [loading, setLoading] = useState(false);
    const [fetchNow, setFetchNow] = useState(false);

    useEffect(() => {
          setLoading(true); 
          fetchOrders('cancelled',{
            orderType: selectedOrderType,
            fullName: searchByName,
            manualOrderId: searchByOrderID,
            limit:itemsPerPage,
            page: currentPage,
          }).finally(() => setLoading(false)); 
      }, [selectedOrderType, currentPage,fetchNow]);
      
    const fetchOrdersDebounced = useCallback(
    debounce(() => {
        fetchOrders("cancelled", {
            orderType: selectedOrderType,
            fullName: searchByName,
            manualOrderId: searchByOrderID,
            limit:itemsPerPage,
            page: currentPage,
        });
    }, 500),
    [searchByName, searchByOrderID, currentPage] 
    );

    useEffect(() => {
        fetchOrdersDebounced();
        return () => {
          fetchOrdersDebounced.cancel();
        };
      }, [searchByName, searchByOrderID,fetchOrdersDebounced]);

    useEffect(() => {
        if (shouldRefetch) {
            reset()
            setFetchNow(prev=>!prev)
        }
        return ()=> setShouldRefetch(false)
    },[shouldRefetch, setShouldRefetch])
    
    useEffect(() => {
        reset()
    },[selectedStore])

    const reset = ()=>{
        setSearchByName("");
        setSearchByOrderID("");
        setSelectedOrderType("");
    }

    const handleOrderTypeChange = (e) => {
        setSelectedOrderType(e.target.value);
        setCurrentPage(1); 
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
        }
    };

    return (
        <div className="px-2">
            <div className="w-full justify-end items-center flex my-2 px-2">
                <button onClick={() => setShouldRefetch(true)} className="border-2 bg-green-100 rounded border-black p-2">
                    <HiRefresh />
                </button>
            </div>
            <div className="grid grid-cols-2 gap-2 sm:flex sm:flex-row sm:gap-5 mb-4">
                <div className="flex flex-col gap-1">
                    <InputField
                    label="Customer Name"
                    type="text"
                    name="cutomer-name"
                    value={searchByName}
                    onChange={(e) => setSearchByName(e.target.value)}
                    placeholder="Enter Name..."
                    classes={'!bg-white'}
                    />
                </div>
                 <div className="flex flex-col gap-1">
                    <InputField
                    label="Order ID"
                    type="text"
                    name="manualOrderId"
                    value={searchByOrderID}
                    onChange={(e) => setSearchByOrderID(e.target.value)}
                    onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }}
                    placeholder="Enter ID..."
                    classes={'!bg-white'}
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <label htmlFor='orderType' className=' text-sm font-medium text-gray-90'>Order Type</label>
                    <select
                        name="orderType"
                        value={selectedOrderType}
                        onChange={handleOrderTypeChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 min-w-[150px] focus:border-primary-600 p-2.5"
                    >
                        <option value="">All Types</option>
                        <option value="new">New</option>
                        <option value="refund">Refund</option>
                    </select>
                </div>
            </div>
            <div className='overflow-x-auto'>
                <table className="bg-white min-w-full divide-y-2 divide-gray-200 text-sm overflow-x-scroll">
                    <thead className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                        <tr>
                            <td className="text-center">ID</td>
                            <td className="text-center">Customer Name</td>
                            <td className="text-center">Total Amount</td>
                            <td className="text-center">Payment Status</td>
                            <td className="text-start">Status</td>
                            <td className="text-center">Actions</td>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {loading ? (
                            <tr>
                                <td colSpan="6" className="text-center py-4">
                                    Loading...
                                </td>
                            </tr>
                        ) : salesData.length > 0 ? (
                            salesData.map((order) => (
                                <tr key={order._id} >
                                    <td className="text-center">{order.manualOrderId || order._id}</td>
                                    <td className="text-center">{order.customerID.fullName}</td>
                                    <td className="text-center">{order.TotalSaleAmount} {currencyMapper[order?.StoreID?.country] || 'JOD'}</td>
                                    <td className="text-center">{order.paymentStatus}</td>
                                    <td className="capitalize">{order.Status}</td>
                                    <td className="flex items-center justify-center gap-1">
                                        <Link to={`/salesOrderDetails/${order._id}`}>
                                            <button className="bg-blue-500 text-xs sm:text-sm border-blue-500 border-2 rounded text-white p-1 xs:p-2 hover:bg-white hover:text-blue-500">
                                                View
                                            </button>
                                        </Link>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center py-4">
                                    No results found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-end items-center mt-2 space-x-4">
                <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className="bg-white shadow-md text-gray-800 font-bold py-1 px-3 rounded-md disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-white hover:bg-gray-100"
                >
                    {"<"}
                </button>
                <span className="text-gray-700">
                    Page {totalPages > 0 ? currentPage : 0} of {totalPages}
                </span>
                <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className="bg-white shadow-md text-gray-800 font-bold py-1 px-3 rounded-md disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-white hover:bg-gray-100"
                >
                    {">"}
                </button>
            </div>
        </div>
    );
};
