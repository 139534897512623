export const AddressInput = ({ index, value, selectedAddress, handleAddressChange, handleRadioChange, setIsNewAddress, isNew = false, onInputChange,required=false }) => (
    <div className="col-span-12 grid grid-cols-12 items-center mb-2">
      <div className="col-span-1">
        <input
          type="radio"
          name="address"
          value={value}
          checked={selectedAddress === value}
          onChange={(e) => {
            handleRadioChange(e.target.value);
            if (setIsNewAddress) setIsNewAddress(false);
          }}
          className="mr-0"
        />
      </div>
      <div className="col-span-10">
        <label className="block mb-2 text-sm font-medium text-gray-900">{isNew ? <>New Address {required&&<span className="text-rose-700">*</span>}</> : "Address"}</label>
        <input
          type="text"
          name="address"
          value={value}
          onChange={(e) => onInputChange ? onInputChange(e.target.value) : handleAddressChange(index, e.target.value)}
          disabled={!isNew}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
          placeholder="Jordan-Amman-Kafoure street"
        />
      </div>
    </div>
  );
  