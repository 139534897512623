import { useContext, useEffect, useState } from "react";
import StorePicker from "./components/StorePicker";
import TransferTable from "./components/TransferTable";
import AuthContext from "../../AuthContext";
import CustomModal from "../../components/common/Modal";
import {useNavigate} from "react-router-dom";


export const TransferStockContainer = () => {
  const authContext = useContext(AuthContext);
  const navigate=useNavigate()
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [stores, setStores] = useState();
  const [products, setProducts] = useState([]);
  const [requestedTransfer, setRequestedTransfer] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const fetchStores = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/store/get`);
      const storesRes = await res.json();
      setStores(storesRes);
    } catch (e) {
      console.error(e);
      alert('Something went wrong fetching stores, please contact the tech team!');
    }
  };

  const fetchStoreProducts = async () => {
    if (!from._id) return;
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/get/bystore/${from._id}`);
      const productRes = await res.json();
      setProducts(productRes);
    } catch (e) {
      console.error(e);
      alert('Something went wrong fetching stores, please contact the tech team!');
    }
  };

  useEffect(() => {
    fetchStores();
  }, []);

  useEffect(() => {
    fetchStoreProducts();
    setRequestedTransfer([]);
  }, [from]);

  const handleSubmitTransfer = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/stockTransfers/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fromStoreID: from._id,
          toStoreID: to._id,
          products: requestedTransfer.map(product => ({
            productID: product.productID._id,
            quantity: product.quantity,
          })),
          userID: authContext.user,
        }),
      });

      const result = await response.json();
      if (!response.ok) {
        throw result;
      }
      alert('Stock transfered successfully!');
      navigate('/stock-transfers')
    } catch (error) {
      console.error('Error creating stock transfer:', error);
      alert(error.message || 'Something went wrong. Please try again.');
    }
  };

  const handleSubmitClick = () => {
    for (let i = 0; i < requestedTransfer.length; i++) {
      const transfer = requestedTransfer[i];

      if (!transfer.productID._id) {
        alert(`The requested product transfer at line ${i + 1} has no product. Please specify it to continue.`);
        return;
      }

      const fromQty = products.find(p => p._id === transfer.productID._id)?.availableStock.find(s => s.storeID === from._id)?.quantity || 0;

      if (transfer.quantity <= 0) {
        if (fromQty === 0) {
          alert(`Product "${products.find(prod => prod._id === transfer.productID._id)?.name}" is out of stock at ${from.name}. No transfer can be placed for it.`);
        } else {
          alert(`The product at line ${i + 1} has zero quantity. Please specify a valid quantity to continue.`);
        }
        return;
      }
    }

    setOpenModal(true);
  };

  const handleAddProduct = () => {
    setRequestedTransfer(prev => [...prev, { productID: { _id: '' }, quantity: 0 }]);
  };

  return (
    <div className="col-span-12 lg:col-span-10 flex flex-col justify-center gap-20 p-2 md:p-0">
      {openModal && (
        <CustomModal modalOpen={openModal} setModalOpen={setOpenModal} title="Submit Transfer" mdWidth={400} lgWidth={500}>
          <div className="flex flex-col items-center justify-center gap-4">
            <p className="text-lg font-semibold">Are you sure you want to submit this transfer?</p>
            <div className="flex flex-row items-center justify-center gap-4">
              <button onClick={handleSubmitTransfer} className="bg-green-500 border-green-500 border-2 rounded text-white p-2 hover:bg-white hover:text-green-500">
                YES
              </button>
              <button onClick={() => setOpenModal(false)} className="bg-red-500 border-red-500 border-2 rounded text-white p-2 hover:bg-white hover:text-red-500">
                NO
              </button>
            </div>
          </div>
        </CustomModal>
      )}
      <div className="w-full flex flex-col gap-4 p-2">
        <h1 className="text-[20px] sm:text-[25px] md:text-[30px] font-bold">Transfer Stock</h1>
        <StorePicker from={from} setFrom={setFrom} to={to} setTo={setTo} stores={stores} />
        <TransferTable products={products} requestedTransfer={requestedTransfer} setRequestedTransfer={setRequestedTransfer} from={from} to={to} />
        <div className="flex justify-start w-full mt-6">
          <button type="button" className="bg-[#66ade7] rounded-lg py-1 px-2 text-sm shadow-sm hover:brightness-[1.3] disabled:bg-gray-300 disabled:cursor-not-allowed disabled:hover:brightness-100" onClick={handleAddProduct} disabled={!from._id || !to._id}>
            + Product
          </button>
        </div>
      </div>
      <div className="flex justify-end w-full mt-24 px-2 sm:px-6 md:px-12">
        <button type="button" className="bg-[#60cf6e] rounded-lg py-1 px-3 text-sm shadow-sm hover:brightness-[1.3] disabled:bg-gray-300 disabled:cursor-not-allowed disabled:hover:brightness-100" onClick={handleSubmitClick} disabled={requestedTransfer.length === 0 || requestedTransfer.some(prod => !prod.productID._id)}>
          Submit
        </button>
      </div>
    </div>
  );
};
