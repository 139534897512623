import { useEffect, useMemo, useState } from "react";
import CustomModal from "../../../components/common/Modal"
import QrReader from "react-qr-reader";
import { useWindowSize } from "react-use";
import { CancelItemModal } from "../../sales/components/CancelItemModal";
import { CancelOrderItemsTable } from "../../sales/components/CancelOrderItemsTable";
import { currencyMapper } from "../../../utils/currency";

export const UpdateOrderStatusModal = ({
  modalOpen,
  setModalOpen,
  ordrID,
  orders,
  setSelectedOrders,
  setSelectedItems,
  setShouldRefetch,
  selectedItems,
  totalReceivable
}) => {
  const [allProducts, setAllProducts] = useState([]);
  console.log(selectedItems, 'selectedItems')
  const { width, height } = useWindowSize();
  const statusMapper = {
    fulfilled: "Fulfilled",
    deliveryPostponed: "Delivery Postponed",
    partiallyFulfilled: "Partially Fulfilled",
    cancelled:"Cancelled"
  }

  const eligblePaymentStatuses = {
    cashCollected: "Cash Collected",
    pendingPaymentConfirmation: "Pending Payment Confirmation",
    uncollected:"Uncollected"
  }

  const deliveryTimeModes = {
    anytime: 'Any Time of the day',
    timeRange: 'From-To',
    specificTime:'Specific Time'
  }
  
  const [scannerOpen, setScannerOpen] = useState(false);
  const [scannedCancelledItems, setScannedCancelledItems] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [deliveryTimeMode, setDeliveryTimeMode]=useState('anytime')
  const [scannedItems, setScannedItems]=useState({})
  const [openScanModal, setOpenScanModal] = useState(false)
  const [data, setData] = useState({
    Status: undefined,
    preferredDeliveryDate: "",
    preferredDeliveryTime: {anytime:true},
    paymentStatus:"",
    notes:"",
    resolvingNote:"",
    cancellationReason:""
  });
  const mergeProducts = (products) => {
    return products.reduce((acc, product) => {
      const productID = product.productID._id;
      const existingProduct = acc.find(item => item.productID._id === productID);
      
      if (existingProduct) {
        existingProduct.quantity += product.quantity;
        existingProduct.productPieces = existingProduct.productPieces.concat(product.productPieces);
      } else {
        acc.push({ ...product });
      }
      
      return acc;
    }, []);
  };
  const allCancelledProducts = useMemo(() => {
    if (orders?.length) {
      return mergeProducts(orders.flatMap(order => order.products));
    }
    return [];
  }, [orders]); 
  
  const hasSingleItem=selectedItems.some(item => 
   item.length === 1 && item.products.some(product => product.quantity === 1)
  );

  useEffect(() => {
    if (orders?.length) {  
      const totalRequestedQty = allCancelledProducts.reduce((sum, itm) => sum + itm.quantity, 0);
      setIsDone(scannedCancelledItems.length === totalRequestedQty);
    }
  }, [scannedCancelledItems, orders, allCancelledProducts]);

  const handleClosing = () =>{
    setModalOpen(false)
    setData({
      Status: undefined,
      preferredDeliveryDate: "",
      preferredDeliveryTime: {anytime:true},
      paymentStatus:"",
      notes:"",
      resolvingNote:"",
      cancellationReason:""
    })
    setScannedCancelledItems([])
    setScannedItems({});
    setAllProducts([]);
  }

  const markAsOutForDelivery = async (e) => {
    e.preventDefault();
    let dataClone = JSON.parse(JSON.stringify(data));

    if (!dataClone.Status) return alert("Please select the order status");
    if (dataClone.Status === "deliveryPostponed") {
      if (deliveryTimeMode === 'specificTime' && !dataClone.preferredDeliveryTime.time) {
        return alert("Please specify the specific time");
      }
    
      if (deliveryTimeMode === 'timeRange') {
        if (!dataClone.preferredDeliveryTime.from) {
          return alert("Please specify the time range start time");
        }
    
        if (!dataClone.preferredDeliveryTime.to) {
          return alert("Please specify the time range end time");
        }
      }
    }

    if (dataClone.Status === 'cancelled'){
      if (!dataClone.cancellationReason){
        return alert ('Please specify the cancellation reason')
      }
      if(!isDone) return alert ('Please ensure all items are scanned before submitting.')
        
        dataClone['isScanned']=isDone
    }

    if (dataClone.Status === "fulfilled" && (!dataClone.paymentStatus || !dataClone.paymentStatus.length)) return alert("Please specify the payment status");

    if (dataClone.Status !== "deliveryPostponed") {
      delete dataClone.preferredDeliveryDate
      delete dataClone.preferredDeliveryTime
    };
    if (dataClone.Status !== "fulfilled" && dataClone.Status !== "partiallyFulfilled") delete dataClone.paymentStatus;
    if(!dataClone.resolvingNote.length) delete dataClone.resolvingNote
    if(!dataClone.notes.length) delete dataClone.notes
    if (dataClone.Status !== 'cancelled') delete dataClone.cancellationReason
    if (dataClone.Status === 'partiallyFulfilled') {
      if (scannedItems && typeof scannedItems === 'object' && Object.keys(scannedItems).length > 0) {
        dataClone = { ...dataClone, scannedItems };
      } else {
        alert("You should scan at least one item!");
      }
    }
    const user = localStorage.getItem('user');
    let parsedUser
    if (user) {
      parsedUser = JSON.parse(user);
      console.log(parsedUser, 'parsedUser');
    } else {
      console.log('No user found in local storage');
    }
    try {
      console.log(dataClone.Status , 'dataClone')
        const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/resolveOutForDelivery`, {
          method: "PATCH",
          body:JSON.stringify({...dataClone, orderIDs:ordrID, newUserID: parsedUser._id}),
          headers: {
            "Content-Type":"application/json"
          }
        });
        const result = await res.json();
        if (res.ok) {
          handleClosing();
          setShouldRefetch((prev) => !prev);
        }
        if(!res.ok){
          alert(result.message || "An unexpected error occurred.");
        }
        setSelectedItems([]);
        setSelectedOrders([]);
    } catch (e) {
      console.error(e);
      alert(e.message || "An unexpected error occurred.");
    }
  }
  const previewStyle = {
    height:height/2,
    width:width/1.35
  };
  const openQrScanner = (e) =>{
    e.preventDefault();
    setOpenScanModal(!openScanModal);
  }
  console.log(scannedItems, 'scannedItems')
  const handleQR = (qrRes) => {
    let isMatched = false;
  
    allProducts?.forEach((product) => {
      if (isMatched) return;
  
      if (product.productPieces) {
        const matchedPiece = product.productPieces.find(piece => piece.productPieceID === qrRes);
  
        if (matchedPiece) {
          setOpenScanModal(!openScanModal);
          isMatched = true;
  
          setScannedItems((previous) => {
            const currentScanned = previous[product.productID._id] || [];
            if (currentScanned.includes(qrRes)) {
              alert("This product piece ID has already been scanned. Please try scanning a different one.");
              return previous; // Prevents adding the same ID again
            }
  
            return {
              ...previous,
              [product.productID._id]: [...currentScanned, qrRes]
            };
          });
        }
      }
    });
  
    // If no match is found and qrRes exists
    if (!isMatched && qrRes) {
      alert("This product is not part of the current orders. Please scan the correct product.");
    }
  };

  useEffect(() => {
    // Combine products with the same productID and sum their quantities
    const combinedProducts = selectedItems
      .flatMap((item) => item.products)
      .reduce((acc, product) => {
        const existingProduct = acc.find(p => p.productID._id === product.productID._id);
        
        if (existingProduct) {
          // Sum the quantities and merge product pieces if the productID is the same
          existingProduct.quantity += product.quantity;
          existingProduct.productPieces = [...existingProduct.productPieces, ...product.productPieces];
        } else {
          acc.push({ ...product }); // Add product if it's not in the accumulator yet
        }
        
        return acc;
      }, []);
  
    setAllProducts(combinedProducts);
  }, [selectedItems]);
  
  return (
    <CustomModal modalOpen={modalOpen} setModalOpen={setModalOpen} title={"Update Status"} lgWidth={650} mdWidth={500}>
      <div className="flex flex-col items-start justify-center">
      {openScanModal&&
      <CustomModal modalOpen={openScanModal} setModalOpen={setOpenScanModal} title={"Scan Item"}>
        <QrReader
        containerStyle={previewStyle}
        onScan={(qrRes) => handleQR(qrRes)}
        delay={1000}
            />
      </CustomModal>}
        <h2 className="text-xl mb-4">Status</h2>
        <form className="flex flex-col w-full items-start justify-center">
          <div className="flex flex-col justify-center items-start w-full">
            <div className="flex justify-start items-center mb-2" onClick={()=>setData((prev)=>({...data, Status:"fulfilled"}))}>
              <input  type="radio" value={"fulfilled"} defaultChecked={data.Status === "fulfilled"} name="Status" id="fulfilled" className="mr-3"/>
              <label htmlFor="fulfilled">{statusMapper.fulfilled}</label>
            </div>
            {data.Status === "fulfilled" && (
              <div className="flex flex-col items-start justify-center ml-3">
                <p>Total Receivable: {totalReceivable} {currencyMapper[orders[0]?.StoreID?.country] || 'JOD'}</p>
                <label>Payment Status</label>
                <select className="rounded mt-2 w-3/4" value={data.paymentStatus} onChange={(e) => setData(prev => ({ ...data, paymentStatus: e.target.value }))}>
                  <option value={""} disabled>-- Select Status --</option>
                  {Object.keys(eligblePaymentStatuses).map(itm => (
                    <option value={itm} key={itm}>{eligblePaymentStatuses[itm]}</option>
                  ))}
                </select>
              </div> 
            )}
            <div className="flex justify-around">
              <div 
                className={`flex justify-start items-center mb-2 ${hasSingleItem ? 'cursor-not-allowed' : ''}`} 
                onClick={() => {
                  if (!hasSingleItem) {
                    setData((prev) => ({ ...prev, Status: "partiallyFulfilled" }));
                  }
                }}
              >
                <input 
                  type="radio" 
                  value={"partiallyFulfilled"} 
                  checked={data.Status === "partiallyFulfilled"} 
                  name="Status" 
                  id="partiallyFulfilled" 
                  className={`${hasSingleItem ? 'opacity-50 cursor-not-allowed' : ''} mr-3`}
                  disabled={hasSingleItem} 
                />
                <label 
                  htmlFor="partiallyFulfilled"
                  className={`${hasSingleItem ? 'text-gray-300 cursor-not-allowed' : ''}`}
                >
                  {statusMapper.partiallyFulfilled}  
                  {hasSingleItem && <span className="text-red-500 text-[12px]">   ( One or more orders contain only a single item. )</span>}
                </label>
              </div>
            {data.Status === "partiallyFulfilled" && !hasSingleItem &&  <button className="ml-8 w-[35px] p-1 border-2 rounded border-blue-700 flex items-center justify-center bg-blue-500 disabled:bg-gray-500 disabled:border-gray-700 disabled:cursor-not-allowed" disabled={hasSingleItem} onClick={openQrScanner}>
              <img alt="" src={require('../../../assets/camera.png')} className="items-center w-[30px] max-h-[25px]"/>
            </button>}
            </div>
            {data.Status === "partiallyFulfilled" && !hasSingleItem && (
              <div className="flex flex-col items-start justify-center ml-3">
                <div className="flex flex-col items-start justify-center ml-3">
                <label>Payment Status</label>
                <select className="rounded mt-2 w-3/4" value={data.paymentStatus} onChange={(e) => setData(prev => ({ ...data, paymentStatus: e.target.value }))}>
                  <option value={""} disabled>-- Select Status --</option>
                  {Object.keys(eligblePaymentStatuses).map(itm => (
                    <option value={itm} key={itm}>{eligblePaymentStatuses[itm]}</option>
                  ))}
                </select>
                </div>
                {allProducts && allProducts?.length > 0 && (
                    <table className="whitespace-nowrap table-auto w-full border-collapse mt-4">
                    <thead>
                      <tr>
                        <th className="border px-2 sm:px-4 py-1 sm:py-2 text-sm sm:text-base">Product Name</th>
                        <th className="border px-2 sm:px-4 py-1 sm:py-2 text-sm sm:text-base">Quantity</th>
                        <th className="border px-2 sm:px-4 py-1 sm:py-2 text-sm sm:text-base">Counter</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allProducts.map((element, index) => (
                        <tr key={index}>
                          <td className="border px-2 sm:px-4 py-1 sm:py-2 text-sm sm:text-base">{element.name}</td>
                          <td className="border px-2 sm:px-4 py-1 sm:py-2 text-sm sm:text-base">{element.quantity}</td>
                          <td className="border px-2 sm:px-4 py-1 sm:py-2 text-sm sm:text-base">{scannedItems[element.productID._id]?.length || 0}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>                  
                )}
              </div> 
            )}
            <div className="flex justify-start items-center mb-2" onClick={()=>setData((prev)=>({...prev, Status:"deliveryPostponed"}))}>
              <input  type="radio" value={"deliveryPostponed"} defaultChecked={data.Status === "deliveryPostponed"} name="Status" id="deliveryPostponed" className="mr-3"/>
              <label htmlFor="deliveryPostponed">{statusMapper.deliveryPostponed}</label>
            </div>
            {data.Status === "deliveryPostponed" && (
              <>
                <div className="w-full flex flex-col col-span-12 items-start justify-center ml-3 mb-3">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  New Delivery Date
                </label>
                <input
                  type="date"
                  name="preferredDeliveryDate"
                  value={data.preferredDeliveryDate}
                  onChange={(e) =>
                    setData((prev)=>({...prev, preferredDeliveryDate:e.target.value}))
                  }
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                />
                </div>
                
                <div className="w-full flex flex-col col-span-12 items-start justify-center ml-3 mb-2">
                <label className="block mb-2 text-sm font-medium text-gray-900">New Delivery Time</label>
                  {Object.keys(deliveryTimeModes).map((val, idx )=> (
                    <div className="flex items-center w-100" key={idx}>
                    <input
                      id="time-check"
                      name="time-mode"
                      type="radio"
                      defaultChecked={deliveryTimeMode === val}
                        onClick={() => {
                          setDeliveryTimeMode(val);
                          if (val === 'anytime') setData(prev => ({ ...prev, preferredDeliveryTime: { anytime: true } }))
                          if(val==='timeRange') setData(prev => ({ ...prev, preferredDeliveryTime: { from:"", to:"" } }))
                          if(val==='specificTime') setData(prev => ({ ...prev, preferredDeliveryTime: { time:"" } }))
                        }}
                      />
                    <label className="ml-2" htmlFor="time-check">{deliveryTimeModes[val]}</label>
                      </div>
                  ))}
                {deliveryTimeMode ==='specificTime' && <div className="col-span-12">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Preferred Delivery Time
                  </label>
                  <input
                    type="time"
                    name="specificTime"
                    value={data.preferredDeliveryTime.time}
                    onChange={(e) =>
                      setData(prev => ({ ...prev, preferredDeliveryTime: { time: e.target.value } }))
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  />
                </div>}
                {deliveryTimeMode ==='timeRange' && <div className="col-span-12">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    From
                  </label>
                  <input
                    type="time"
                    name="from"
                    value={data.preferredDeliveryTime.from}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    onChange={(e)=>setData(prev => ({ ...prev, preferredDeliveryTime: { ...prev.preferredDeliveryTime, from:e.target.value } }))}
                  />
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    To
                  </label>
                  <input
                    type="time"
                    name="to"
                    value={data.preferredDeliveryTime.to}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    onChange={(e)=>setData(prev => ({ ...prev, preferredDeliveryTime: { ...prev.preferredDeliveryTime, to:e.target.value } }))}
                  />
                </div>}          
                </div>
              </>
            )}
            <div className="w-full flex justify-between items-center pr-2 mb-2" onClick={()=>setData((prev)=>({...prev, Status:"cancelled"}))}>
              <div>
                <input  type="radio" value={"cancelled"} defaultChecked={data.Status === "cancelled"} name="Status" id="cancelled" className="mr-3"/>
                <label htmlFor="cancelled">{statusMapper.cancelled}</label>
              </div>            
              <button 
                type="button"
                className="py-1 px-2 bg-[#14ae5c] text-white text-xs rounded-lg font-semibold hover:brightness-[1.2] disabled:bg-gray-300 disabled:cursor-not-allowed disabled:brightness-100" 
                disabled={data.Status !== "cancelled" || isDone}
                onClick={()=>setScannerOpen(!scannerOpen)}
                >
                Scan
              </button>
            </div>
          </div>
          {data.Status === "cancelled" && <div className="flex w-full items-start justify-center mt-4 flex-col">
            <div className="flex flex-col items-start justify-center w-full ml-3">
                {scannerOpen && <CancelItemModal modalOpen={scannerOpen} products={allCancelledProducts} scannedItems={scannedCancelledItems} setModalOpen={setScannerOpen} setScannedItems={setScannedCancelledItems} />}
                <CancelOrderItemsTable products={allCancelledProducts} scanned={scannedCancelledItems} setScanned={setScannedCancelledItems} /> 
            </div> 
            <label>Cancellation Reason<span className="text-red-500">*</span></label>
            <textarea value={data.cancellationReason} required={data.Status === "cancelled"? true: ""} onChange={(e)=>setData(prev=>({...prev, cancellationReason:e.target.value}))}  className="w-full rounded"/>
          </div>}
          <div className="mt-4 h-px my-4 bg-gray-200 border-t-1 border-black dark:bg-gray-700 w-full" ></div>
          <div className="flex w-full items-start justify-center mt-4 flex-col">
            <label>Notes</label>
            <textarea value={data.resolvingNote} onChange={(e)=>setData(prev=>({...prev, resolvingNote:e.target.value}))}  className="w-full rounded"/>
          </div>
          <div className="w-full flex justify-between items-center mt-4">
            <button className="rounded-lg bg-red-500 hover:bg-red-300 px-6 py-1 text-white" type="button" onClick={handleClosing}>
              Cancel
            </button>
            <button className="rounded-lg bg-green-500 hover:bg-green-300 px-6 py-1 text-white" type="submit" onClick={markAsOutForDelivery}>
              Submit
            </button>
          </div>
        </form>
      </div>

    </CustomModal>
  )
}