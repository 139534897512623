import { useRef, useState } from "react"
import CustomModal from "../../../components/common/Modal"
import { useParams } from 'react-router-dom';
import { MdDelete } from "react-icons/md";
import { Dropdown } from "../../../components/common/dropdowns/Dropdown";
import { InputField } from "../../../components/common/InputFields/InputField";

export const EditPurchaseModal = ({
  focCategories,
  product,
  setProduct,
  expensesCategories,
  purchase,
  modalOpen,
  setModalOpen,
  setResModalOpen,
  setPayload
}) => {
  
  const { id } = useParams();
  const [productFoc, setProductFoc] = useState(product?.foc.map(itm=>({focCategoryID:itm.focCategoryID._id,quantity:itm.quantity})))
  const [productExpenses, setProductExpenses] = useState(product?.expenses.map(itm=>({expenseID:itm.expenseID._id,cost:itm.cost})))
  const productQuantityRef = useRef(product.quantity);
  const focQuantityRef = useRef (productFoc.reduce((acc,foc)=>acc+foc.quantity,0));
  const focQuantity = productFoc.reduce((acc,foc)=>acc+foc.quantity,0);
  const handleUpdate = async (e) => {
    e.preventDefault()

    let errorMessages = [];

    productFoc.forEach((foc, idx) => {
      if (!foc.focCategoryID) {
        errorMessages.push(`FOC: Please select a category for FOC #${idx + 1}`);
      }
      if (foc.quantity === undefined || foc.quantity <= 0) {
        errorMessages.push(`FOC: Please specify a valid quantity for FOC #${idx + 1}`);
      }
    });

    productExpenses.forEach((exp, idx) => {
      if (!exp.expenseID) {
        errorMessages.push(`Expense: Please select a category for Expense #${idx + 1}`);
      }
      if (exp.cost === undefined || exp.cost <= 0) {
        errorMessages.push(`Expense: Please specify a valid cost for Expense #${idx + 1}`);
      }
    });

    if (errorMessages.length > 0) {
      alert(errorMessages.join("\n"));
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/purchase/update/${id}?productID=${product.productID._id}`, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({foc:productFoc , expenses:productExpenses , productNewQuantity: product.quantity}),
      })
      const data = await response.json();
      if (!response.ok) throw data.message || 'An Error Occurred'
      if (data.message) {
        alert(data.message);
        window.location.reload();
      }else{
        setPayload(data)  
        setResModalOpen(true)      
        setModalOpen(false)
      }
    }catch(err) {
      console.log(err) 
      alert (err)
    };
  }

  const deleteFoc = (productIndex) => {
    const updatedProductFoc = [...productFoc];
    updatedProductFoc.splice(productIndex, 1);
    setProductFoc(updatedProductFoc);
  };

  const addFocRow = () => setProductFoc((prev) => [...prev, { focCategoryID: undefined, quantity: undefined }] || [{ focCategoryID: "", quantity: 0 }])
  
  const handleFocChange = ({ rowIdx, key, value }) => {
    const updatedProductFoc = productFoc?.map((itm, idx) => {
      if (idx === rowIdx) {
        return { ...itm, [key]: value };
      }
      return itm;
    });
    setProductFoc(updatedProductFoc);
  };
  const deleteExpenses = (productIndex) => {
    const updatedProductExpense = [...productExpenses];
    updatedProductExpense.splice(productIndex, 1);
    setProductExpenses(updatedProductExpense);
  };

  const addExpenseRow = () => setProductExpenses((prev) => [...prev, { expenseID: undefined, cost: undefined }] || [{ expenseID: "", cost: 0 }])
  
  const handleExpensesChange = ({ rowIdx, key, value }) => {
    const updatedProductExpense = productExpenses?.map((itm, idx) => {
      if (idx === rowIdx) {
        return { ...itm, [key]: value };
      }
      return itm;
    });
    setProductExpenses(updatedProductExpense);
  };
  
  return (
    <CustomModal modalOpen={modalOpen} setModalOpen={setModalOpen} lgWidth={650} mdWidth={500} title={"Update Product"}>
      <form onSubmit={handleUpdate} className="flex w-full flex-col gap-3 lg:gap-4 items-center justify-center">
        <div className="flex w-full gap-3 first-letter:justify-between items-center">
          <Dropdown
            label={'Product'}
            value={product?.productID?._id}
            onChange={(e)=>setProduct((p)=>purchase.products.find(c=>c.productID._id === e.target.value))}
            options={purchase.products.map(prod=>prod.productID)}
            placeholder={'Choose Product'}
            required
          />
          <InputField
            label={'Quantity'}
            value={product.quantity}
            onChange={(e)=>setProduct((prod=>({...prod,quantity:+e.target.value})))}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
            }}
            classes={'!max-w-[90px]'}
            placeholder="Qty"
            required
          />
        </div>
        {product.quantity - focQuantity > productQuantityRef.current - focQuantityRef.current && purchase.status === 'received' && <div className="w-auto shadow-md bg-blue-700 rounded-2xl text-[12px] text-white py-1 px-2">New product pieces will be generated </div>}
        {product.quantity - focQuantity < productQuantityRef.current - focQuantityRef.current && purchase.status === 'received' && <div className="w-auto shadow-md bg-blue-700 rounded-2xl text-[12px] text-white py-1 px-2">Some product pieces will be deleted based on available stock levels </div>}
        <div className="border-4 w-full border-[#757575] flex flex-col items-start justify-center gap-3 p-4 rounded-md">
            <span className="font-bold">FOC</span>
            <div className="h-[1px] bg-[#757575] w-full"></div>
            {productFoc[0] ? 
              productFoc.map((foc,idx)=>{
                return (<div key={idx} className="flex justify-between items-center w-full gap-3">
                  <Dropdown
                    label={'Category'}
                    value = {foc.focCategoryID || ''}
                    required
                    name="focCategoryID"
                    onChange={(e)=>handleFocChange({rowIdx:idx, key:"focCategoryID", value:e.target.value})}
                    placeholder={'Select Category'}  
                    options={focCategories}
                    disabledOptions={productFoc.map (f=>f.focCategoryID)}
                    />
                    <InputField
                      label={'Quantity'}
                      value={foc.quantity}
                      onChange={(e)=>handleFocChange({rowIdx:idx, key:"quantity", value:+e.target.value})}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                      classes={'!max-w-[90px]'}
                      placeholder="Qty"
                      required
                    />
                    <div className="w-1/2">
                      <button
                        type="button"
                        className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm p-2 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 mt-4 inline-flex items-center justify-between"
                        onClick={() => deleteFoc(idx)}
                      >
                        <MdDelete />
                      </button>
                    </div>
                </div>)
              })
              :
              <div className="w-full flex justify-center">This product has no FOC</div>
            }
            <div className="h-[1px] bg-[#757575] w-full"></div>
            <div className="w-full flex items-center justify-center">
            <button type="button" className="bg-[#14ae5c] text-white rounded-lg py-[6px] px-5 text-[14px] shadow-sm hover:brightness-[1.3]" onClick={addFocRow}>+ Add FOC</button>
            </div>
        </div>
        <div className="border-4 w-full border-[#757575] flex flex-col items-start justify-center gap-3 p-4 rounded-md">
            <span className="font-bold">Expenses</span>
            <div className="h-[1px] bg-[#757575] w-full"></div>
            {productExpenses[0] ? 
              productExpenses.map((exp,idx)=>{
                return (<div key={idx} className="flex justify-between items-center w-full gap-3">
                  <Dropdown
                    label={'Category'}
                    value = {exp.expenseID || ''}
                    required
                    name="expenseID"
                    onChange={(e)=>handleExpensesChange({rowIdx:idx, key:"expenseID", value:e.target.value})}
                    placeholder={'Select Category'}  
                    options={expensesCategories}
                    disabledOptions={productExpenses.map (f=>f.expenseID)}
                    />
                    <InputField
                      label={'Cost'}
                      value={exp.cost}
                      type={'number'}
                      onChange={(e)=>handleExpensesChange({rowIdx:idx, key:"cost", value:+e.target.value})}
                      classes={'!max-w-[90px]'}
                      placeholder="Qty"
                      required
                    />
                    <div className="w-1/2">
                      <button
                        type="button"
                        className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm p-2 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 mt-4 inline-flex items-center justify-between"
                        onClick={() => deleteExpenses(idx)}
                      >
                        <MdDelete />
                      </button>
                    </div>
                </div>)
              })
              :
              <div className="w-full flex justify-center">This product has no Expenses</div>
            }
            <div className="h-[1px] bg-[#757575] w-full"></div>
            <div className="w-full flex items-center justify-center">
            <button type="button" className="bg-[#14ae5c] text-white rounded-lg py-[6px] px-5 text-[14px] shadow-sm hover:brightness-[1.3]" onClick={addExpenseRow}>+ Add Expense</button>
            </div>
        </div>
        <div className="flex items-center justify-end gap-2 w-full mt-2 md:mt-4">
          <button type="button" className="bg-red-500 rounded-lg py-2 px-6 text-[14px] shadow-sm hover:brightness-[1.3]" onClick={()=>setModalOpen(false)}>Cancel</button>
          <button type="submit" className="bg-[#14ae5c] rounded-lg py-2 px-6 text-[14px] shadow-sm hover:brightness-[1.3]">Submit</button>
        </div>
    </form>
  </CustomModal>
)
}