export const PAGES_CREDENTIALS = {
  "prepare-order": {
    username: "orderPreparer",
    password: "a8s7d6f5g4h3"
  },
  "inventory": {
    username: "inventoryChecker",
    password: "b7t8y9u6r5e4"
  },
  "goods-expenses": {
    username: "goodsExpensesAdmin",
    password: "c5f6g7h8j9k0"
  },
  "login": {
    username: "loginUser",
    password: "d9j8k7l6m5n4"
  },
  "register": {
    username: "registerUser",
    password: "e3r4t5y6u7i8"
  },
  "dashboard": {
    username: "dashboardViewer",
    password: "f6g7h8j9k0l1"
  },
  "purchase-details": {
    username: "purchaseDetailViewer",
    password: "g8h9j0k1l2m3"
  },
  "sales-admin": {
    username: "salesAdmin",
    password: "h5j7k8l9m0"
  },
  "manage-store": {
    username: "storeManager",
    password: "i7j8k9l0m1n2"
  },
  "products": {
    username: "productManager",
    password: "j8k9l0m1n2o3"
  },
  "order-expenses": {
    username: "orderExpensesAdmin",
    password: "k9l0m1n2o3p4"
  },
  "single-purchase-details": {
    username: "singlePurchaseViewer",
    password: "l0m1n2o3p4q5"
  },
  "sale-sources": {
    username: "salesSourceManager",
    password: "m1n2o3p4q5r6"
  },
  "no-page-found": {
    username: "noPageViewer",
    password: "n2o3p4q5r6s7"
  },
  "received": {
    username: "markAsReceived",
    password: "n2o3p4y5u6c7k8"
  },
  "markAsPaid": {
    username: "paidOrdersAdmin",
    password: "nfjk3eqbrDFs342"
  },
  "stock-transfers":{
    username:"stockTransferAdmin",
    password:"kZ7u2xjEWrP9qL81"
  }
};
