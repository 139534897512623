import React from 'react';
import { MdDelete, MdContentCopy } from 'react-icons/md';
import ExpenseItem from './ExpenseItem';
import {Dropdown} from "../../../components/common/dropdowns/Dropdown" // Assuming these are your custom components
import {InputField} from "../../../components/common/InputFields/InputField"
import { isNumber } from 'lodash';
import { currencyMapper } from '../../../utils/currency';

export const ProductItem = ({
  product,
  productIndex,
  products,
  expenses,
  purchase,
  setPurchase,
  store
}) => {
    console.log(store)
      // Add new expense to a specific product
    const addExpense = (productIndex) => {
        const updatedProducts = [...purchase.products];
        updatedProducts[productIndex].expenses.push({ expenseID: "", cost: "" });
        setPurchase({ ...purchase, products: updatedProducts });
    };



    // Delete a product
    const deleteProduct = (productIndex) => {
        const updatedProducts = [...purchase.products];
        updatedProducts.splice(productIndex, 1);
        setPurchase({ ...purchase, products: updatedProducts });
    };

    const calculateTotalCostPerProduct = ({ prIdx }) => {
        const expenses = purchase.products[prIdx].expenses;
        let total = 0;
        for (let expense of expenses) {
        console.log(expense, typeof expense);
        if (typeof expense === 'object' && isNumber(expense.cost)) {
            total += expense.cost;
        }
        }
        return total;
    };

    const duplicateProductCard = (idx) => {
        const allAddedProducts = [...purchase.products];
        const productToAdd = JSON.parse(JSON.stringify(allAddedProducts[idx]));
        allAddedProducts.push(productToAdd);
        setPurchase((prev) => ({ ...prev, products: allAddedProducts }));
    };

    // Handling Input Change for products
    const handleProductChange = (index, key, value) => {
    const updatedProducts = [...purchase.products];
    updatedProducts[index][key] = value;
    if (key === "productID") {
        updatedProducts[index]["name"] = products.find(itm => itm._id === value).name;
    }
    setPurchase({ ...purchase, products: updatedProducts });
    };


  return (
    <div className="mb-4 col-span-6 bg-white rounded p-3 border-2">
      <div className="flex justify-between items-center">
        <h1 className="flex items-center justify-start">
          <b>{product.name ? product.name : 'Product'} ({productIndex + 1}) - ({currencyMapper[store.country]} {calculateTotalCostPerProduct({ prIdx: productIndex })})</b>
        </h1>
        <div className="flex items-end justify-start">
          <button
            type="button"
            className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm p-2 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 mt-4 inline-flex items-center justify-between"
            onClick={() => deleteProduct(productIndex)}
          >
            <MdDelete /> Product
          </button>
          <span className="cursor-pointer align-self-end ml-2" title="Duplicate" onClick={() => duplicateProductCard(productIndex)}>
            <MdContentCopy color="green" size={30} />
          </span>
        </div>
      </div>
      <div className="grid gap-4 mb-4 sm:grid-cols-12">
        <div className="col-span-5">
          <Dropdown
            label="Product Name"
            value={product.productID}
            onChange={(e) => handleProductChange(productIndex, 'productID', e.target.value)}
            options={products}
            placeholder="Select Product"
          />
        </div>
        <div className="col-span-5">
          <InputField
            label="Quantity"
            type="number"
            name="quantity"
            value={product.quantity}
            onChange={(e) => handleProductChange(productIndex, 'quantity', e.target.value)}
            placeholder="0 - 999"
            min={0}
          />
        </div>
        <button
          type="button"
          className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm text-center py-2 px-4 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 self-end justify-self-end col-span-2"
          onClick={() => addExpense(productIndex)}
        >
          +
        </button>
      </div>
      {product.expenses.map((expense, expenseIndex) => (
        <ExpenseItem
          key={expenseIndex}
          expense={expense}
          productIndex={productIndex}
          expenseIndex={expenseIndex}
          expenses={expenses}
          purchase={purchase}
          setPurchase={setPurchase}
        />
      ))}
    </div>
  );
};

export default ProductItem;