import { BiSolidPurchaseTag, BiTransfer } from "react-icons/bi";
import { BsDatabaseFillAdd } from "react-icons/bs";
import { FaBox, FaCartPlus, FaClipboardList, FaMoneyBillAlt, FaShoppingCart, FaWarehouse } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { MdMoneyOff } from "react-icons/md";
import { RiDashboardHorizontalFill } from "react-icons/ri";
import { TbShoppingCartQuestion } from "react-icons/tb";

export const navLinksData = [
    {
      to: "/",
      icon: <RiDashboardHorizontalFill />,
      text: "Dashboard",
    },
    {
      to: "/inventory",
      icon:<FaClipboardList />,
      text: "Inventory",
    },
    {
      to: "/sales",
      icon:<FaShoppingCart />,
      text: "Sales",
    },
    {
      to: "/pendingOrders",
      icon:<TbShoppingCartQuestion />,
      text: "Pending Sales Orders",
  },
    {
      to: "/sales-admin",
      icon:<FaCartPlus />,

      text: "Sales Admin",
    },
    {
      to: "/stock-transfers",
      icon:<BiTransfer />,

      text: "Stock Transfers",
    },
    {
      type: "separator",
    },
    {
      type: "header",
      text: "General Configs",
    },
    {
      to: "/products",
      icon:<FaBox />,
      text: "Products",
    },
    {
      to: "/manage-store",
      icon:<FaWarehouse />,
      text: "Manage Store",
    },
    {
      to: "/goods-expenses",
      icon:<BsDatabaseFillAdd />,
      text: "Goods Expenses",
    },
    {
      to: "/order-expenses",
      icon:<FaMoneyBillAlt />,
      text: "Order Expenses",
    },
    {
      to: "/sale-sources",
      icon: <FaLocationDot />,
      text: "Sale Sources",
    },
    {
      to: "/add-category-foc",
      icon: <MdMoneyOff />,
      text: "Foc Categories",
  },
  {
    to: "/purchase-details",
    icon:<BiSolidPurchaseTag />,
    text: "Purchase Orders",
  },
  ];
  