import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { MdEdit } from "react-icons/md";

dayjs.extend(utc);
dayjs.extend(timezone);
export const StockTransferPage = ()=>{
    const navigate = useNavigate();
    const location = useLocation();
    const statusMapper = {
        pending: "Pending",
        completed: "Completed",
    };    
    const query = new URLSearchParams(location.search);
    const initialView = Object.keys(statusMapper).includes(query.get("view"))
        ? query.get("view")
        : "pending";
    const [currentView, setCurrentView] = useState(initialView);
    const [transfersData, setTransfersData] = useState([]);

    const fetchTransfersData = async (view, filters = {}) => {
        try {
          const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/api/stockTransfers/`);
          url.searchParams.append("status", view || currentView);
          const response = await fetch(url);
          const data = await response.json();
          if(!response.ok) throw data
          // Process data
          setTransfersData(data);
        } catch (e) {
          console.error(e);
          alert(e.message || "Error Fetching Orders");
        }
      };

    useEffect(()=>{
        fetchTransfersData()
    },[location.search, currentView])

    const handleFilterChange = (filter) => {
        setCurrentView(filter);
        const query = new URLSearchParams(location.search);
        query.set("view", filter);
        navigate({ search: query.toString() });
        };
    return(
        <div className="col-span-12 lg:col-span-10 w-full flex flex-col justify-center p-2 md:p-0">
            <h1 className="text-[20px] sm:text-[25px] md:text-[30px] font-bold">Stock Transfers</h1>
            <div className="w-full flex flex-col items-end justify-end gap-4 px-8">
                <div className="flex justify-end items-end">
                <select
                    value={currentView}
                    onChange={(e) => handleFilterChange(e.target.value)}
                    className="rounded"
                >
                    {Object.keys(statusMapper).map((itm) => (
                    <option key={itm} value={itm}>
                        {statusMapper[itm]}
                    </option>
                    ))}
                    </select>
                </div>
                <button className="bg-[#62d5e9] rounded-lg py-1 px-3 text-sm font-semibold shadow-sm hover:brightness-[1.3]" onClick={()=>navigate('/addStockTransfer')}>
                    Transfer Stock
                </button>
            </div>
            <table className="bg-white min-w-full text-sm md:text-[16px] mt-4 overflow-x-auto">
                <thead className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    <tr className=" break-words whitespace-normal">
                        <td className="text-left p-2">Date</td>
                        <td className="text-left p-2">From</td>
                        <td className="text-left p-2">To</td>
                        <td className="text-left p-2"># Products</td>
                        <td className="text-left p-2">Actions</td>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                        {transfersData.length > 0 ?
                        transfersData.map((itm,idx)=>
                        <tr key={idx}>
                            <td className="text-left p-2">
                                {dayjs(itm.createdAt).tz('Asia/Amman').format('DD/MM/YYYY')}
                            </td>
                            <td className="text-left p-2">
                                { itm.fromStoreID.name  }
                            </td>
                            <td className="text-left p-2">
                                { itm.toStoreID.name  }
                            </td>
                            <td className="text-left p-2">
                                {itm.products.length}
                            </td>
                            <td className="text-left flex gap-1 p-2">
                                <FaEye size={20} color="grey" className="cursor-pointer" onClick={()=>navigate(`${itm._id}`)}/>
                                {itm.status==='pending' && <MdEdit size={19} color="grey"/>}
                            </td>
                        </tr>
                        ):
                        <tr>
                            <td colSpan="6" className="text-center py-4">
                                No results found
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}