import { useContext, useEffect, useState } from "react";
import AddNewOrder from "../containers/orderForm/AddOrderForm"
import AuthContext from "../AuthContext";

export const AddSalePage = () => {
  const [products, setAllProducts] = useState();
  const [stores, setAllStores] = useState();
  const [updatePage, setUpdatePage] = useState();
  const [saleSources, setSaleSources] = useState();
  const [expenses, setExpenses] = useState();

  const authContext = useContext(AuthContext);

  useEffect(() => {
    fetchProductsData();
    fetchStoresData();
    fetchAllSaleSources();
    fetchOrdersExpenses();
  }, [updatePage]);

  // Fetching Data of All Sales
  const fetchOrdersExpenses = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/orderExpenses`)
      const data = await res.json();
      setExpenses(data)
    } catch (e) {
      alert("something went wrong trying to fetch the goods expenses");
    }
  }

  // Fetching Data of All Products
  const fetchProductsData = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/get/${authContext.user}`)
      .then((response) => response.json())
      .then((data) => {
        setAllProducts(data);
      })
      .catch((err) => console.log(err));
  };

  // Fetching Data of All Stores
  const fetchStoresData = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/store/get`)
      .then((response) => response.json())
      .then((data) => {
        setAllStores(data);
      });
  };
  const fetchAllSaleSources =()=>{
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/salesources`)
    .then((response) => response.json())
    .then((data) => {
      setSaleSources(data);
      console.log(data)
    })
    .catch((err) => console.log(err));
  }

  const handlePageUpdate = () => {
    setUpdatePage(!updatePage);
  };

  if(!expenses || !saleSources || !products || !stores ) return
  
  return (
    <div className="col-span-12 lg:col-span-10 px-0 lg:px-2">
      <h2 className="font-bold mb-4 pb-4 border-b-2 border-black">Add Sale</h2>
      <AddNewOrder products={products} handlePageUpdate={handlePageUpdate} authContext={authContext} expenses={expenses} saleSources={saleSources} stores={stores} />
      </div>
  )
}