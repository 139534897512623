import { useEffect, useState } from "react";
import CustomModal from "../../../components/common/Modal"


export const OutForDeliveryModal = ({
  modalOpen,
  setModalOpen,
  ordrID,
  setShouldRefetch,
  setSubmitted
}) => {

  const [deliveryPerson, setDeliveryPerson] = useState()

  const markAsOutForDelivery = async (e) => {
    e.preventDefault();
    const user = localStorage.getItem('user');
        let parsedUser
        if (user) {
          parsedUser = JSON.parse(user);
          console.log(parsedUser, 'parsedUser');
        } else {
          console.log('No user found in local storage');
        }
    if (!deliveryPerson) return alert("Insert Delivery Person Name");
    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/markAsOutForDelivery`, {
          method: "PATCH",
          body:JSON.stringify({deliveryPerson, orderIDs:ordrID, newUserID:parsedUser._id}),
          headers: {
            "Content-Type":"application/json"
          }
        });
        const data = res.json()
        if (res.status === 400) alert(data.message);
        if (res.ok) {
          alert("Order is out for delivery");
          setShouldRefetch((prev) => !prev);
          setModalOpen(false)
          setSubmitted(true)
        }
    } catch (e) {
      alert("Something went wrong!");
    }
  }

  useEffect(() => {
    setDeliveryPerson();
  },[modalOpen])
  
  return (
    <CustomModal modalOpen={modalOpen} setModalOpen={setModalOpen} title={"Out For Delivery"} lgWidth={650} mdWidth={500}>
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-xl mb-4">Insert Delivery Person Name</h2>
        <form onSubmit={markAsOutForDelivery} className="flex flex-col w-full items-center justify-center">
        <input type="text"className="w-full rounded" onChange={(e) => setDeliveryPerson(e.target.value)} value={deliveryPerson} />
        <div className="w-full flex justify-between items-center mt-4">
          <button className="rounded-lg bg-red-500 hover:bg-red-300 px-6 py-1 text-white" type="button" onClick={()=>setModalOpen(false)}>
            Cancel
          </button>
          <button className="rounded-lg bg-green-500 hover:bg-green-300 px-6 py-1 text-white" type="submit">
            Submit
          </button>
        </div>
        </form>
      </div>

    </CustomModal>
  )
}