import React from "react";
import NavLinks from "./NavLinks";
import { navLinksData } from "../constants/navData";

function SideMenu() {
  const localStorageData = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="h-full flex-col justify-between  bg-white hidden lg:flex ">
      <div className="px-4 py-6">
      <nav aria-label="Main Nav" className="mt-6 flex flex-col space-y-1">
          {navLinksData.map((link, index) => {
            if (link.type === "separator") {
              return <hr key={index} />;
            } else if (link.type === "header") {
              return (
                <h4 key={index} className="text-gray-500">
                  {link.text}
                </h4>
              );
            } else {
              return (
                <NavLinks
                  key={index}
                  to={link.to}
                  imgSrc={link.imgSrc}
                  imgAlt={link.imgAlt}
                  text={link.text}
                  icon={link.icon}
                />
              );
            }
          })}
        </nav>
      </div>

      <div className="sticky inset-x-0 bottom-0 border-t border-gray-100">
        <div className="flex items-center gap-2 bg-white p-4 hover:bg-gray-50">
          <img
            alt="Profile"
            src={localStorageData.imageUrl}
            className="h-10 w-10 rounded-full object-cover"
          />

          <div>
            <p className="text-xs">
              <strong className="block font-medium">
                {localStorageData.firstName + " " + localStorageData.lastName}
              </strong>

              <span> {localStorageData.email} </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideMenu;
