export const InputField = ({ label,labelClasses, type, name, value, onChange,onInput, placeholder, disabled, min, max, classes, id, rows, required=false }) => (
    <div className="w-full">
      <label className={`block mb-2 text-sm font-medium text-gray-900 ${labelClasses}`}>{label}{required&&<span className="text-rose-700">*</span>}</label>
      <input
        type={type}
        rows={type === "textarea" ? rows : "5"}
        name={name}
        id={id? id : name}
        value={value}
        onChange={onChange}
        onInput = {onInput}
        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${classes}`}
        placeholder={placeholder}
        disabled={disabled}
        min={min ? min : 0}
        max={max ? max : undefined}
      />
    </div>
  );
  