import { useContext, useEffect, useState } from "react";
import AddPurchaseDetails from "../containers/addPurchase/AddPurchaseDetails"
import AuthContext from "../AuthContext";

export const AddPurchasePage = () => {
  const [products, setAllProducts] = useState([]);
  const [stores, setAllStores] = useState([]);
  const [goodsExpenses, setGoodsExpenses] = useState();

  const authContext = useContext(AuthContext);

  const fetchExpensesData = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/goodsExpenses`)
      .then((response) => response.json())
      .then((data) => {
        setGoodsExpenses(data);
      })
      .catch((err) => console.log(err));
  };
  const fetchStoresData = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/store/get`)
      .then((response) => response.json())
      .then((data) => {
        setAllStores(data);
      })
      .catch((err) => console.log(err));
  };
  const fetchProductsData = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/get/${authContext.user}`)
      .then((response) => response.json())
      .then((data) => {
        setAllProducts(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchProductsData();
    fetchStoresData();
    fetchExpensesData()
  }, []);

  if (!goodsExpenses || !products || !stores) return

  return (
    <div className="col-span-12 lg:col-span-10 flex flex-col">
      <h2 className="font-bold">Add Purchase Order</h2>
      <AddPurchaseDetails
        products={products}
        authContext={authContext}
        stores={stores}
        expenses={goodsExpenses}
      />
    </div>
  )
}