import { AddPurchasePage } from "../pages/AddPurchase";
import { AddSalePage } from "../pages/AddSale";
import CategoryFOC from "../pages/CategoriesFOC";
import { DashboardPage } from "../pages/Dashboard";
import GoodsExpense from "../pages/GoodsExpenses";
import Inventory from "../pages/Inventory";
import Login from "../containers/login/Login";
import NoPageFound from "../components/common/NoPageFound";
import OrderExpenses from "../pages/OrderExpenses";
import { PendingOrders } from "../containers/pendingOrders/PendingOrders";
import PrepareOrder from "../pages/PrepareOrder";
import { Products } from "../pages/Products";
import PurchaseDetails from "../pages/PurchaseDetails";
import Register from "../pages/Register";
import { SalesOrderDetails } from "../pages/SalesOrderDetails";
import {Sales} from "../pages/Sales";
import SalesSources from "../pages/SalesSources";
import { SinglePurchaseDetails } from "../pages/SinglePurchaseDetails";
import Store from "../pages/Store";
import { AddRefundPage } from "../pages/AddRefund";
import { SalesRefundDetails } from "../pages/salesRefundDetails";
import { ProductPiecesPage } from "../pages/ProductPieces";
import { SalesAdmin } from "../pages/SalesAdmin";
import { TransferStock } from "../pages/AddTransferStock";
import { StockTransferPage } from "../pages/StockTansfers";
import { StockTransferDetails } from "../pages/StockTransferDetails";

export const routes = {
  public: [
    { path: "/login", element: <Login /> },
    { path: "/register", element: <Register /> },
    { path: "*", element: <NoPageFound /> },
  ],
  protected: [
    { path: "/", element: <DashboardPage /> },
    { path: "/inventory", element: <Inventory />, password: false },
    { path: "/purchase-details", element: <PurchaseDetails />, password: false },
    { path: "/purchase-details/add", element: <AddPurchasePage />, password: false },
    { path: "/sales", element: <Sales />, password: false },
    { path: "/manage-store", element: <Store />, password: true },
    { path: "/products", element: <Products />, password: true },
    { path: "/goods-expenses", element: <GoodsExpense />, password: true },
    { path: "/order-expenses", element: <OrderExpenses />, password: true },
    { path: "/purchase-details/:id", element: <SinglePurchaseDetails />, password: false },
    { path: "/prepare-order/:id", element: <PrepareOrder />, password: true },
    { path: "/sale-sources", element: <SalesSources />, password: false },
    { path: "/add-category-foc", element: <CategoryFOC />, password: false },
    { path: "/pendingOrders", element: <PendingOrders />, password: false },
    { path: "/salesOrderDetails/:id", element: <SalesOrderDetails />, password: false },
    { path: "/sales/add", element: <AddSalePage />, password: false },
    { path: "/sales/add/:id", element: <AddSalePage />, password: false },
    { path: "/sales/addRefund", element: <AddRefundPage />, password: false },
    { path: "/sales/addRefund/:id", element: <AddRefundPage />, password: false },
    { path: "/salesRefundDetails/:id", element: <SalesRefundDetails />, password: false },
    { path: "/productPieces", element: <ProductPiecesPage />, password: false },
    { path: "/sales-admin", element: <SalesAdmin />, password: true },
    { path: "/addStockTransfer", element: <TransferStock />, password: false },
    { path: "/stock-transfers", element: <StockTransferPage />, password: true },
    { path: "/stock-transfers/:id", element: <StockTransferDetails />, password: false },
  ]
};