import { useState } from "react";
import CustomModal from "../../../components/common/Modal"

export const PurchaseResponseModal = ({resModalOpen, setResModalOpen , payload, product})=>{
    const [loadingQrs, setLoadingQrs] = useState(false);

    const handleGenerateQrs = async () => {
        setLoadingQrs(true);
        try {
          const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/productPieces/generateAddedQr`, {
            method: "POST",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify(payload.data),
          });
          const blob = await res.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `AddedQRCodes-(${product.productID.name}).zip`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        } catch (e) {
          alert("Error generating QRs, Please contact the technical team")
        } finally {
          setLoadingQrs(false)
          window.location.reload();
        }
      }

    return (
        <CustomModal modalOpen={resModalOpen} setModalOpen={setResModalOpen} lgWidth={700} mdWidth={550} title={'Purchase updated successfully'}>
            {payload.status ==='add' ? 
            <div className="p-3 w-full flex flex-col items-center justify-center gap-1">
                <p className="text-center">Generate the QRs for the new products</p>
                <p className="text-red-500 text-center">You will suffer from finding the QR for these if you do not <br/>
                do it now, Please Press Yes</p>
                <div className="flex  justify-center items-center gap-8 w-1/2 mt-4">
                    <button className="py-2 px-5 bg-[#F24822]  text-white rounded-lg font-semibold hover:brightness-[1.2]" onClick={()=>window.location.reload()}>
                        No
                    </button>
                    <button className="py-2 px-5 bg-[#14ae5c]  text-white rounded-lg font-semibold hover:brightness-[1.2]" onClick={handleGenerateQrs}>
                     {loadingQrs ? "Please wait..." : `Yes`}
                    </button>
                </div>
            </div>
            :
            <div className="p-3 w-full flex flex-col items-center justify-center gap-1">
                <p className="text-center">These Pieces were deleted from the system</p>
                <p className="text-red-500 text-center">
                    Please copy and save them in a safe place, detach their QR stickers, and do not use them anymore!
                </p>
                <div className="flex flex-col items-center w-full my-2">
                    <div className=" w-auto px-4 max-h-40 overflow-y-auto p-2 border border-gray-300 rounded">
                        {payload.data?.map((id, idx) => (
                            <span key={id} className="block">{idx + 1}. {id}</span>
                        ))}
                    </div>
                    <p
                        className="py-2 px-5 text-blue-500 hover:text-blue-800 hover:cursor-pointer underline rounded-lg font-semibold hover:brightness-[1.2]" 
                        onClick={() => {
                            const ids = payload.data?.join("\n");
                            navigator.clipboard.writeText(ids);
                            alert("IDs copied to clipboard!");
                        }}
                        >
                        Copy All
                    </p>
                </div>
                <button 
                    className="py-2 px-5 mt-2 bg-[#14ae5c]  text-white rounded-lg font-semibold hover:brightness-[1.2]" 
                    onClick={() => window.location.reload()}
                >
                    Done
                </button>
            </div>

            }
        </CustomModal>
    )
}