import Modal from "react-responsive-modal";
import { useState, useEffect } from "react";

const CustomModal = ({ modalOpen, setModalOpen, title, children, lgWidth, mdWidth }) => {
  const [modalWidth, setModalWidth] = useState(getModalWidth());

  function getModalWidth() {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 400) {
      return "100%"; // Full width for small screens
    } else if (screenWidth >= 1024) {
      return `${lgWidth || 320}px`; // Large screens (lgWidth or default 320px)
    } else if (screenWidth >= 768) {
      return `${mdWidth || 320}px`; // Medium screens (mdWidth or default 320px)
    } else {
      return "320px"; // Default for other screen sizes
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setModalWidth(getModalWidth());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); 

  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      center
      styles={{
        modal: {
          width: modalWidth,
          maxWidth: "100%",
          marginLeft: 0,
        },
      }}
    >
      {title && (
        <div className="flex mb-[25px] border-b-2 pb-2">
          <h2 className="font-bold">{title}</h2>
        </div>
      )}
      <div className="custom-modal-body">{children}</div>
    </Modal>
  );
};

export default CustomModal;
