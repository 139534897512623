import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import CustomModal from "../../../components/common/Modal";
import QrReader from "react-qr-reader";
import { useWindowSize } from "react-use";
import { handleScan } from "../../../utils/handleScan";
import { handlePrintClick } from "../../../utils/handlePrint";
import { HiRefresh } from "react-icons/hi";
import AuthContext from "../../../AuthContext";

export const PendingOrdersTable = ({ fetchOrders, orders, selectedStore }) => {
  const authContext = useContext(AuthContext);
  const newUserID = authContext.user;
  const [selectedItems, setSelectedItems] = useState([]);
  const [openScanModal, setOpenScanModal] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false)
  const [item, setItem] = useState("")
  const [fetchNow, setFetchNow] = useState(false);
  const { width, height } = useWindowSize();
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedOrderType, setSelectedOrderType] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 1000000;
  const { salesData = [], totalPages = 1 } = orders;

  useEffect(() => {
    setLoading(true);
    fetchOrders("pending", {
      date: selectedDate,
      orderType: selectedOrderType,
      limit: itemsPerPage,
      page: currentPage,
    }).finally(() => setLoading(false));
  }, [selectedDate, selectedOrderType, currentPage,fetchNow]);

  useEffect(()=>{
    if (shouldRefetch){
      reset()
      setFetchNow(prev=>!prev)
    }
    return ()=> setShouldRefetch(false)
  },[shouldRefetch, setShouldRefetch])
  
  useEffect(() => {
    reset()
  },[selectedStore])
  
  const reset= ()=>{
    setSelectedDate('')
    setSelectedOrderType('')
    setCurrentPage(1)
    setItem("")
    setSelectedItems([])
  }

  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedItems((prev) => [...prev, id]);
    } else {
      setSelectedItems((prev) => prev.filter((itemId) => itemId !== id));
    }
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
    setCurrentPage(1); 
  };

  const handleOrderTypeChange = (e) => {
    setSelectedOrderType(e.target.value);
    setCurrentPage(1); 
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const previewStyle = {
    height: height / 2,
    width: width / 1.35,
  };

  const cancelOrder = async()=>{
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/cancelPendingOrders`, {
        method: "PATCH",
        body:JSON.stringify({orderID : item, newUserID }),
        headers: {
          "Content-Type":"application/json"
        }
      });
      setOpenCancelModal(false)
      const result = res.json()
      if (!res.ok) throw (result.message || 'An Error Occurred');
      alert(`Order cancelled successfuly`);
      setShouldRefetch(true);
      if (res.status === 400) alert(result.message);
    } catch (e) {
      console.log(e);
      alert(e);
    }
  }
  
  const handleCancelButton = (element) =>{
    setOpenCancelModal(true)
    setItem(element._id)
  }
  return (
    <div className="px-2">
    {openScanModal&&
      <CustomModal modalOpen={openScanModal} setModalOpen={setOpenScanModal} title={"Scan Item"}>
        <QrReader
        containerStyle={previewStyle}
        onScan={(qrRes) => handleScan(qrRes)}
        delay={1000}
            />
      </CustomModal>}
      {openCancelModal && <CustomModal modalOpen={openCancelModal} setModalOpen={setOpenCancelModal} title={'Confirm Cancel Orders'}>
        <p>Are you Sure you want to Cancel order ?</p>
        <div className="popup-actions" style={{ display: 'grid', justifyContent: 'end' }}>
          <button onClick={cancelOrder} className="bg-green-500 border-green-500 border-2 rounded text-white p-2 hover:bg-white hover:text-green-500">
            Submit
          </button>
        </div>
      </CustomModal>}
      <div className="flex flex-col w-full justify-between">
      <div className="w-full justify-end items-center flex my-2 px-2">
          <button onClick={() => setShouldRefetch(true)} className="border-2 bg-green-100 rounded border-black p-2">
            <HiRefresh />
          </button>
        </div>
        <div className="flex gap-5 mb-4">
          <div className="flex flex-col gap-2">
            <label htmlFor='date-pending' className=' text-sm font-medium text-gray-90'>Delivery Time</label>
            <input
              type="date"
              name="date-pending"
              value={selectedDate || ""} 
              onChange={handleDateChange}  
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 min-w-[150px] focus:border-primary-600 p-2.5"
            />
          </div>
          <div className="flex flex-col gap-2">
              <label htmlFor='orderType' className=' text-sm font-medium text-gray-90'>Order Type</label>
              <select
                  name="orderType"
                  value={selectedOrderType}
                  onChange={handleOrderTypeChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 min-w-[150px] focus:border-primary-600 p-2.5"
              >
                  <option value="">All Types</option>
                  <option value="new">New</option>
                  <option value="refund">Refund</option>
              </select>
          </div>
        </div>
        <div className="flex gap-3 items-center justify-between pb-4 pr-8">
          <p className="text-[16px] ml-[2px]">Counter: {selectedItems.length}</p>
          <div className="flex gap-2">
            <button
              className="w-[50px] p-1 border-2 rounded border-blue-700 flex items-center justify-center bg-blue-500 disabled:bg-gray-500 disabled:border-gray-700 disabled:cursor-not-allowed"
              disabled={selectedItems.length}
              onClick={(e) => setOpenScanModal((prev) => !prev)}
            >
              <img
                alt=""
                src={require("../../../assets/camera.png")}
                className="items-center w-[30px] max-h-[25px]"
              />
            </button>
            <button
              className="w-[50px] p-1 border-2 rounded border-blue-700 flex items-center justify-center bg-blue-500 disabled:bg-gray-500 disabled:border-gray-700 disabled:cursor-not-allowed"
              disabled={!selectedItems.length}
              onClick={() => handlePrintClick(selectedItems)}
            >
              <img
                alt=""
                src={require("../../../assets/printer.png")}
                className="items-center w-[30px] max-h-[25px]"
              />
            </button>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="bg-white min-w-full divide-y-2 divide-gray-200 text-sm">
          <thead className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
            <tr>
              <td></td>
              <td>Order ID</td>
              <td>Customer</td>
              <td className="text-start">Time</td>
              <td className="text-start">Type</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {loading ? (
              <tr>
                <td colSpan="6" className="text-center py-4">
                  Loading...
                </td>
              </tr>
            ) : salesData.length > 0 ? (
              salesData.map((itm, idx) => (
                <tr key={idx}>
                  <td className="pl-2">
                    <input
                      type="checkbox"
                      value={itm._id}
                      onChange={(e) => handleCheckboxChange(e, itm._id)}
                      className="form-checkbox h-5 w-5 text-blue-500 border-blue-500 rounded border-2"
                    />
                  </td>
                  <td>{itm?.manualOrderId || itm?.orderID}</td>
                  <td>{itm.customerID?.fullName}</td>
                  <td className="">{itm.preferredDeliveryTime}</td>
                  <td className="capitalize"><span className={`${itm.orderType==='refund'? "bg-yellow-300 font-semibold" :""} p-1 rounded`}>{itm.orderType}</span></td>
                  <td>
                    <Link
                      to={`/${itm.orderType === "refund" ? "salesRefundDetails" : "salesOrderDetails"}/${itm._id}`}
                    >
                      <button className="bg-blue-500 border-blue-500 border-2 rounded text-white p-2 hover:bg-white hover:text-blue-500">
                        Details
                      </button>
                    </Link>
                    <button onClick={()=>handleCancelButton(itm)} className="bg-orange-500 border-orange-500 border-2 rounded text-white p-2 hover:bg-white hover:text-orange-500 ml-2"
                    >Cancel
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center py-4">
                  No results found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end items-center mt-2 space-x-4">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className="bg-white shadow-md text-gray-800 font-bold py-1 px-3 rounded-md disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-white hover:bg-gray-100"
        >
          {"<"}
        </button>
        <span className="text-gray-700">
          Page {totalPages > 0 ? currentPage : 0} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="bg-white shadow-md text-gray-800 font-bold py-1 px-3 rounded-md disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-white hover:bg-gray-100"
        >
          {">"}
        </button>
      </div>
    </div>
  );
};
