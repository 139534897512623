import { useCallback, useState } from "react"
import { ProductPieceActions } from "./ProductPiecesActions"
import { debounce } from "lodash";
import { SearchScanQR } from "./SearchScanQR";
export const ProductPieceHeader = ({ filters, setFilters, searchScanModal, setSearchScanModal, addProductPiece }) => {
  
  const [addPieceModal, setAddPieceModal] = useState(false);
  const [localFilters, setLocalFilters] = useState(filters);
  
  const statusMapper = {
    'outForDelivery': 'Out For Delivery',
    'readyForDelivery': 'Ready For Delivery',
    'available': 'Available',
    'needsRepair': 'Needs Repair',
    'damaged': 'Damaged',
    'fulfilled': 'Fulfilled',
    'issuance': 'Issuance',
    'all':undefined
  }
  
  const debouncedSetFilters = useCallback(
    debounce((newFilters) => {
      setFilters(newFilters); 
    }, 500), 
    []
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedFilters = { ...localFilters, [name]: value };
    setLocalFilters(updatedFilters);
    debouncedSetFilters(updatedFilters); 
  };

  return (
    <div className="w-full flex-col justify-start items-start">
      <h2 className="font-bold text-[35px]">Product Pieces</h2>
      <div className="flex flex-wrap items-end justify-between my-12">
        <div className="flex justify-start items-end flex-wrap">
          <div className="flex flex-col items-start mr-3">
            <label className="font-bold">Product</label>
            <input className="rounded" type="text" name="product" value={localFilters.product || ""} onChange={handleInputChange} />
          </div>
          <div className="flex flex-col items-start">
            <label className="font-bold">ID</label>
            <input className="rounded" type="text" name="id" value={localFilters.id || ""} onChange={handleInputChange} />
          </div>
        </div>

        <div className="flex items-end justify-start">
          <button className="bg-blue-500 rounded px-2 py-2 text-white mr-3"
            onClick={()=>setSearchScanModal(true)}
          >Scan & Find</button>
          <button className="bg-green-500 rounded px-2 py-2 text-white"
            onClick={()=>setAddPieceModal(true)}
          >+ Add</button>
        </div>
        {addPieceModal && <ProductPieceActions modalOpen={addPieceModal} setModalOpen={setAddPieceModal} />}
        {searchScanModal && <SearchScanQR modalOpen={searchScanModal} setModalOpen={setSearchScanModal} setFilters={setFilters} setLocalFilters={setLocalFilters} />}

      </div>
    </div>
  )
}