import { MdDelete } from "react-icons/md";
import { Dropdown } from "../../../components/common/dropdowns/Dropdown";
import { InputField } from "../../../components/common/InputFields/InputField";

export const ExpenseItem = ({ item, index, expensesOptions, editExpense, deleteExpense }) => (
  <div className="grid grid-cols-12 gap-2 justify-between items-center mt-2">
    <div className="flex flex-col justify-start col-span-9">
      <Dropdown
        label="Expenses"
        value={item._id}
        onChange={(e) => editExpense(index, "expenseID", e.target.value)}
        options={expensesOptions}
        placeholder="-- Select Expense --"
      />
    </div>
    <div className="flex flex-col justify-start col-span-2 justify-self-end">
      <InputField
        label="Amount"
        type="number"
        min={0}
        value={item.amount}
        onChange={(e) => editExpense(index, "amount", Number(e.target.value))}
        placeholder="JOD"
      />
    </div>
    <div className="col-span-1 flex items-end justify-end h-full">
      <MdDelete
        size={30}
        color="red"
        onClick={() => deleteExpense(index)}
        className="cursor-pointer"
      />
    </div>
  </div>
);
