export const TransferDetailsTable = ({transfer, fromName, toName, scanned})=>{
  const calculateScanned = (id) => scanned.filter(itm=>itm.productID === id || itm.productID._id === id ).length
  const handleDownload = (productName, productPieces) => {
    
    const pieceIds = productPieces.map((piece) => piece.productPieceID._id).join(", ");    
    const formattedText = `${productName}: {${pieceIds}}`;
    const blob = new Blob([formattedText], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${fromName}-${toName}-(${productName}).txt`;
    link.click();
  };

  return (
    <table className="bg-white min-w-full text-sm md:text-[16px] overflow-x-auto">
        <thead className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
        <tr>
            <td className="p-2">Product</td>
            <td className="p-2">Qty</td>
            {transfer.status === "completed" && <td className="p-2">Action</td>}

        </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
        {transfer.products.map((itm, idx) => {
            return (
                <tr key={idx}>
                <td className="p-2">{itm.productID.name}</td>
                <td className="p-2">{calculateScanned(itm.productID._id)}/{itm.quantity}</td>
                {transfer.status === "completed" && (
                <td className="p-2">
                    <button
                    className="text-blue-500 underline cursor-pointer"
                    onClick={() => handleDownload(itm.productID.name, itm.productPieces)}
                    >
                    Download IDs
                    </button>
                </td>
                )}
                </tr>      
            )
        })}
        </tbody>
  </table>
  )
}