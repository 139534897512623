
export const CancelOrderItemsTable = ({ products, scanned, setScanned }) => {
  const calculateScanned = (id) => scanned.filter(itm=>itm.productID._id === id).length

  const getScannedItemsForProduct = (prID) => scanned.filter(itm => itm.productID._id === prID);

  const handleRemoveScannedItem = (itmID) => {
    setScanned((prev)=>prev.filter(itm => itm._id !== itmID));
  }
  return (
    <table className="min-w-full divide-gray-200 text-sm">
    <tbody className="divide-y divide-gray-200">
      {products.map((itm, idx) => {
        return (
          <>
          <tr className="">
            <td>{itm.name}</td>
            <td className="text-center flex flex-col justify-start">{calculateScanned(itm.productID._id)}/{itm.quantity}
            </td>
            </tr>
            <br />
            {getScannedItemsForProduct(itm.productID._id).map(itm => (
                <div className="flex justify-between pl-3">
                <p>{itm._id}</p>
                <span className="text-red-500 text-[30px] cursor-pointer" onClick={()=>handleRemoveScannedItem(itm._id)}>-</span>
                </div>
              ))}
          </>
        )
      })}
    </tbody>
  </table>
  )
}