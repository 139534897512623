import "./styles.css";
import React, { useEffect, useState } from "react";
import Select from "react-select";

const INCLUDED_COUNTRY_CODE = ["JO","EG","IQ","PS"]; // Replace with the code of the country you want to include

export const CountrySelect = ({ onChange, value}) => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();

  useEffect(() => {
    // if(selectedCountry.value) onChange(selectedCountry)
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        // Filter out the excluded country
        const filteredCountries = data.countries.filter(
          (country) => INCLUDED_COUNTRY_CODE.includes(country.value)
        );
        setCountries(filteredCountries);
        // setSelectedCountry(data.userSelectValue);
      });
  }, []);

  const handleChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    if (onChange) onChange(selectedOption); // Call the onChange prop with the selected option
  };

  return (
    <Select
      options={countries}
      value={value?.label ? value : selectedCountry}
      onChange={handleChange}
      placeholder={"Select a country"}
    />
  );
};
