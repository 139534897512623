import { InputField } from "../../../components/common/InputFields/InputField";

export const DeliveryTimeSelection = ({ deliveryTimeMode, setDeliveryTimeMode, setTimeError, setSaleGeneralInfo, saleGeneralInfo, updateNestedState }) => {
    const deliveryTimeModes = {
        anytime: 'Any Time of the day',
        timeRange: 'From-To',
        specificTime:'Specific Time'
      }

      const handleSpecificTimeChange = (e) => {
        const selectedTime = e.target.value;
        const now = new Date();
        const currentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate()); 
    
        const deliveryDate = new Date(saleGeneralInfo.preferredDeliveryDate); 
        const [hours, minutes] = selectedTime.split(":");
        const selectedDateTime = new Date(deliveryDate); 
        selectedDateTime.setHours(hours, minutes, 0, 0);
    
        if (deliveryDate.toDateString() === currentDate.toDateString()) {
            if (selectedDateTime < now) {
                setTimeError && setTimeError('The selected time cannot be in the past. Please choose a valid time.');
            } else {
                setTimeError && setTimeError(null);
            }
        } else {
            setTimeError && setTimeError(null); 
        }
    
        updateNestedState(setSaleGeneralInfo, 'preferredDeliveryTime', { time: selectedTime });
    };    

    const handleFromTimeChange = (e) => {
        const selectedTime = e.target.value;
        const now = new Date();
        const currentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate()); 
    
        const deliveryDate = new Date(saleGeneralInfo.preferredDeliveryDate); 
        const [fromHours, fromMinutes] = selectedTime.split(":");
        const selectedFromDateTime = new Date(deliveryDate); 
        selectedFromDateTime.setHours(fromHours, fromMinutes, 0, 0); 
    
        if (deliveryDate.toDateString() === currentDate.toDateString()) {
            if (selectedFromDateTime < now) {
                setTimeError && setTimeError('The start time cannot be in the past. Please choose a valid time range.');
            }
        }
    
        const toTime = saleGeneralInfo.preferredDeliveryTime.to;
        if (toTime) {
            const [toHours, toMinutes] = toTime.split(":");
            const selectedToDateTime = new Date(deliveryDate);
            selectedToDateTime.setHours(toHours, toMinutes, 0, 0);
    
            if (selectedFromDateTime > selectedToDateTime) {
                setTimeError && setTimeError('The start time cannot be later than the end time. Please select a valid time range.');
            } else {
                setTimeError && setTimeError(null);
            }
        } else {
            setTimeError &&  setTimeError(null);
        }
    
        updateNestedState(setSaleGeneralInfo, 'preferredDeliveryTime', { ...saleGeneralInfo.preferredDeliveryTime, from: selectedTime });
    };
       

    const handleToTimeChange = (e) => {
        const selectedTime = e.target.value;
        const now = new Date();
        const currentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    
        const deliveryDate = new Date(saleGeneralInfo.preferredDeliveryDate); 
        const [toHours, toMinutes] = selectedTime.split(":");
        const selectedToDateTime = new Date(deliveryDate); 
        selectedToDateTime.setHours(toHours, toMinutes, 0, 0); 
    
        const fromTime = saleGeneralInfo.preferredDeliveryTime.from;
    
        if (deliveryDate.toDateString() === currentDate.toDateString()) {
            if (selectedToDateTime < now) {
                setTimeError && setTimeError('The end time cannot be in the past. Please choose a valid time.');
            }
        }
    
        if (fromTime) {
            const [fromHours, fromMinutes] = fromTime.split(":");
            const fromDateTime = new Date(deliveryDate);
            fromDateTime.setHours(fromHours, fromMinutes, 0, 0);
    
            if (selectedToDateTime <= fromDateTime) {
                setTimeError && setTimeError('The end time must be later than the start time. Please select a valid time range.');
            } else {
                setTimeError && setTimeError(null);
            }
        } else {
            setTimeError && setTimeError(null);
        }
    
        updateNestedState(setSaleGeneralInfo, 'preferredDeliveryTime', { ...saleGeneralInfo.preferredDeliveryTime, to: selectedTime });
    };
    
    
    
    return (
        <div className="w-full flex flex-col col-span-12 items-start justify-center">
        <label className="font-medium mb-3">Delivery Time</label>
        {Object.keys(deliveryTimeModes).map((val) => (
            <div className="flex items-center w-100" key={val}>
            <input
                id={`time-check-${val}`}
                name="time-mode"
                type="radio"
                checked={deliveryTimeMode === val}
                onClick={() => {
                setDeliveryTimeMode(val);
                if (val === 'anytime') setSaleGeneralInfo((prev) => ({ ...prev, preferredDeliveryTime: { anytime: true } }));
                if (val === 'timeRange') setSaleGeneralInfo((prev) => ({ ...prev, preferredDeliveryTime: { from: "", to: "" } }));
                if (val === 'specificTime') setSaleGeneralInfo((prev) => ({ ...prev, preferredDeliveryTime: { time: "" } }));
                }}
            />
            <label className="ml-2" htmlFor={`time-check-${val}`}>{deliveryTimeModes[val]}</label>
            </div>
        ))}
        {deliveryTimeMode === 'specificTime' && (
            <InputField
            label="Preferred Delivery Time"
            type="time"
            name="specificTime"
            value={saleGeneralInfo.preferredDeliveryTime.time}
            required
            onChange={handleSpecificTimeChange}
            />
        )}
        {deliveryTimeMode === 'timeRange' && (
            <>
                <InputField
                    label="From"
                    type="time"
                    name="from"
                    required
                    value={saleGeneralInfo.preferredDeliveryTime.from}
                    onChange={handleFromTimeChange}
                />
                <InputField
                    label="To"
                    type="time"
                    name="to"
                    required
                    value={saleGeneralInfo.preferredDeliveryTime.to}
                    onChange={handleToTimeChange}
                />
            </>
        )}
        </div>
    )
}
  