import { useNavigate } from "react-router-dom";
import CustomModal from "../../../components/common/Modal"

export const ConfirmPickingModal = ({ modalOpen, setModalOpen, orderID, scanned, storeID }) => {
  const navigate = useNavigate();

  const handleConfirmPickingOrder = async () => {
    const user = localStorage.getItem('user');
    let parsedUser
    if (user) {
      parsedUser = JSON.parse(user);
      console.log(parsedUser, 'parsedUser');
    } else {
      console.log('No user found in local storage');
    }
    const body = {
      orderID,
      newUserID:parsedUser._id,
      scannedItems: scanned.map(item => item._id)
    }
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/confirmPicking`, {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json",
        },
      }
      );
      const data = await res.json();
      if (res.status === 400) alert(data.message);
      if (res.ok) navigate(`/pendingOrders?store=${storeID}`);
    } catch (e) {
      console.error(e)
      alert("Something went wrong!");
    }
  }
  return (
    <CustomModal modalOpen={modalOpen} setModalOpen={setModalOpen} title={"Confirm Picking"} >
      <div className="flex flex-col items-center justify-center text-center">
        <p>By confirming, the order status will be "Ready For Delivery"</p>
        <div className="flex items-center justify-center mt-4 w-full">
          <button className="rounded p-2 bg-red-500 text-white" onClick={()=>setModalOpen(false)}>Cancel</button>
          <button className="rounded p-2 bg-green-500 text-white ml-3" onClick={()=>handleConfirmPickingOrder()}>Confirm</button>
        </div>
      </div>
    </CustomModal>
  )
}