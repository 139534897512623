import { useState } from "react";
import CustomModal from "../../../components/common/Modal";
import QrScanner from "../../../components/common/QrScanner";

export const RefundItemModal = ({ modalOpen, setModalOpen, products,originalProducts, scannedItems, setScannedItems }) => {
  const [showScanner, setShowScanner] = useState(true)
  const handleScannedProduct = (scannedItem) => {    
    setModalOpen(false);
    let productListed = products.find(itm => 
     scannedItem.productID._id === itm.productID._id
    );
    
    if (!productListed) return alert("Product is not requested in the order");
    
    let productInOriginalOrder = originalProducts.flatMap(itm => itm.productPieces.map(p=>p.productPieceID))
    .find(itm =>
      itm === scannedItem._id
    );
    if (!productInOriginalOrder) {
      return alert("This item is not associated with the original order");
    }

    let isProdcutScanned = scannedItems.find(itm => {
      return scannedItem._id === itm._id
    }
  );
    if (isProdcutScanned) return alert("Item is already scanned, please pick another item");
    
    const scannedCountForThisProduct = scannedItems.filter(itm => itm.productID._id === scannedItem.productID._id).length
    
    if (scannedCountForThisProduct >= productListed.quantity) return alert(`${productListed.name} is already filled for this order!`);
    if(scannedItem.status !== "fulfilled") return alert("You cannot refund unfulfilled items")

    setScannedItems((prev)=>[...prev, scannedItem]);
  }

  return (
    <CustomModal modalOpen={modalOpen} setModalOpen={setModalOpen} title={"Scan Item"}>
      <QrScanner onScanFn={handleScannedProduct} showScanner={showScanner} setShowScanner={setShowScanner} />
    </CustomModal>
  )
}