import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { UpdateOrderStatusModal } from "./UpdateOrderStatusModal";
import { UpdateRefundStatusModal } from "./UpdateRefundStatusModal";
import { useWindowSize } from "react-use";
import CustomModal from "../../../components/common/Modal";
import QrReader from "react-qr-reader";
import { handleScan } from "../../../utils/handleScan";
import { handlePrintClick } from "../../../utils/handlePrint";
import { HiRefresh } from "react-icons/hi";
import { InputField } from "../../../components/common/InputFields/InputField";
import debounce from "lodash/debounce";
import { currencyMapper } from "../../../utils/currency";

export const OutForDeliveryTable = ({ fetchOrders, orders, selectedStore}) => {
  const [updateStatusModal, setUpdateStatusModal] = useState(false);
  const [refundFulfilModal, setRefundFulfilModal] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [fetchNow, setFetchNow] = useState(false);
  const [refundOrder, setRefundOrder] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [search, setSearch] = useState('');
  const [openScanModal, setOpenScanModal] = useState(false);
  const { width, height } = useWindowSize();
  const [selectedOrderType, setSelectedOrderType] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 1000000;
  const [items, setItems]= useState([])
  const { salesData = [], totalPages = 1 } = orders;

  useEffect(() => {
      setLoading(true);
      fetchOrders("outForDelivery", {
        orderType: selectedOrderType,
        driver: search,
        limit: itemsPerPage,
        page: currentPage,
      }).finally(() => setLoading(false));
  }, [selectedOrderType, currentPage,fetchNow]);

  const fetchOrdersDebounced = useCallback(
    debounce(() => {
      fetchOrders("outForDelivery", {
        orderType: selectedOrderType,
        driver: search,
        limit: itemsPerPage,
        page: currentPage,
      });
    }, 500),
    [search, currentPage] 
  );


  useEffect(() => {
    fetchOrdersDebounced();
    return () => {
      fetchOrdersDebounced.cancel();
    };
  }, [search,fetchOrdersDebounced]);

  useEffect(()=>{
    if (shouldRefetch){
      reset()
      setFetchNow(prev=>!prev)  
    }
    return ()=> setShouldRefetch(false)
  },[shouldRefetch, setShouldRefetch])
  
  useEffect(() => {
    reset()
  },[selectedStore])
  
  const reset = ()=>{
    setSearch('')
    setSelectedOrderType('')
    setCurrentPage(1)
    setSelectedItems([])
    setSelectedOrders([])
  }

  const handleCheckboxChange = (e, id, item) => {
    if (e.target.checked) {
      setSelectedItems((prev) => [...prev, id]);
      setSelectedOrders((prev) => [...prev, item]);
      setItems((prev) => [...prev, item]);
    } else {
      setSelectedItems((prev) => prev.filter((itemId) => itemId !== id));
      setSelectedOrders((prev) => prev.filter((order) => order._id !== id));
      setItems((prev) => prev.filter((item) => item._id !== id));
    }
  };
  const handleOrderTypeChange = (e) => {
    setSelectedOrderType(e.target.value);
    setCurrentPage(1); 
  };
  
  const handleOutForDelivery = () => {
    setUpdateStatusModal(true); 
  };

  const handleRefundResolve = (itm) => {
    setRefundOrder(itm);
    setRefundFulfilModal(true); 
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const previewStyle = {
    height: height / 2,
    width: width / 1.35,
  };

  const totalReceivable = salesData
    .filter((itm) => selectedItems.includes(itm._id))
    .reduce((sum, itm) => sum + (itm.deliveryCashCollected || 0), 0);

  return (
    <div className="px-2">
      {openScanModal && (
        <CustomModal modalOpen={openScanModal} setModalOpen={setOpenScanModal} title={"Scan Item"} lgWidth="w-full">
          <QrReader
            containerStyle={previewStyle}
            onScan={(qrRes) => handleScan(qrRes)}
            delay={1000}
          />
        </CustomModal>
      )}
      <div className="w-full justify-end items-center flex my-2 px-2">
          <button onClick={() => setShouldRefetch(true)} className="border-2 bg-green-100 rounded border-black p-2">
            <HiRefresh />
          </button>
      </div>
      <div className="flex flex-col">
        <div className="flex gap-5 mb-4">
          <div className="flex flex-col ">
            <InputField
              label="Search Driver"
              type="text"
              name="driver-name"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Driver Name..."
              classes={'!bg-white'}
              />
          </div>
          <div className="flex flex-col gap-2">
          <label htmlFor='orderType' className=' text-sm font-medium text-gray-90'>Order Type</label>
            <select
              value={selectedOrderType}
              name="orderType"
              onChange={handleOrderTypeChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 min-w-[150px] focus:border-primary-600 p-2.5"
            >
              <option value="">All Types</option>
              <option value="new">New</option>
              <option value="refund">Refund</option>
            </select>
          </div>
        </div>
        <span className="flex items-center justify-between mb-4">
          <p className="mt-2">Total Receivable: {totalReceivable} {!salesData[0] ? "" : (currencyMapper[salesData[0]?.StoreID?.country] || 'JOD')}</p> 
          <div className="flex items-center gap-2">
            <button
              className="w-[50px] p-1 border-2 rounded border-blue-700 flex items-center justify-center bg-blue-500 disabled:bg-gray-500 disabled:border-gray-700 disabled:cursor-not-allowed "
              disabled={selectedItems.length}
              onClick={() => setOpenScanModal((prev) => !prev)}
            >
              <img
                alt=""
                src={require('../../../assets/camera.png')}
                className="items-center w-[30px] max-h-[25px]"
              />
            </button>
            <button
              className="w-[50px] p-1 border-2 rounded border-blue-700 flex items-center justify-center bg-blue-500 disabled:bg-gray-500 disabled:border-gray-700 disabled:cursor-not-allowed "
              disabled={!selectedItems.length}
              onClick={() => handlePrintClick(selectedItems)}
            >
              <img
                alt=""
                src={require("../../../assets/printer.png")}
                className="items-center w-[30px] max-h-[25px]"
              />
            </button>
            <button
              className={`max-h-[50px] ${selectedItems.length === 0 ? 'bg-stone-400' : 'bg-green-500 hover:bg-green-400'} border rounded text-white px-2 text-[14px] py-1 sm:py-2 ml-1`}
              disabled={selectedItems.length === 0}
              onClick={handleOutForDelivery}
            >
              Update Selected
            </button>
          </div>
        </span>
      </div>
      
      <div className=" overflow-x-auto">
        <table className="bg-white min-w-full divide-y-2 divide-gray-200 text-sm">
          {updateStatusModal && (
            <UpdateOrderStatusModal
              modalOpen={updateStatusModal}
              ordrID={selectedItems}
              orders={selectedOrders}
              setSelectedOrders={setSelectedOrders}
              selectedItems={items}
              setSelectedItems={setSelectedItems}
              setModalOpen={setUpdateStatusModal}
              setShouldRefetch={setShouldRefetch}
              totalReceivable={totalReceivable}
            />
          )}

          {refundFulfilModal && (
            <UpdateRefundStatusModal
              modalOpen={refundFulfilModal}
              order={refundOrder}
              setModalOpen={setRefundFulfilModal}
              setShouldRefetch={setShouldRefetch}
            />
          )}

          <thead className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
            <tr>
              <td></td>
              <td>ID</td>
              <td className="text-center">Driver</td>
              <td className="text-center">Total Receivable</td>
              <td className="text-start">Type</td>
              <td className="text-center">Actions</td>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {loading ? (
              <tr>
                <td colSpan="6" className="text-center py-4">
                  Loading...
                </td>
              </tr>
            ) : salesData.length > 0 ? (
              salesData.map((itm) => (
                <tr key={itm._id}>
                  <td className="pl-5">
                    {itm.orderType !== "refund" && (
                      <input
                        type="checkbox"
                        value={itm._id}
                        onChange={(e) => handleCheckboxChange(e, itm._id, itm)}
                        className="form-checkbox h-5 w-5 text-blue-500 border-blue-500 rounded border-2"
                      />
                    )}
                  </td>
                  <td>{itm?.manualOrderId || itm?.orderID}</td>
                  <td className="text-center">{itm.deliveryPerson}</td>
                  <td className="text-center">{-itm?.refundDetails?.cashToGiveDelivery || itm?.deliveryCashCollected} {currencyMapper[itm?.StoreID?.country] || 'JOD'}</td>
                  <td className="capitalize">
                    <span className={`${itm.orderType === "refund" ? "bg-yellow-300 font-semibold" : ""} p-1 rounded`}>
                      {itm.orderType}
                    </span>
                  </td>
                  <td className="flex items-center justify-center gap-1">
                    <Link to={`/${itm.orderType === 'refund' ? 'salesRefundDetails' : 'salesOrderDetails'}/${itm._id}`}>
                      <button className="bg-blue-500 text-xs sm:text-sm border-blue-500 border-2 rounded text-white p-1 xs:p-2 hover:bg-white hover:text-blue-500">
                        View
                      </button>
                    </Link>
                    {itm.orderType === "refund" && (
                      <button
                        className="bg-blue-500 text-xs sm:text-sm border-blue-500 border-2 rounded text-white p-1 xs:p-2 hover:bg-white hover:text-blue-500"
                        onClick={() => handleRefundResolve(itm)}
                      >
                        Resolve
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center py-4">
                  No results found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* Pagination Controls */}
      <div className="flex justify-end items-center mt-2 space-x-4">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className="bg-white shadow-md text-gray-800 font-bold py-1 px-3 rounded-md disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-white hover:bg-gray-100"
        >
          {"<"}
        </button>
        <span className="text-gray-700">
          Page {totalPages > 0 ? currentPage : 0} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="bg-white shadow-md text-gray-800 font-bold py-1 px-3 rounded-md disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-white hover:bg-gray-100"
        >
          {">"}
        </button>
      </div>
    </div>
  );
};
