import React, { useState, useEffect, useContext } from "react";
import AddCategorysModal from "../containers/categoriesFoc/AddCategoryFOCModal";

function CategoryFOC() {
  const [showExpensesModal, setShowExpensesModal] = useState(false);
  const [allCategories, setAllCategories] = useState();
  const [updatePage, setUpdatePage] = useState(false);

  useEffect(() => {
    fetchOrdersExpenses();
  }, [updatePage]);


  // Fetching Data of All Products
  const fetchOrdersExpenses = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/focCategories`)
      .then((response) => response.json())
      .then((data) => {
        setAllCategories(data);
      })
      .catch((err) => console.log(err));
  };

  // Modal for Sale Add
  const addSaleModalSetting = () => {
    setShowExpensesModal(!showExpensesModal);
  };

  // Handle Page Update
  const handlePageUpdate = () => {
    setUpdatePage(!updatePage);
  };

  return (
    <div className="col-span-12 lg:col-span-10  flex justify-center">
      <div className=" flex flex-col gap-5 w-11/12">
        {showExpensesModal && (
          <AddCategorysModal
            setShowOrdersExpenseModal={setShowExpensesModal}
            handlePageUpdate={handlePageUpdate}
          />
        )}
        {/* Table  */}
        <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 ">
          <div className="flex justify-between pt-5 pb-3 px-3">
            <div className="flex gap-4 justify-center items-center ">
              <span className="font-bold">FOC Categories</span>
            </div>
            <div className="flex gap-4">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 text-xs  rounded"
                onClick={addSaleModalSetting}
              >
                Add Category FOC
              </button>
            </div>
          </div>
          <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
            <thead>
              <tr>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Name
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {allCategories && allCategories.length > 0 && allCategories.map((element, index) => {
                return (
                  <tr key={element._id}>
                    <td className="whitespace-nowrap px-4 py-2  text-gray-900">
                      {element.name}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CategoryFOC;
