import React from 'react';
import QrReader from 'react-qr-reader';
import { useWindowSize } from 'react-use';

const QrScanner = ({
  showScanner,
  setShowScanner,
  onScanFn,
  getPieceData = true,
}) => {
  const { width, height } = useWindowSize();

  const handleScan = async (qrRes) => {
    if (qrRes) {
      let data = qrRes;
      try {
        if (getPieceData) {
          const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/productPieces/get/${qrRes}`);
           data = await res.json();
        }
        onScanFn(data)
        setShowScanner(false);

      } catch (e) {
        console.error('Error processing QR code:', e);
        alert('Invalid QR code or item not found');
      }
        }
    };

    const handleError = (err) => {
      alert("Error Scanning the QR");
    };

    const previewStyle = {
        height:height/2,
        width:width/1.35
    };

  return (
    <>

        {showScanner && <QrReader
        containerStyle={previewStyle}
        onScan={(qrRes) => handleScan(qrRes)}
        delay={1000}
            />}
    </>
    );
};

export default QrScanner;
