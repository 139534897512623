import { useContext, useEffect, useState } from "react"
import AuthContext from "../AuthContext";
import { InputField } from "../components/common/InputFields/InputField";
import { Dropdown } from "../components/common/dropdowns/Dropdown";
import { MdDelete } from "react-icons/md";
import { DeliveryTimeSelection } from "../containers/orderForm/components/DeliveryTImeComponent";
import { AddressInput } from "../components/common/InputFields/AddressInput";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { currencyMapper } from "../utils/currency";

export const AddRefundPage = () => {
    const authContext = useContext(AuthContext);
    const [originalOrderID,setOriginalOrderID]=useState("")
    const [originalOrder,setOriginalOrder]=useState()
    const [deliveryTimeMode, setDeliveryTimeMode]=useState('anytime')
    const [newInsertedAddress, setNewInsertedAddress] = useState("");
    const [isNewAddress, setIsNewAddress] = useState(true);
    const [refundOrder, setRefundOrder] = useState({
        userID: authContext.user,
        StoreID:'',
        sourceId:'',
        products: [],
        newDeliveryAmount: 0,
        originalOrderId:'',
        refundReason:'',
        customerID:'',
        country:{},
        city:{},
        notes:'',
        deliveryAddress:'',
        preferredDeliveryDate:'',
        preferredDeliveryTime:{anytime:true},
        customerRefundAmount:0
        });
    const { id } = useParams();
    const productTemplate = { productID: {}, quantity: 0, price: 0, name: '', discount:0 }
    const currentOrderCurrency = currencyMapper[originalOrder?.StoreID.country]

    
    useEffect(() => {
        if (!originalOrder) return;
    
        setRefundOrder((prev) => ({
            ...prev,
            customerID: originalOrder.customerID._id,
            country: originalOrder.country,
            city: originalOrder.city,
            StoreID:originalOrder.StoreID._id || originalOrder.StoreID,
            sourceId:originalOrder.sourceId
        }));
    }, [originalOrder]);
    
    useEffect(()=>{
        if(id) fetchOrderByID();
    },[id])
    
    const fetchOrderByID = async () => { 
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/get/refundOrder/${id}`);
            if (!response.ok) {
              throw new Error('Error Occurred');
            }
            let data = await response.json()
            
            const order = {
                userID: authContext.user,
                // StoreID:data.StoreID?._id,
                sourceId:data.sourceId,
                products: data.products,
                newDeliveryAmount: data.deliveryAmount,
                manualOrderId:data.manualOrderId,
                originalOrderId:data.originalOrderId.manualOrderId,
                refundReason:data.refundReason,
                customerID:data.customerID._id,
                notes:data.notes,
                deliveryAddress:data.deliveryAddress,
                preferredDeliveryDate:dayjs(data.preferredDeliveryDate).format('YYYY-MM-DD'),
                customerRefundAmount:data.TotalSaleAmount
            }
        
            let preferredDeliveryTime = { anytime: true };
            if (data.preferredDeliveryTime.time) {
              preferredDeliveryTime = data.preferredDeliveryTime;
              setDeliveryTimeMode('specificTime')
            }
            if (data.preferredDeliveryTime.timeRange) {
              preferredDeliveryTime = data.preferredDeliveryTime.timeRange;
              setDeliveryTimeMode('timeRange')
            }

            setRefundOrder(()=>({...data,...order,preferredDeliveryTime}));
            setOriginalOrder(()=>data.originalOrderId)
          } catch (error) {
            console.error('Error fetching products:', error);
            alert (error)
          }      
    };

    const fetchSalesData = () => {        
        if (originalOrderID === originalOrder?.manualOrderId) return
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/get/manual-id/${originalOrderID}?type=refund`)
          .then(async (response) => {
            const data=await response.json()
            if(!response.ok){
                throw data.message
            }
            return data
            })
          .then((data) => {
            setOriginalOrder(data);
            setRefundOrder(refund=>({...refund, originalOrderId:data.manualOrderId}))
          })
          .catch((err) => {
              setOriginalOrder()
              console.log(err)
              alert(err)
          });
    };

    const handleInputChange = (setter, name, value) => {
    setter((prev) => ({ ...prev, [name]: value }));
    };

    const addProduct = ()=>{
    if(originalOrder.products.length ===refundOrder.products.length) return
    setRefundOrder(refund=>({...refund,products:[...refund.products,productTemplate]}))
    };

    const disabledOptions =(index)=>{ 
    return refundOrder.products
    .filter((prod, i) => i !== index)
    .map(prod => prod.productID._id);
    }

    const removeProduct=(product)=>{
    setRefundOrder(refund => ({
        ...refund,
        products: refund.products.filter((item) =>item !== product )
        })); 
    };

    const handleRadioChange = (value) => {
        setRefundOrder((prev)=>({...prev, deliveryAddress:value}));
    };

    const handleAddressChange = (index, value) => {
        const updatedAddresses = [...originalOrder.customerID.addresses];
        updatedAddresses[index] = value;
        setRefundOrder((prev) => ({
            ...prev,
            deliveryAddress: value
        }));
    };

    const updateProductDetails = (refund, originalProduct, index, quantity) => {
        const validQuantity = Math.min(quantity, originalProduct.quantity);
        const perItemDiscount = originalProduct.discount / originalProduct.quantity;
        const refundAmount = validQuantity * (originalProduct.price - perItemDiscount);

        return {
            ...refund,
            products: refund.products.map((item, i) =>
                i === index
                    ? {
                        ...originalProduct,
                        quantity: validQuantity,
                        price: originalProduct.price,
                        discount: perItemDiscount * validQuantity
                    }
                    : item
            ),
            customerRefundAmount: refund.products.reduce(
                (total, item, i) =>
                    total +
                    (i === index
                        ? refundAmount
                        : item.quantity * (item.price - item.discount / item.quantity)),
                0
            )
        };
    };

    const selectProduct = (selectedProductID, index) => {
        const selectedProduct = originalOrder.products.find(prod => prod.productID._id === selectedProductID);
        const duplicateProduct = refundOrder.products.find(prod => prod.productID._id === selectedProductID);
        if (!selectedProduct || duplicateProduct) return;

        setRefundOrder(refund => updateProductDetails(refund, selectedProduct, index, 1));
    };

    const editProductQuantity = (quantity, index) => {
        const originalProduct = originalOrder.products.find(
            prod => prod.productID._id === refundOrder.products[index].productID._id
        );
        if (!originalProduct) return;

        setRefundOrder(refund => updateProductDetails(refund, originalProduct, index, quantity));
    };

    const addRefundSale = () => {
        const payload = {
            ...refundOrder,
            products: refundOrder.products.map(({ productPieces, ...rest }) => ({
              ...rest,
              productID: rest.productID._id
            }))
          };
    if (!id){

        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/addRefund`, {
        method: "POST",
        headers: {
            "Content-type": "application/json",
        },
        body: JSON.stringify(payload),
        })
        .then(async (result) => {
        if (!result.ok) {
            const err = await result.json();
            const errMessage = err.message || 'An Error Occured , Please try again'
            throw (errMessage)
        }
        alert("Order ADDED");
        window.location.reload();
        })
        .catch((err) => {
        console.log(err);
        alert(`Error: ${err}`); 
        });
    }else {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/update/refund`, {
            method: "PATCH",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(payload),
            })
            .then(async (result) => {
            if (!result.ok) {
                const err = await result.json();
                const errMessage = err.message || 'An Error Occured , Please try again'
                throw (errMessage)
            }
            alert("Order Updated");
            window.location.reload();
            })
            .catch((err) => {
            console.log(err);
            alert(`Error: ${err}`); 
            });
    }

        
    };      
    
    return (
        <div className="col-span-12 h-screen w-screen lg:h-auto lg:w-auto lg:col-span-10 py-2 flex justify-center">
            <div className="flex flex-col gap-5 w-11/12">
                <div className="rounded-lg border bg-white border-gray-200 px-5 pb-3">
                    <div className="flex justify-between pt-5 pb-3">
                        <div className="flex gap-4 justify-center items-center ">
                        <span className="font-bold text-xl">Refund Order</span>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-start pt-5 pb-3 gap-2">
                        <div className="flex items-end justify-center gap-2">
                            <InputField
                            label="Original Order ID"
                            labelClasses={"!font-semibold !text-md"}
                            type="text"
                            value={originalOrderID}
                            onChange={(e) => {setOriginalOrderID(+e.target.value)}}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                            }}
                            placeholder="Enter ID"
                            />
                            <button className="bg-[#0d99ff] text-[17px] font-semibold rounded-lg py-2 px-4 hover:brightness-[1.3]" onClick={fetchSalesData}>Get</button>
                        </div>
                    </div>
                    {originalOrder && 
                    <div className="flex flex-col gap-4">
                        <div className="border-4 border-[#757575] flex flex-col gap-3 p-4 rounded-md">
                            <span className="font-bold text-lg">Original Order</span>
                            <div className="grid grid-cols-2 gap-4 py-3 px-2">
                                <span className="text-md sm:text-lg">Status: <span className="capitalize">{originalOrder.Status}</span></span>
                                <span className="text-md sm:text-lg">
                                    Payment: <span className="capitalize">{originalOrder.paymentStatus}</span></span>
                                <span className="text-md sm:text-lg">
                                    Products: {originalOrder.products.reduce((acc, product) => acc + product.quantity * product.price - product.discount, 0)} {currentOrderCurrency || 'JOD'}
                                </span>
                                <span className="text-md sm:text-lg">Delivery: {originalOrder.deliveryAmount} {currentOrderCurrency || 'JOD'}</span>
                                <span className="text-md sm:text-lg">Customer: <span className="capitalize">{originalOrder.customerID.fullName}</span></span>
                                <span className="text-md sm:text-lg">Address: <span className="capitalize">{originalOrder.deliveryAddress}</span></span>
                            </div>
                            <table className="bg-white min-w-screen space-y-8 border-spacing-1 text-sm ml-2 overflow-x-auto">
                                <thead className="whitespace px-4 py-2 text-left font-medium text-gray-900">
                                    <tr>
                                        <th className="px-2">Items</th>
                                        <th className="px-2">Qty</th>
                                        <th className="px-2">Price</th>
                                        <th className="px-2">Discount</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className="text-left">
                                    {originalOrder.products.map((product,index)=>{
                                    return (
                                        <tr key={index}>
                                            <td className="text-md px-2 sm:text-lg">- {product.productID.name}</td>
                                            <td className="text-md px-2 sm:text-lg">x{product.quantity}</td>
                                            <td className="text-md px-2 sm:text-lg">{product.quantity*product.price} <span className="text-[10px]">{currentOrderCurrency || 'JOD'}</span></td>
                                            <td className="text-md px-2 sm:text-lg">{product.discount} <span className="text-[10px]">{currentOrderCurrency || 'JOD'}</span></td>
                                        </tr>
                                    )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <form className="border-4 border-[#757575] flex flex-col gap-3 p-4 rounded-md">
                            <span className="font-bold text-lg">Refund Details</span>
                            <div className="mr-0">
                                {/* <InputField
                                label="Refund Order ID"
                                type="text"
                                name="manualOrderId"
                                value={refundOrder.manualOrderId}
                                onChange={(e) => {
                                    handleInputChange(setRefundOrder, e.target.name, +e.target.value)
                                }}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                }}
                                classes={"!w-[200px]"}
                                placeholder="Enter ID"
                                /> */}
                            </div>
                            <div className="mr-0">
                                <InputField
                                label="Reason"
                                type="text"
                                name="refundReason"
                                value={refundOrder.refundReason}
                                onChange={(e) => {
                                handleInputChange(setRefundOrder, e.target.name, e.target.value)
                                }}
                                classes={"!max-w-[500px]"}
                                placeholder="Write here ..."
                                />
                            </div>
                            {refundOrder.products[0] &&
                            <table className="bg-white min-w-screen space-y-4 divide-y-2 border-spacing-1 text-sm ml-2 overflow-x-auto">
                                <thead className="whitespace px-4 py-2 text-left font-medium text-gray-900">
                                    <tr>
                                        <th className="px-2">Items</th>
                                        <th className="px-2">Qty</th>
                                        <th className="px-2">Price</th>
                                        <th className="px-2">Discount</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className="text-left">
                                {refundOrder.products.map((product,index)=>{
                                    return (
                                        <tr key={index}>
                                            <td className="px-2">
                                                <Dropdown 
                                                    placeholder={'Select Product'}
                                                    value={refundOrder.products[index]?.productID?._id || ""}
                                                    options={originalOrder.products.map(prod => prod.productID)}
                                                    onChange={(e)=>selectProduct(e.target.value,index)}
                                                    disabledOptions={disabledOptions(index)}
                                                />
                                            </td>
                                            <td className="px-2">
                                                <InputField
                                                    type="text"                                         
                                                    name="quantity"
                                                    value={refundOrder.products[index]?.quantity}
                                                    onChange={(e) => {
                                                        editProductQuantity(e.target.value,index)
                                                    }}
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                                    }}
                                                    classes={"!max-w-[80px] disabled:bg-gray-300"}
                                                    placeholder="Qty"
                                                    disabled={!refundOrder.products[index].productID._id}
                                                />
                                            </td>
                                            <td className="px-2 pt-3">
                                                {refundOrder.products[index]?.quantity *refundOrder.products[index]?.price}
                                            </td>
                                            <td className="px-2 pt-3">
                                               {refundOrder.products[index]?.discount}
                                            </td>
                                            <td className="px-2 pt-3 text-red-500">
                                                <MdDelete className="h-4 w-4 md:h-6 md:w-6 cursor-pointer" onClick={()=>removeProduct(product)}/>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>}
                            <div className="flex justify-center w-full mt-6">
                                <button type= "button" className="bg-[#14ae5c] rounded-lg py-1 px-2 text-sm shadow-sm hover:brightness-[1.3] disabled:bg-gray-300 disabled:cursor-not-allowed disabled:hover:brightness-100" onClick={addProduct} disabled={originalOrder.products.length === refundOrder.products.length}>
                                    + Product
                                </button>
                            </div>
                            {refundOrder.products[0]&& refundOrder.products[0].productID._id &&
                            <>  
                                <span className="text-lg text-red-500">Pay the customer: {refundOrder.customerRefundAmount} {currentOrderCurrency || 'JOD'}</span>
                                <div className="w-full bg-[#757575] h-[1px] my-4"></div>
                                <div className="w-full md:w-1/2 lg:w-1/3 flex flex-col gap-6">
                                    <div>
                                        {originalOrder.customerID.addresses.map((itm, idx) => (
                                        <AddressInput
                                        key={idx}
                                        index={idx}
                                        value={itm}
                                        selectedAddress={refundOrder.deliveryAddress}
                                        handleAddressChange={handleAddressChange}
                                        handleRadioChange={handleRadioChange}
                                        setIsNewAddress={setIsNewAddress}
                                        />
                                        ))}
                                        <AddressInput isNew = {true}
                                            value={newInsertedAddress}
                                            selectedAddress={refundOrder.deliveryAddress}
                                            handleRadioChange={handleRadioChange}
                                            onInputChange={(value) => {
                                            if (isNewAddress) {
                                                handleInputChange(setRefundOrder, 'deliveryAddress', value);
                                            }
                                            setNewInsertedAddress(value);
                                            }}
                                        />
                                    </div>
                                    <InputField label="Preferred Delivery Date"
                                        type="date"
                                        name="preferredDeliveryDate"
                                        value={refundOrder.preferredDeliveryDate}
                                        onChange={(e) => handleInputChange(setRefundOrder, 'preferredDeliveryDate', e.target.value)}
                                    />
                                    <DeliveryTimeSelection deliveryTimeMode={deliveryTimeMode}
                                        setDeliveryTimeMode={setDeliveryTimeMode}
                                        setSaleGeneralInfo={setRefundOrder}
                                        saleGeneralInfo={refundOrder}
                                        updateNestedState={handleInputChange}
                                        
                                    />
                                    <InputField
                                        label="New Delivery Amount"
                                        type="text"
                                        name="newDeliveryAmount"
                                        value={refundOrder.newDeliveryAmount}
                                        onChange={(e) => {
                                            handleInputChange(setRefundOrder, e.target.name, +e.target.value)
                                        }}
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                        }}
                                        placeholder="Enter Amount"
                                    />
                                    <div className="flex w-full items-start justify-center mt-4 flex-col">
                                    <label>Notes</label>
                                    <textarea
                                    value={refundOrder?.notes}
                                    name="notes"
                                    onChange={(e) =>handleInputChange(setRefundOrder, e.target.name, e.target.value)}
                                    className="w-full rounded"
                                    />
                                    </div>
                                </div>
                            </>
                            }
                            <div className="w-full bg-[#757575] h-[1px] my-2"></div>
                            <div className="flex items-end justify-end">  
                                <button 
                                    className="p-2 bg-[#14ae5c] rounded-lg font-semibold hover:brightness-[1.2] disabled:bg-gray-300 disabled:cursor-not-allowed disabled:brightness-100"
                                    type="button" 
                                    disabled={!refundOrder.products[0] ||!refundOrder.products[0].productID._id }
                                    onClick={addRefundSale}    
                                >
                                            Submit
                                </button>
                            </div>
                        </form>
                    </div>}
                </div>
            </div>
        </div>
    )
}