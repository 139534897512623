export const handleScan = async (qrRes) => {
    if (qrRes) {
      try {
        const url = new URL(qrRes);
        const orderId = url.pathname.split("/").pop();

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/get/${orderId}`);
        if (!response.ok) {
          throw new Error('Order not found');
        }

        window.location.href = `/salesOrderDetails/${orderId}`;
    } catch (error) {
        console.error('Error processing QR code:', error);
        alert('Invalid QR code or order not found');
      }
    }
  };