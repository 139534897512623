export const Dropdown = ({ label, value, onChange, options, placeholder, classes, name , disabledOptions, required=false}) => (
    <div className="w-full">
      <label className="block mb-2 text-sm font-medium text-gray-900">{label}{required&&<span className="text-rose-700">*</span>}</label>
      <select
        value={value}
        onChange={onChange}
        name={name? name : ""}
        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${classes}`}
        placeholder={placeholder}
        >
        <option value="" disabled>{placeholder}</option>
        {options && options.map((option) => (
          <option 
          key={option._id} 
          value={option._id}
          disabled={disabledOptions?.includes(option._id)}
          >
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );