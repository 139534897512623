import { ExpenseItem } from "./ExpenseItem";

export const ExpenseList = ({ expenses, editExpense, deleteExpense, addExpense, availableExpenses }) => (
  <div>
    {expenses.map((item, index) => (
      <ExpenseItem
        key={index}
        item={item}
        index={index}
        expensesOptions={availableExpenses}
        editExpense={editExpense}
        deleteExpense={deleteExpense}
      />
    ))}
    <button
      type="button"
      className="mt-2 text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm text-center py-2.5 px-4 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 self-end justify-self-end col-span-2"
      onClick={addExpense}
    >
      + Expense
    </button>
  </div>
);
