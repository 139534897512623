import { useEffect, useState } from "react"
import { ProductPiecesContainer } from "../containers/ProductPieces/ProductPieces";
import { ProductPieceHeader } from "../containers/ProductPieces/ProductPieceHeader";

export const ProductPiecesPage = () => {
  
  const [productPieces, setProductPieces] = useState();
  const [filters, setFilters] = useState({});
  const [searchScanModal, setSearchScanModal] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [allUsers, setAllUsers] = useState(false);
  const [page, setPage] = useState(1);
  const limit = 100;
  const fetchProductPieces = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/api/productPieces/getAll?page=${page}&limit=${limit}`;
    if (Object.keys(filters).length) {
      Object.keys(filters).forEach((key, idx) => {
         url += `&${key}=${filters[key]}`
      })
    }
    const res = await fetch(`${url}`);
    const data = await res.json()
    
    setProductPieces(data);
  }

  const fetchAllUsers = async ()=>{
    try{
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/getUsers`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
      });
      const data = await res.json();
      setAllUsers(data)
    }
    catch(error){
      console.log(error)
    }
  }
  
  const addProductPiece = async ({ product, store, purchase }) => {
    window.prompt(`Add by: \n"Scan",\n"InsertID",\n"Auto Create"`);

    /*  
        What do you have for this piece?
        1- Have the sticker ? 
        2- Have ID Only ?
        3- Don't have an id ?
    */

   };

  useEffect(() => { 
     fetchProductPieces()
    }, [filters, shouldRefetch, page]);

  useEffect(() => { 
     fetchAllUsers() 
    }, []);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setPage(1); 
  };

  console.log(productPieces);
  
  return (
    <div className="col-span-12 lg:col-span-10 overflow-x-hidden pr-4">
      <ProductPieceHeader filters={filters} setFilters={handleFilterChange} addProductPiece={addProductPiece} searchScanModal={searchScanModal} setSearchScanModal={setSearchScanModal}/>
      <ProductPiecesContainer productPieces={productPieces?.items} allUsers={allUsers} setFilters={setFilters} setShouldRefetch={setShouldRefetch} page={page} setPage={setPage} limit={limit} totalCount={productPieces?.totalCount} />
    </div>
  )

}