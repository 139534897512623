import React from 'react';
import ReactApexChart from 'react-apexcharts';

const BarChart = ({ classes, width, title, data, height }) => {

  const salesChartOptions = {
    chart: {
      type: 'bar',
      height: height ? height : 450, // Dynamic height
      toolbar: {
        show: false, // Hide the toolbar if you don't need it
      },
    },
    plotOptions: {
      bar: {
        horizontal: false, // Set to true for horizontal bars
        columnWidth: '85%', // Adjust the width of the bars (change as needed)
        borderRadius: 5, // Adjust this value for more or less rounding
      },
    },
    xaxis: {
      categories: classes ? classes : ['Category 1', 'Category 2', 'Category 3'],
    },
    title: {
      text: title,
      align: 'center',
    },
    // Enable data labels
    dataLabels: {
      enabled: true, // Enable data labels
      style: {
        colors: ['#fff'], // Text color for the data labels
      },
      formatter: function (val) {
        return val; // Return the value to display
      },
      offsetY: -10, // Adjust vertical position of labels
    },
    colors: ['#008FFB'], // Customize bar colors here
  };

  const series = [
    {
      name: 'Series 1',
      data: data ? data : [0, 0, 0],
    },
  ];

  return (
    <div style={{ width: '100%', height: height ? height : '300px' }}>
      <ReactApexChart
        options={salesChartOptions}
        series={series}
        type="bar"
        height={height ? height : "300"}
        width={width}
      />
    </div>
  );
};

export default BarChart;
