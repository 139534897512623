import React from 'react';
import { MdDelete } from 'react-icons/md';
import {Dropdown} from "../../../components/common/dropdowns/Dropdown" // Assuming these are your custom components
import {InputField} from "../../../components/common/InputFields/InputField"

export const ExpenseItem = ({
  expense,
  productIndex,
  expenseIndex,
  expenses,
  purchase,
  setPurchase
}) => {

    // Handling Input Change for expenses
    const handleExpenseChange = (productIndex, expenseIndex, key, value) => {
        const updatedProducts = [...purchase.products];
        updatedProducts[productIndex].expenses[expenseIndex][key] = value;
        setPurchase({ ...purchase, products: updatedProducts });
    };
    
    // Delete an expense
    const deleteExpense = (productIndex, expenseIndex) => {
        const updatedProducts = [...purchase.products];
        updatedProducts[productIndex].expenses.splice(expenseIndex, 1);
        setPurchase({ ...purchase, products: updatedProducts });
    };
  return (
    <div className="grid gap-4 mb-4 sm:grid-cols-12 items-center">
      <div className="col-span-5">
        <Dropdown
          label="Expense Type"
          value={expense.expenseID}
          onChange={(e) => handleExpenseChange(productIndex, expenseIndex, 'expenseID', e.target.value)}
          options={expenses}
          placeholder="Select Expense"
        />
      </div>
      <div className="col-span-5">
        <InputField
          label="Cost"
          type="number"
          name="cost"
          value={expense.cost}
          onChange={(e) => handleExpenseChange(productIndex, expenseIndex, 'cost', Number(e.target.value))}
          placeholder="JOD, EGP, etc..."
          min={0}
        />
      </div>
      <span className="self-end cursor-pointer inline-flex items-center justify-self-end col-span-2">
        <MdDelete onClick={() => deleteExpense(productIndex, expenseIndex)} color="red" size={30} />
      </span>
    </div>
  );
};

export default ExpenseItem;
