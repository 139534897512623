import { useEffect, useState } from "react";

export const StoreSelector = ({ selectedStore,  handleStoreChange}) => {
  
  const [stores, setStores] = useState();

  const fetchStores =async  () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/store/get`);
      const storesRes = await res.json();
      setStores(storesRes);
      if(storesRes?.length>0 && !selectedStore){
        handleStoreChange(storesRes[0]._id)
      }
    } catch (e) {
      console.error(e);
      alert('Something went wrong fetching stores, please contact the tech team!');
    }
  }

  useEffect(() => {
    fetchStores();
    
  }, []);
  
  return (
    <div className="bg-white rounded-xl border-2px border-gray-200 p-4 w-full">
      <div className="flex flex-wrap items-center justify-start w-full">
        <span className="text-black px-2 py-1">
        Warehouse:
        </span>
        {stores?.map((itm,idx) => (
          <div
          key={idx}
            onClick={() => {handleStoreChange(itm._id)}}
            className={`${selectedStore === itm._id ? "bg-blue-500 text-white" : "text-black cursor-pointer hover:bg-blue-200 transition-all underline"} px-2 py-1 ml-4 rounded-xl`}>{itm.name}</div>
        ))}
      </div>
    </div>
  )
}