import { useEffect, useState } from "react";
import { PasswordLayer } from "../components/PasswordLayer";
import { useLocation } from "react-router-dom";

export const PasswordProtectedWrapper = ({ children, path }) => {
  const [unlocked, setUnlocked] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname.replace('/', '');

  useEffect(()=>{setUnlocked(false)},[currentPath])
  if (!unlocked && !process.env.REACT_APP_DEVELOPMENT_ENVIRONMENT) return <PasswordLayer path={path} setUnlocked={setUnlocked} />
  
  else return (<>
      { children }
  </>
  )
}
