import dayjs from "dayjs"
import { MdOutlineDelete } from "react-icons/md"
import { useState } from "react"
import CustomModal from "../../components/common/Modal"
import { Dropdown } from "../../components/common/dropdowns/Dropdown"

export const ProductPiecesContainer = ({ productPieces, allUsers, setShouldRefetch, page, setPage, limit, totalCount}) => {
  const [radioInput, setRadioInput] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [itemID, setItemID] = useState()
  const [issuanceTo, setIssuanceTo] = useState({firstName:""})
  const [issuanceModal, setIssuanceModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const productPiecesStatuses = {
    "available": "Available",
    "fulfilled":"Fulfilled"
  }
  const formattedUsers = allUsers && allUsers.map(user => ({
    ...user,
    name: `${user.firstName} ${user.lastName}`
  }));
  
  const totalPages = Math.ceil(totalCount / limit);

  const handleNextPage = () => {
    if (page < totalPages) setPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (page > 1) setPage((prev) => prev - 1);
  };
  
  // Handle Damaged and Repair Status Update
  const handleDamagedAndRepair = async (id) => {
    try {
      const { status } = radioInput;
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/productPieces/updateStatusToDamaged/${itemID? itemID : id}`, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ newStatus: status ? status : "available" })
      });
      const data = await res.json();
      setModalOpen(false)
      setShouldRefetch(prev=>!prev)
      setItemID("")
      setRadioInput("")
      if (!res.ok) {
        alert(data.message);
        return;
      }
      // Optional: trigger refetch or update state to reflect changes
    } catch (e) {
      console.error(e);
      alert("Something went wrong, contact the technical team.");
    }
    setRadioInput({ id: "", status: "" }); // Reset radioInput state
  };

  // Handle Changing Status Directly
  const handleChangeStatus = async (id, status) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/productPieces/updateStatus/${id}`, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ newStatus: status })
      });
      const data = await res.json();
      setShouldRefetch(prev=>!prev)
      if (!res.ok) {
        alert(data.message);
        return;
      }
      // Optional: trigger refetch or update state to reflect changes
    } catch (e) {
      console.error(e);
      alert("Something went wrong, contact the technical team.");
    }
  };
  const handleOpenModalAndAssignItemId = (id) => {
    setItemID(id)
    setModalOpen(!modalOpen);
  };
  const handleIssuance = async() =>{
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/productPieces/updateToIssuance/${itemID}`, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ newStatus: "issuance", issuedTo: issuanceTo, issuanceDate:new Date()})
      });
      const data = await res.json();
      setShouldRefetch(prev=>!prev)
      setIssuanceModal(!issuanceModal)
      if (!res.ok) {
        alert(data.message);
        return;
      }
    } catch (e) {
      console.error(e);
      alert("Something went wrong, contact the technical team.");
    }
  }
  const handleIssuaneUsers= (e) =>{
    setIssuanceTo(e.target.value)
  }
  const handleOpenIssuanceModal = (id) =>{
    setIssuanceModal(!issuanceModal)
    setItemID(id)
  }
  const handleOpenDeleteModal = (id) =>{
    setItemID(id)
    setDeleteModal(!deleteModal)
  }
  const deleteProductPiece = async () =>{
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/productPieces/delete/${itemID}`, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
        },
      });
      const data = await res.json();
      setItemID("")
      setDeleteModal(!deleteModal)
      setShouldRefetch(prev=>!prev)
      if (!res.ok) {
        alert(data.message);
        return;
      }
    } catch (e) {
      console.error(e);
      alert("Something went wrong, contact the technical team.");
    }
  }
  const handleGenerateQr = async (productPieceID) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/productPieces/generateOneQr/${productPieceID}`);
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'productPiecesQRCodes.zip'; // Set the filename for the download
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      alert("Error generating QRs, Please contact the technical team")
    }
  }
  return (
    <div className="w-full flex-col items-center justify-center">
      <CustomModal modalOpen={modalOpen} setModalOpen={setModalOpen} title={"Choose status"}>
        <div>
        <div className="flex justify-start items-center mb-2">
          <input 
            type="radio" 
            value="damaged" 
            checked={radioInput.status === "damaged"} 
            name="condition" 
            id="damaged" 
            className="mr-3" 
            onChange={() => setRadioInput({ status: "damaged" })} 
          />
          <label htmlFor="damaged">Damaged</label>
        </div>
        <div className="flex justify-start items-center mb-2">
          <input 
            type="radio" 
            value="needsRepair" 
            checked={radioInput.status === "needsRepair"} 
            name="condition" 
            id="needsRepair" 
            className="mr-3" 
            onChange={() => setRadioInput({ status: "needsRepair" })} 
          />
          <label htmlFor="needsRepair">Needs Repair</label>
        </div>
        <button 
          className="flex flex-end bg-green-500 text-white p-2 rounded" 
          onClick={handleDamagedAndRepair}
        >
          Submit
        </button>
        </div>
      </CustomModal>
      <CustomModal modalOpen={issuanceModal} setModalOpen={setIssuanceModal} title={"Choose User"}>
        <div>
        <Dropdown label={"Users"} value={issuanceTo} placeholder={"Choose User"} onChange={handleIssuaneUsers} options={formattedUsers} name="issuanceUsers"/>
        <br/>
        <button 
          className="flex flex-end bg-green-500 text-white p-2 rounded" 
          onClick={handleIssuance}
        >
          Submit
        </button>
        </div>
      </CustomModal>
      <CustomModal modalOpen={deleteModal} setModalOpen={setDeleteModal} title={"Warning Popup !"}>
        <div>
        <h2>Are you sure you want to delete it for ever !</h2>
        <br/>
        <button 
          className="flex flex-end bg-red-500 text-white p-2 rounded" 
          onClick={deleteProductPiece}
        >
          Delete
        </button>
        </div>
      </CustomModal>
      <b>Total: {productPieces?.length}</b>
      <div className="overflow-x-auto">
        <table className="w-full bg-white min-w-[1362px] mt-4 shadow-lg p-6">
          <thead >
            <tr className="border-b-2 border-black">
              <td className="font-bold">Product</td>
              <td className="font-bold">Piece ID</td>
              <td className="font-bold">Order ID</td>
              <td className="font-bold">Pay.Status</td>
              <td className="font-bold">Status</td>
              <td className="font-bold">Store</td>
              <td className="font-bold">Purch.Date</td>
              <td className="font-bold">Statuses</td>
              <td className="font-bold">Actions</td>
            </tr>
          </thead>
          <tbody>
            {productPieces?.length > 0 ?
              productPieces.map((item, idx) => (
              <tr className="border-b-2 border-gray-500">
                  <td>{item.productID?.name}</td>
                  <td>{item._id }</td>
                  <td>{item.orderId ? item.orderId : 'none' }</td>
                  <td>{item.paymentStatus }</td>
                  <td>
                  {(item.status === "available" || item.status === "fulfilled") && (
                    <select
                      value={item.status}  // Use value for controlled component
                      onChange={(e) => handleChangeStatus(item._id, e.target.value)} // Pass the new status to handleChangeStatus
                    >
                      {Object.keys(productPiecesStatuses).map((statusKey) => (
                        <option key={statusKey} value={statusKey}>
                          {productPiecesStatuses[statusKey]}
                        </option>
                      ))}
                    </select>
                  )}
                    {!(item.status === "available" || item.status === "fulfilled") && <span className="flex items-center"><p><b>{item.status}</b></p></span>}
                  </td>
                  <td>{item.storeID?.name }</td>
                  <td>{dayjs(item.purchaseID.created_at).format('DD-MM-YYYY')}</td>
                  <td className="flex items-center justify-start">
                    {(item.status === "damaged" || item.status === "needsRepair" || item.status === "issuance")&& <button
                      onClick={()=>handleDamagedAndRepair(item._id)}
                      className="w-4/5 bg-green-500 px-2 py-2 rounded mr-2 text-white">Mark as Available</button>}
                    {item.status === "available" && <button
                      onClick={()=>handleOpenModalAndAssignItemId(item._id)}
                      disabled={item.status === "available" ? false : true}
                      className="bg-blue-500 px-2 py-2 rounded mr-2 text-white">Damaged/Repair</button>}
                    {item.status === "available" && <button
                      onClick={()=>handleOpenIssuanceModal(item._id, 'issuance')}
                      disabled={item.status === "available" ? false : true}
                      className="bg-blue-600 px-2 py-2 rounzded mr-2 text-white">Issuance</button>}
                  </td>
                  <td className=" items-center justify-start">
                    <button
                      onClick={()=>handleGenerateQr(item._id)}
                      className="bg-blue-500 px-2 py-2 rounded mr-2 text-white">Gen QR</button>
                    <button
                      onClick={()=>handleOpenDeleteModal(item._id)}
                      className="bg-red-500 px-3 py-3 rounded"><MdOutlineDelete color="white" /></button>
                  </td>
            </tr>
            )) : 
          (<tr>
            <td colSpan="6" className="text-center py-4">
              No results found
            </td>
          </tr>) }
          </tbody>
        </table>
      </div>
      <div className=" sticky bottom-0 flex justify-end items-center mt-2 space-x-4">
        <button
          onClick={handlePreviousPage}
          disabled={page === 1}
          className="bg-white shadow-md text-gray-800 font-bold py-1 px-3 rounded-md disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-white hover:bg-gray-100"
        >
          {"<"}
        </button>
        <span className="text-gray-700">
          Page {totalPages > 0 ? page : 0} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={page === totalPages}
          className="bg-white shadow-md text-gray-800 font-bold py-1 px-3 rounded-md disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-white hover:bg-gray-100"
        >
          {">"}
        </button>
      </div>
    </div>
  )
}