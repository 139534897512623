import { useContext, useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom";
import { SalesOrderItemsTable } from "../containers/sales/components/SaleOrderItemsTable";
import { PickItemModal } from "../containers/sales/components/PickItemModal";
import { ConfirmPickingModal } from "../containers/sales/components/ConfirmPickingModal";
import { IoMdArrowBack } from "react-icons/io";
import { currencyMapper } from "../utils/currency";
import CustomModal from "../../src/components/common/Modal";
import AuthContext from "../AuthContext";
export const SalesOrderDetails = () => {
  const dateOptions = {
    weekday: 'short', // long, short, narrow
    year: 'numeric', // numeric, 2-digit
    month: 'short', // numeric, 2-digit, long, short, narrow
    day: 'numeric', // numeric, 2-digit
  };
  const navigate = useNavigate();
  const authContext = useContext(AuthContext)
  const [isDone, setIsDone] = useState(false);
  const { id } = useParams();
  const [order, setOrder] = useState();
  const [item, setItem] = useState("");
  const [scannerOpen, setScannerOpen] = useState(false);
  const [scannedItems, setScannedItems] = useState([]);
  const [confirmPickingModal, setConfirmPickingModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false)
  const [cancellationNotes, setCancellationNotes] = useState()
  const fetchSalesOrderDetails = async () => {
    try {
      const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/getPopulated/${id}`);
      const salesOrder = await data.json();
      setOrder(salesOrder);
      const allPiecesIDs = [];
      for (const prod of salesOrder.products) {
        prod.productPieces.forEach(itm => allPiecesIDs.push(itm.productPieceID));
      }
      setScannedItems(allPiecesIDs);
    } catch (e) {
      alert("Something went wrong! Please contact the technical team")
    }
  }

  const formatDeliveryTime = (deliveryDate) => {
    if (deliveryDate.anytime) {
      return "Anytime";
    } else if (deliveryDate.time) {
      return new Date(`1970-01-01T${deliveryDate.time}:00`).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });
    } else if (deliveryDate.timeRange) {
      const fromTime = new Date(`1970-01-01T${deliveryDate.timeRange.from}:00`).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });
      const toTime = new Date(`1970-01-01T${deliveryDate.timeRange.to}:00`).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });
      return `${fromTime} - ${toTime}`;
    }
    return "N/A";
  }
  
  useEffect(() => {
    if (order) { 
      const totalRequestedQty = order.products.reduce((sum, itm) => sum + itm.quantity, 0);
      if (scannedItems.length === totalRequestedQty) setIsDone(true);
      else if (isDone) setIsDone(false);
    }
  },[scannedItems, isDone, order])

  useEffect(()=>{
    if (!openCancelModal){
      setCancellationNotes('')
    }
  },[openCancelModal])

  useEffect(() => {
    fetchSalesOrderDetails();
  }, [])
  
  if (!order) return (
    <div className="col-span-12 lg:col-span-10 flex justify-center items-center">
      <h2>Loading...</h2>
    </div>
  )
  const cancelOrder = async()=>{
    if (!cancellationNotes) return alert('Cancellation Notes cannot be empty')
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/cancelPendingOrders`, {
        method: "PATCH",
        body:JSON.stringify({orderID : item, newUserID:authContext.user, cancellationNotes}),
        headers: {
          "Content-Type":"application/json"
        }
      });
      setOpenCancelModal(false)
      const result = res.json()
      if (!res.ok) throw (result.message || 'An Error Occurred');
      alert(`Order cancelled successfuly`);
      if (res.status === 400) alert(result.message);
      navigate(0); // This reloads the current page
    } catch (e) {
      console.log(e);
      alert(e);
    }
  }
  const handleOpenCancelModal = (id) =>{
    setOpenCancelModal(true);
    setItem(id)
  }
  
  const handleExitModal = () =>{
    setOpenCancelModal(false)
  }

  const currentOrderCurrency = currencyMapper[order?.StoreID.country]
  
  
  return (
    <div className="col-span-12 lg:col-span-10 px-2">
      {openCancelModal && <CustomModal modalOpen={openCancelModal} mdWidth={400} lgWidth={500} setModalOpen={setOpenCancelModal} title={'Confirm Cancel Orders'}>
        <div className="flex flex-col items-center justify-center gap-4">
          <p className="text-lg font-semibold">Are you Sure you want to Cancel order ?</p>
          <div className="flex flex-col items-center gap-2 w-full">
            <label htmlFor="cancellationNotes" className="font-semibold">Cancellation Notes<span className="text-red-700">*</span></label>
            <textarea
              value={cancellationNotes}
              onChange={(e)=>setCancellationNotes(e.target.value)}
              className="w-full rounded"
              name="cancellationNotes"
              />
          </div>
          <div className="flex flex-row items-center justify-center gap-4">
            <button onClick={cancelOrder} className="bg-green-500 border-green-500 border-2 rounded text-white p-2 hover:bg-white hover:text-green-500">
              YES
            </button>
            <button onClick={handleExitModal} className="bg-red-500 border-red-500 border-2 rounded text-white p-2 hover:bg-white hover:text-red-500">
              NO
            </button>
          </div>
        </div>
      </CustomModal>}
      <div className="flex flex-col w-full">
        <div className="flex justify-between items-center py-2 w-full">
          <h2 className="font-bold inline-flex items-center">
          
              <IoMdArrowBack className="cursor-pointer" size={20} onClick={()=>navigate(-1)} />
            <span className="ml-4">
              Order ID : {order.manualOrderId} 
            </span>
          </h2>
          <div className="flex items-center justify-start">
              {order.Status === "pending" && <button disabled={!isDone} className={`${isDone ? "bg-green-500 text-white" : "bg-gray-300 cursor-not-allowed"} rounded px-2 py-1`} onClick={() => setConfirmPickingModal(true)}>Confirm Picking</button>}
            {order.Status === "readyForDelivery" && <button className={`bg-red-500 text-white rounded px-2 py-1 cursor-not-allowed`}>Make Pending(Soon)</button>}
              <button
                onClick={()=>setScannerOpen(true)}
                className={`${!isDone ? "bg-blue-500 text-white" : "bg-gray-300 cursor-not-allowed"} rounded px-2 py-1 ml-3`}>Scan</button>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-full">
          <div>
            <p><b>Customer: </b>{order.customerID.fullName}</p>
            <p><b>Phone: </b>{order.customerID.phone}</p>
            <p><b>Address: </b>{order.deliveryAddress}</p>
            <p><b>Source: </b>{order.sourceId?.name}</p>
          </div>
          <div>
            <p><b>Delivery: </b>{order.deliveryAmount} {currentOrderCurrency || "JOD"}</p>
            <p><b>Delivery Date: </b>{new Date(order.preferredDeliveryDate).toLocaleDateString('en-US', dateOptions)}</p>
            <p><b>Cash To Collect From Client: </b>{order.customerCashCollected} {currentOrderCurrency || "JOD"}</p>
            <p><b>Created By: </b>{order.userID.firstName + " " + order.userID.lastName}</p>
          </div>
          <div className="flex flex-col">
            <div className="relative">
              <p className="whitespace-normal break-words max-h-20 overflow-hidden">
                <b>Notes: </b>
                {order.notes ? (
                  <>
                    {order.notes.length > 100 ? (
                      <>
                        {order.notes.slice(0, 100)}...
                        <span className="text-blue-500 cursor-pointer" onClick={() => {/* Add toggle logic here */}}>
                          Read More
                        </span>
                      </>
                    ) : (
                      order.notes
                    )}
                  </>
                ) : "No Notes"}
              </p>
            </div>
            
            {order.Status === 'cancelled' && (
              <div className="relative mt-2">
                <p className="whitespace-normal break-words max-h-20 overflow-hidden">
                  <b>Cancellation Notes: </b>
                  {order.cancellationReason ? (
                    <span className="text-red-500 font-semibold">{order.cancellationReason}</span>
                  ) : "No Cancellation Notes"}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-end col-span-3"> {/* Ensure this div spans all columns */}
          {order.Status === "pending" && (
            <button 
              onClick={() => handleOpenCancelModal(order._id)} 
              className="bg-red-500 border-red-500 border-2 rounded mt-2 text-white px-4 py-2 hover:bg-white hover:text-red-500 transition duration-300 max-w-[150px] focus:outline-none focus:ring-2 focus:ring-red-500"
            >
              Cancel
            </button>
          )}
        </div>
        <div className="mt-3 bg-white">
          <SalesOrderItemsTable order={order} scanned={scannedItems} setScanned={setScannedItems} /> 
        </div>
      </div>
      {scannerOpen && <PickItemModal modalOpen={scannerOpen} products={order.products} scannedItems={scannedItems} setModalOpen={setScannerOpen} setScannedItems={setScannedItems} />}
      {confirmPickingModal && <ConfirmPickingModal modalOpen={confirmPickingModal} setModalOpen={setConfirmPickingModal} orderID={id} scanned={scannedItems} storeID={order.StoreID._id}/>}
    </div>
  )
}