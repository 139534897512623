import { Fragment, useRef, useState, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import UploadImage from "../../components/common/UploadImage";
import AuthContext from "../../AuthContext";
import { InputField } from "../../components/common/InputFields/InputField";
import { InputTextArea } from "../../components/common/InputFields/InputTextArea";
import { Dropdown } from "../../components/common/dropdowns/Dropdown";

export default function AddStore() {
  const authContext = useContext(AuthContext);
  const [form, setForm] = useState({
    userId: authContext.user,
    name: "",
    category: "",
    address: "",
    country: "",
    city: "",
    image: "",
  });
  const options = [
    { _id: "JOR", name: "Jordan" },
    { _id: "EGY", name: "Egypt" },
    { _id: "IRQ", name: "Iraq" },
    { _id: "PAL", name: "Palestine" },
  ]
  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);

  const addStore = async () => {
    const missingFields = [];
  
    if (!form.name) missingFields.push("Name");
    if (!form.city) missingFields.push("City");
    if (!form.country) missingFields.push("Country");
    if (!form.address) missingFields.push("Address");
  
    if (missingFields.length > 0) {
      alert(`The following fields are required and cannot be empty:\n-${missingFields.join("-\n-")}-`);
      return;
    }

    try{
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/store/add`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(form),
      })
      const data = await res.json()
      console.log(data);
      
      if (!res.ok) throw data
      
        alert("STORE ADDED");
        setOpen(false);
  
    }catch(err) {
      console.log(err)
      alert(err.message)
    };
  };

  const uploadImage = async (image) => {
    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", "inventoryapp");

    await fetch("https://api.cloudinary.com/v1_1/ddhayhptm/image/upload", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        setForm({ ...form, image: data.url });
        alert("Store Image Successfully Uploaded");
      })
      .catch((error) => console.log(error));
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <PlusIcon
                        className="h-6 w-6 text-blue-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left ">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-semibold leading-6 text-gray-900 "
                      > Store Information
                      </Dialog.Title>
                      <form action="#">
                        <div className="grid gap-4 mb-4 sm:grid-cols-2">
                        <InputField
                          label="Name"
                          type="text"
                          name="name"
                          value={form.name}
                          onChange={handleInputChange}
                          placeholder="Enter Store Name"
                          classes=""
                        />
                        <InputField
                          label="City"
                          type="text"
                          name="city"
                          value={form.city}
                          onChange={handleInputChange}
                          placeholder="Enter City Name"
                          classes=""
                        />
                          <div>
                          <Dropdown
                            label="Country"
                            value={form.country}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                country: e.target.value,
                              })
                            }
                            name="country"
                            options={options}
                            placeholder="Select a country"
                            classes=""
                          />
                          </div>
                          <div className="sm:col-span-2">
                            <InputTextArea
                              label="Address"
                              name="address"
                              value={form.address}
                              onChange={handleInputChange}
                              placeholder="Write an address..."
                              rows="5"
                              classes=""
                            />
                          </div>
                        </div>
                        <div className="flex items-center space-x-4">
                          <div>
                            <UploadImage uploadImage={uploadImage} />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                    onClick={addStore}
                  >
                    Add Store
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}