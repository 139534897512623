import Select from 'react-select';

export const SearchableDropdown = ({ label, value, onChange, options, placeholder, classes, name, disabledOptions, required = false }) => {
  const formattedOptions = options.map(option => ({
    value: option._id,
    label: option.name,
    isDisabled: disabledOptions?.includes(option._id) 
  }));

  const handleChange = (selectedOption) => {
    onChange(selectedOption ? selectedOption.value : ""); 
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#f9fafb',
      minHeight: '41px',  
      minWidth:'230px',
      fontSize:'14px',
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: '14px', 
      color: 'gray',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'black',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, 
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#fff' : '#000', 
      fontSize: '14px',
   
    }),
  };
  
  return (
    <div className="w-full">
      <label className="block mb-2 text-sm font-medium text-gray-900">
        {label}{required && <span className="text-rose-700 bg-[#dff8fa] ">*</span>}
      </label>
      <Select
        value={formattedOptions.find(opt => opt.value === value) || null}
        onChange={handleChange}
        options={formattedOptions}
        noOptionsMessage={() => "No Products Found"}
        placeholder={placeholder}
        className={`${classes}`}
        styles={customStyles}
        name={name}
        />
    </div>
  );
};