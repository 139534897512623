import { Dropdown } from "../../../components/common/dropdowns/Dropdown";

const StorePicker = ({ from, setFrom, to, setTo, stores }) => {
  const handleFrom = (e) => {
    const selectedStore = stores.find(d => d._id === e.target.value);
    setFrom(selectedStore);
    if (selectedStore._id === to._id) {
      setTo('');
    }
  };

  const handleTo = (e) => {
    const selectedStore = stores.find(d => d._id === e.target.value);
    setTo(selectedStore);
  };

  return (
    <div className="flex flex-col sm:flex-row gap-3 items-start justify-start w-full">
      <div className="min-w-[300px]">
        <Dropdown label="From" value={from._id || ''} onChange={handleFrom} placeholder="Select Store" options={stores} disabledOptions={stores?.filter(f => f._id === from._id)} />
      </div>
      <div className="min-w-[300px]">
        <Dropdown label="To" value={to?._id || ''} onChange={handleTo} placeholder="Select Store" options={stores?.filter(f => f._id !== from._id)} disabledOptions={stores?.filter(f => f._id === to._id)} />
      </div>
    </div>
  );
};

export default StorePicker;
