import { useState } from "react";
import CustomModal from "../../../components/common/Modal"


export const UpdatePaymentStatusModal = ({
  modalOpen,
  setModalOpen,
  ordrID,
  setShouldRefetch,
  selectedOrders
}) => {
  let currentPaymentStatus = Array.from(new Set(selectedOrders.map(order=>order.paymentStatus)));
  console.log(currentPaymentStatus);
  
  const eligblePaymentStatuses = {
    cashCollected: "Cash Collected",
    pendingPaymentConfirmation: "Pending Payment Confirmation",
    uncollected:"Uncollected"
  }

  const [data, setData] = useState({
    paymentStatus:currentPaymentStatus.length > 1 ? "" : currentPaymentStatus[0],
  });

  const markAsOutForDelivery = async (e) => {
    e.preventDefault();
    const dataClone = JSON.parse(JSON.stringify(data));

    if (!dataClone.paymentStatus) return alert("Please select the order status");

    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/updatePaymentStatus`, {
        method: "PATCH",
        body:JSON.stringify({...dataClone, orderIDs:ordrID}),
        headers: {
          "Content-Type":"application/json"
        }
      });
      const result = res.json()
      if (res.status === 400) alert(result.message);
      if (res.ok) {
        alert(`Order is ${eligblePaymentStatuses[dataClone.paymentStatus]}`);
        setShouldRefetch((prev) => !prev);
        setModalOpen(false)
      }
    } catch (e) {
      alert("Something went wrong!");
    }
  }
  
  return (
    <CustomModal modalOpen={modalOpen} setModalOpen={setModalOpen} title={"Update Status"} lgWidth={650} mdWidth={500}>
      <div className="flex flex-col items-start justify-center">
        <h2 className="text-xl mb-4">Status</h2>
        <form onSubmit={markAsOutForDelivery} className="flex flex-col w-full items-start justify-center">
          <div className="flex flex-col justify-center items-start">
            {Object.keys(eligblePaymentStatuses).map(itm => {
              return (
                <div 
                  className={`flex justify-start items-center mb-2 ${currentPaymentStatus.length ===1 && currentPaymentStatus[0] === itm ? 'opacity-50 cursor-not-allowed' : ''}`} 
                  onClick={() => currentPaymentStatus.length === 1 && currentPaymentStatus[0] !== itm && setData((prev) => ({ ...data, paymentStatus: itm }))}
                >
                  <input
                    type="radio"
                    value={itm}
                    defaultChecked={data.paymentStatus === itm}
                    name="paymentStatus"
                    id={itm}
                    className="mr-3"
                    disabled={currentPaymentStatus.length ===1 && currentPaymentStatus[0] === itm}
                  />
                  <label htmlFor={itm}>{eligblePaymentStatuses[itm]}</label>
                </div>
            ) })}
          </div>
        <div className="w-full flex justify-between items-center mt-4">
          <button className="rounded-lg bg-red-500 hover:bg-red-300 px-6 py-1 text-white" onClick={()=>setModalOpen(false)}>
            Cancel
          </button>
          <button className="rounded-lg bg-green-500 hover:bg-green-300 px-6 py-1 text-white" type="submit">
            Submit
          </button>
        </div>
        </form>
      </div>

    </CustomModal>
  )
}