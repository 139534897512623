export const InputTextArea = ({ label, type, name, value, onChange, placeholder, disabled, min, classes, id, rows }) => (
    <div className="w-full">
      <label className="block mb-2 text-sm font-medium text-gray-900">{label}</label>
      <textarea
        id={id? id : name}
        type={type}
        rows={rows}
        name={name}
        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${classes}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
      </textarea>
    </div>
  );
  