import { Fragment, useContext, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import AuthContext from "../../../AuthContext";
import UploadImage from "../../../components/common/UploadImage";
import { InputField } from "../../../components/common/InputFields/InputField";
import { InputTextArea } from "../../../components/common/InputFields/InputTextArea";
import { Dropdown } from "../../../components/common/dropdowns/Dropdown";
import SellingPriceList from "./SellingPriceList";

export default function AddProduct({
  addProductModalSetting,
  handlePageUpdate,
  sales,
  singleProduct
}) {
  const authContext = useContext(AuthContext);
  const [product, setProduct] = useState(singleProduct ?{...singleProduct} : {
    userId: authContext.user,
    name: "",
    project: "",
    description: "",
    image:"",
    imageName:"",
    sellingPrices:[{sourceId:'', price:0}],
  });
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const cancelButtonRef = useRef(null);
  const projects = [{name:'Giving Movement' , _id :'Giving Movement'}, {name:'Orange Cow', _id:'Orange Cow'}]
  const maxImageSize = 4 * 1024 * 1024
  const handleInputChange = (e) => {
    if (!e || !e.target) {
      console.error('Event or target is undefined');
      return;
    }
    const { name, value } = e.target;
    setProduct(prev => ({
      ...prev,
      [name]: value
    }));
  };
  const [productImage, setProductImage] = useState(null);
  const handleImageUpload = (file, imgName) => {
    setProduct(prev => ({
      ...prev,
      image: file,
      imageName: imgName
    }));
    // For example, store the image in state
    // setProductImage(file);
  };

  const addProduct = async () => {
    
    setLoading (true)
    let s3ImageUrl = '';
    if (product.image && product.imageName) {
      try {
          // Step 1: Get presigned URL
          const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/common/getPresignedUrl/`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  fileName: product.imageName,
                  fileType: product.image.type,
              }),
          });

          if (!res.ok) throw new Error("Failed to get presigned URL");
          
          const { url } = await res.json();
          
          // Step 2: Upload image to S3
          const uploadRes = await fetch(url, {
              method: 'PUT',
              headers: {
                  'Content-Type': product.image.type,
              },
              body: product.image,
          });

          if (!uploadRes.ok) throw new Error("Failed to upload image to S3");

          // Step 3: Set the S3 URL in the product object
            s3ImageUrl = url.split('?')[0]; 
          setProduct((prev) => ({
              ...prev,
              image: s3ImageUrl, 
          }));
      } catch (error) {
          console.error('Error uploading image:', error);
          setLoading(false)
          return alert(error.message || "An error occurred while uploading the image.");
      }
    }

    // Step 4: Add or update product
    const apiEndpoint = singleProduct 
        ? `${process.env.REACT_APP_BACKEND_URL}/api/product/update/${product._id}` 
        : `${process.env.REACT_APP_BACKEND_URL}/api/product/add`;
    const payload = {...product, image:s3ImageUrl || product.image}
    
    // Clean up the payload object
    if (payload.description === "") delete payload.description;
    if (payload.image === "") delete payload.image;
    if (payload.imageName === "") delete payload.imageName;
    try {
        const res = await fetch(apiEndpoint, {
            method: singleProduct ? "PATCH" : "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(payload),
        });
        
        const response = await res.json();
        if (!res.ok) throw response.message || 'An error occurred'; 
        alert(singleProduct ? 'Product updated successfully' : 'Product added successfully');
    } catch (error) { 
        console.log(error);
        setLoading(false)
        return alert(error);
    }

    // Final steps
    handlePageUpdate();
    addProductModalSetting();
    setLoading(false)
};


  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission, including the uploaded image
    console.log("Form submitted with image:", productImage);

    // You can now upload the productImage to your server or further process it
  };
  return (
    // Modal
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <PlusIcon
                        className="h-6 w-6 text-blue-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left ">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-semibold leading-6 text-gray-900 "
                      >
                        Add Product
                      </Dialog.Title>
                      <form onSubmit={handleSubmit}>
                        <div className="grid gap-4 mb-4 sm:grid-cols-2">
                          <div>
                            <InputField
                              label="Name"
                              type="text"
                              name="name"
                              id="name"
                              value={product.name}
                              onChange={handleInputChange}
                              placeholder="Ex. Apple iMac 27&ldquo;"
                            />
                          </div>
                          <div>
                            <Dropdown
                              label="Project"
                              value={product.project}
                              onChange={handleInputChange}
                              options={projects}
                              placeholder="Select Project"
                              name={"project"}
                            />
                          </div>
                          <div className="col-span-2 mt-4">
                          <SellingPriceList
                            sales={sales}
                            product={product}
                            setProduct={setProduct}
                          />
                          </div>
                          <div className="sm:col-span-2">
                            <InputTextArea
                                label="Description"
                                type="textarea"
                                rows="5"
                                name="description"
                                id="description"
                                value={product.description}
                                onChange={handleInputChange}
                                placeholder="Write a description..."
                                classes={'min-h-[80px]'}
                            />
                          </div>
                        </div>
                        <UploadImage onImageUpload={handleImageUpload} initialImageURL={product.image} maxImageSize = {maxImageSize} />
                        <div className="flex items-center space-x-4">
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto disabled:bg-gray-200 disabled:cursor-not-allowed"
                    onClick={addProduct}
                    disabled={loading}
                  >
                    {loading? "Please wait..." : singleProduct ? 'Update Product': 'Add Product'}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => addProductModalSetting()}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}