import { useEffect, useState } from "react";
import { PendingOrdersTable } from "../../containers/pendingOrders/components/pendingOrdersTable";
import { ReadyForDeliveryTable } from "./components/readyForDeliveryTable";
import { OutForDeliveryTable } from "./components/OutForDeliveryTable";
import { FulfilledTable } from "./components/FulfilledTable";
import { CancelledOrdersTable } from "./components/CancelledOrdersTable";
import { StoreSelector } from "./components/StoreSelector";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

export const PendingOrders = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const statusMapper = {
    pending: "PENDING",
    readyForDelivery: "READY FOR DELIVERY",
    outForDelivery: "Out For Delivery",
    fulfilled: "Fulfilled",
    cancelled: "Cancelled",
  };

  const query = new URLSearchParams(location.search);
  const initialView = Object.keys(statusMapper).includes(query.get("view"))
    ? query.get("view")
    : "pending";

  const [currentView, setCurrentView] = useState(initialView);
  const [ordersData, setOrdersData] = useState({});
  const [selectedStore, setSelectedStore] = useState(query.get("store") || null);
  const fetchOrdersData = async (view, filters = {}) => {
    try {
      const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/api/sales/get`);
      url.searchParams.append("StoreID", selectedStore);
      url.searchParams.append("Status", view || currentView);
      if (filters.page) url.searchParams.append("page", filters.page);
      if (filters.limit) url.searchParams.append("limit", filters.limit);
      if (filters.date) url.searchParams.append("date", filters.date);
      if (filters.orderType) url.searchParams.append("orderType", filters.orderType);
      if (filters.driver) url.searchParams.append("driver", filters.driver);
      if (filters.paymentStatus) url.searchParams.append("paymentStatus", filters.paymentStatus);
      if (filters.manualOrderId) url.searchParams.append("manualOrderId", filters.manualOrderId);
      if (filters.fullName) url.searchParams.append("fullName", filters.fullName);
      
      const response = await fetch(url);
      const data = await response.json();
      for (let order of data.salesData) {
        if(order.preferredDeliveryTime.anytime) order.preferredDeliveryTime = 'Any Time'
        if(order.preferredDeliveryTime.timeRange) order.preferredDeliveryTime = `${dayjs("01-01-2024 "+order.preferredDeliveryTime.timeRange.from).format('hh:mm A')}-${dayjs("01-01-2024 "+order.preferredDeliveryTime.timeRange.to).format('hh:mm A')}`
        if (order.preferredDeliveryTime.time) order.preferredDeliveryTime = dayjs("01-01-2024 "+order.preferredDeliveryTime.time).format('hh:mm A');
      }
      // Process data
      setOrdersData(data);
    } catch (e) {
      console.error(e);
      alert("Error Fetching Orders");
    }
  };

  const handleFilterChange = (filter) => {
    setCurrentView(filter);
    const query = new URLSearchParams(location.search);
    query.set("view", filter);
    navigate({ search: query.toString() });
  };

  useEffect(() => {
    const storeInQuery = query.get("store");
    if (storeInQuery && storeInQuery !== selectedStore) {
      setSelectedStore(storeInQuery);
    }
  }, [location.search]);  
  
    const handleStoreChange = (storeId) => {
    setSelectedStore(storeId);
    const query = new URLSearchParams(location.search);
    query.set("store", storeId);
    navigate({ search: query.toString()});
  };
  useEffect(()=>{
    if(selectedStore){
      fetchOrdersData()
    }
  },[selectedStore])  
  
  return (
    <div className="col-span-12 lg:col-span-10">
      <div className="flex flex-col w-full gap-3">
          <h2 className="font-bold">Orders</h2>
          <StoreSelector selectedStore={selectedStore} setSelectedStore={setSelectedStore} handleStoreChange={handleStoreChange} />
          {!selectedStore && (
                <div className="w-full h-[350px] flex justify-center items-center bg-white">
                  <p className="text-red-500 font-bold">Select a store first</p>
                  </div>
          )}
          {selectedStore && !Object.keys(ordersData)[0] && <div className="col-span-12 lg:col-span-10 flex items-center justify-center">
            <h3>Loading...</h3>
          </div>}
          {selectedStore && Object.keys(ordersData)[0] && <>
            <div className="flex justify-end items-end p-2">
              <select
                value={currentView}
                onChange={(e) => handleFilterChange(e.target.value)}
                className="rounded"
              >
                {Object.keys(statusMapper).map((itm) => (
                  <option key={itm} value={itm}>
                    {statusMapper[itm]}
                  </option>
                ))}
                </select>
            </div>

        <div className="mt-3">
          {currentView === "pending" && <PendingOrdersTable fetchOrders={fetchOrdersData} selectedStore={selectedStore} orders={ordersData} />}
          {currentView === "readyForDelivery" && (
            <ReadyForDeliveryTable fetchOrders={fetchOrdersData} selectedStore={selectedStore} orders={ordersData} />
          )}
          {currentView === "outForDelivery" && (
            <OutForDeliveryTable fetchOrders={fetchOrdersData} selectedStore={selectedStore} orders={ordersData} />
          )}
          {currentView === "fulfilled" && (
            <FulfilledTable fetchOrders={fetchOrdersData} selectedStore={selectedStore} orders={ordersData} />
          )}
          {currentView === "cancelled" && (
            <CancelledOrdersTable fetchOrders={fetchOrdersData} selectedStore={selectedStore} orders={ordersData} />
          )}
        </div>
        </>}
      </div>
    </div>
  );
};
