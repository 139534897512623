import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneNumberInput = ({ value, onChange, setPhoneNumWarning, labelClasses, label, required }) => {   
    const [placeholder, setPlaceholder] = useState("+XXX XXXXXXXXXX"); 
 
  const handlePhoneChange = (value, country) => {
    let formattedPhone = value;

    const countryCode = country.dialCode;  
      
    if (formattedPhone.startsWith(countryCode) && formattedPhone.charAt(countryCode.length) === '0') {        
        formattedPhone = countryCode + formattedPhone.slice(countryCode.length + 1);
    }

    const isValid = formattedPhone.length >= 12;
    onChange(formattedPhone);
    setPhoneNumWarning(!isValid);
    setPlaceholder(`+${country.dialCode} ${"X".repeat(9)}`);
  };


  return (
    <div className="w-full">
      <label className={`block mb-2 text-sm font-medium text-gray-900 ${labelClasses}`}>{label}{required&&<span className="text-rose-700">*</span>}</label>
      <PhoneInput
        country={"jo"}
        disableCountryGuess={true} 
        value={value}
        onChange={handlePhoneChange}
        onlyCountries={["jo", "eg", "ps", "iq"]} 
        disableCountryCode={false}
        placeholder={placeholder}
        inputProps={{
          name: "phone",
          required: true,
        }}       
        inputClass="!w-full !max-w-[300px]"
      />
      
    </div>
  );
};

export default PhoneNumberInput;
