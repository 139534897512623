import React from "react";
import { PasswordLayer } from "../PasswordLayer";
import CustomModal from "./Modal";
export const PasswordPopUp = ({
  id, passwordModalOpen, setPasswordModalOpen, setReceived
}) => {
  let productId
    console.log(id, 'id')
    const hanldeUpdateStatus =() =>{
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/purchase/updatestatus/${id}`, {
            method: "PATCH",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify(productId)
          })
            .then((result) => {
              alert("Mark As Received Done");
              setPasswordModalOpen(false)
              setReceived(true)
            })
            .catch((err) => console.log(err));
    }

  return (
    <CustomModal modalOpen={passwordModalOpen} setModalOpen={setPasswordModalOpen} title={'Mark As Received'}>
        <PasswordLayer setUnlocked={hanldeUpdateStatus} action={'received'}/>
    </CustomModal>
  )
}