import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const SalesRefundDetails = () => {
    const [order,setOrder]=useState()
    const { id } = useParams();

    useEffect (()=>{
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/get/refundOrder/${id}`)
        .then(async (response) => {
            const data=await response.json()
            if(!response.ok){
                throw data.message
            }
            return data
            })
          .then((data) => {
            setOrder(data);
          })
          .catch((err) => {
              console.log(err)
              alert(err)
          });
    },[id])

    const confirmPicking=()=>{
      if (window.confirm("Are you sure you want to confirm the picking of this order?")) {
        const user = localStorage.getItem('user');
        let parsedUser
        if (user) {
          parsedUser = JSON.parse(user);
          console.log(parsedUser, 'parsedUser');
        } else {
          console.log('No user found in local storage');
        }
        const body = {orderID : order._id, newUserID:parsedUser._id}

        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/refund/confirmPicking`, {
            method: "PATCH",
            body: JSON.stringify(body),
            headers: {
              "Content-type": "application/json",
            },
          }
          ).then(async (response) => {
            const data=await response.json()
            if(!response.ok){
                throw data.message
            }
             alert('Order confirmed')
             window.location.reload();
            })
          .catch((err) => {
              console.log(err)
              alert(err)
          });
      }
    }
    
    if (!order){
        return (
            <div className="col-span-12 h-screen w-screen lg:h-auto lg:w-auto lg:col-span-10 py-2 flex justify-center">
            <div className="flex flex-col gap-5 w-11/12">
                <div className="rounded-lg border bg-white border-gray-200 px-5 pb-3">
                    <div className="flex justify-center items-center pt-5 pb-3">
                        <div className="flex gap-4 justify-center items-center ">
                        <span className="font-bold text-xl">Order Not Found</span>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        )
    }
    return (
        <div className="col-span-12 h-screen w-screen lg:h-auto lg:w-auto lg:col-span-10 py-2 flex justify-center">
            <div className="flex flex-col gap-5 w-11/12">
                <div className="rounded-lg border bg-white border-gray-200 px-5 pb-3">
                    <div className="flex justify-between pt-5 pb-3">
                        <div className="flex gap-4 justify-center items-center ">
                         <span className="font-bold text-2xl">Refund Order: #{order.manualOrderId}</span>
                        </div>
                        <button 
                            className="p-2 text-white bg-[#14ae5c] rounded-lg font-semibold hover:brightness-[1.2] disabled:bg-gray-300 disabled:cursor-not-allowed disabled:brightness-100"
                            disabled = {order.Status !== 'pending'}
                            onClick={confirmPicking}
                        >
                             Confirm Picking
                        </button>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className="border-4 border-[#757575] flex flex-col gap-3 p-4 rounded-md">
                            <span className="font-bold text-[22px]">Original Order: #{order.originalOrderId.manualOrderId}</span>
                            <div className="grid grid-cols-2 gap-2 py-3 px-2">
                                <span className="text-sm sm:text-lg font-semibold">Customer: <span className="capitalize">{order.customerID.fullName}</span></span>
                                <span className="text-sm sm:text-lg font-semibold">Phone: {order.customerID.phone}</span>
                                <span className="text-sm sm:text-lg font-semibold">Address: <span className="capitalize">{order.deliveryAddress}</span></span>
                                <span className="text-sm sm:text-lg font-semibold">Payment Type: Cash</span>
                                <span className="text-sm sm:text-lg font-semibold">
                                    Order amount: {order.originalOrderId.products.reduce((acc, product) => acc + product.quantity * product.price - product.discount, 0)} JOD
                                </span>
                                <span className="text-sm sm:text-lg font-semibold">Delivery: {order.originalOrderId.deliveryAmount} JOD</span>
                                <span className="text-sm sm:text-lg font-semibold">Cash to Collect from Client: {order.originalOrderId.customerCashCollected} JOD</span>
                                <span className="text-sm sm:text-lg font-semibold">Cash to Collect from Delivery: {order.originalOrderId.deliveryCashCollected} JOD</span>
                            </div>
                        </div>
                        <span className="font-bold text-[24px] ml-2">Actions</span>
                        <div className="border-4 border-[#757575] flex flex-col gap-3 p-4 rounded-md">
                            <span className="font-bold text-lg">Refund Details</span>
                            <div className="flex flex-col gap-1 py-3 px-2">
                                <span className="text-sm sm:text-lg font-semibold">Reason</span>
                                <span className="text-sm sm:text-lg ml-2">{order.refundReason}</span>
                            </div>
                            <table className="bg-white min-w-screen space-y-8 border-spacing-1 text-sm overflow-x-auto">
                                <thead className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                                    <tr>
                                        <th className="px-2">Items</th>
                                        <th className="px-2">Qty</th>
                                        <th className="px-2">Price</th>
                                        <th className="px-2">Discount</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className="text-left">
                                    {order.products.map((product,index)=>{
                                    return (
                                        <tr key={index}>
                                            <td className="text-sm px-2 sm:text-lg">{index+1}- {product.productID.name}dsdfsdfdsf</td>
                                            <td className="text-sm px-2 sm:text-lg">x{product.quantity}</td>
                                            <td className="text-sm px-2 sm:text-lg">{product.quantity*product.price} <span className="text-[10px]">JOD</span></td>
                                            <td className="text-sm px-2 sm:text-lg">{product.discount} <span className="text-[10px]">JOD</span></td>
                                        </tr>
                                    )
                                    })}
                                </tbody>
                            </table>
                            <div className="bg-[#757575] h-[1px] "></div>
                            <span className="text-sm sm:text-lg text-red-500 font-semibold">Pay The Delivery: {order.refundDetails.cashToGiveDelivery} <span className="text-[8px] sm:text-[12px]">JOD</span></span>
                            <span className="text-sm sm:text-lg font-semibold">New Delivery Amount: {order.deliveryAmount} <span className="text-[8px] sm:text-[12px]">JOD</span></span>
                            <span className="text-sm sm:text-lg text-red-500 font-semibold">Delivery Will Pay the Customer: {order.refundDetails.cashToGiveCustomer} <span className="text-[8px] sm:text-[12px]">JOD</span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
