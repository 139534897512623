import React, { useState, useEffect } from "react";
import GoodsExpenseModal from "../containers/goodsExpenses/AddGoodsExpense";
import { saveAs } from 'file-saver';


function GoodsExpense() {
  const [showExpensesModal, setShowExpensesModal] = useState(false);
  const [allExpenses, setAllExpenses] = useState();
  const [updatePage, setUpdatePage] = useState(false);

  useEffect(() => {
    fetchGoodsExpenses();
  }, [updatePage]);


  // Fetching Data of All Products
  const fetchGoodsExpenses = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/goodsExpenses/`)
      .then((response) => response.json())
      .then((data) => {
        setAllExpenses(data);
      })
      .catch((err) => console.log(err));
  };

  // Modal for Sale Add
  const addSaleModalSetting = () => {
    setShowExpensesModal(!showExpensesModal);
  };

  // Handle Page Update
  const handlePageUpdate = () => {
    setUpdatePage(!updatePage);
  };

  const exportExcel = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/goodsExpenses/export-expenses`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      saveAs(blob, 'goods_expenses.csv');
    } catch (error) {
      console.error('Error exporting Excel file:', error);
    }
  };

  return (
    <div className="col-span-12 lg:col-span-10  flex justify-center">
      <div className=" flex flex-col gap-5 w-11/12">
        {showExpensesModal && (
          <GoodsExpenseModal
            setShowGoodsExpenseModal={setShowExpensesModal}
            handlePageUpdate={handlePageUpdate}
          />
        )}
        {/* Table  */}
        <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 ">
          <div className="flex justify-between pt-5 pb-3 px-3">
            <div className="flex gap-4 justify-center items-center ">
              <span className="font-bold">Goods Expenses</span>
            </div>
            <div className="flex gap-4">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 text-xs  rounded"
                onClick={addSaleModalSetting}
              >
                {/* <Link to="/inventory/add-product">Add Product</Link> */}
                Add Expense
              </button>
              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold p-2 text-xs rounded"
                onClick={exportExcel}
              >
                Export Excel
              </button>
            </div>
          </div>
          <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
            <thead>
              <tr>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Name
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {allExpenses && allExpenses.length > 0 && allExpenses.map((element, index) => {
                return (
                  <tr key={element._id}>
                    <td className="whitespace-nowrap px-4 py-2  text-gray-900">
                      {element.name}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default GoodsExpense;
