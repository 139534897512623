import { useState } from "react"
import QrScanner from "../components/common/QrScanner"
import { useParams } from "react-router-dom";

const PrepareOrder = () => {
  const { id } = useParams();
  const [includedProducts, setIncludedProducts] = useState([]);
  const [showScanner, setShowScanner] = useState(false)
  const onScanFn = (data) => {
    setIncludedProducts([...includedProducts, data]);
  }

  return (
    <div className="lg:col-span-10 col-span-12 w-full">
      <div className="w-full h-screen px-2">
        {console.log({includedProducts})}
        <div className={`w-full ${showScanner ? "blur":""}`}>
          <div className="flex justify-between items-center w-full">
            <h1>Order #{id}</h1>
            <button onClick={()=>setShowScanner(true)} className="bg-blue-500 px-1 py-2 rounded text-white">Scan Piece</button>
          </div>
        </div>
        <div className="flex">
          <QrScanner onScanFn={onScanFn} setShowScanner={setShowScanner} showScanner={showScanner} />
        </div>
      </div>

    </div>
  )
}

export default PrepareOrder