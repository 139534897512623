import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { HiRefresh } from "react-icons/hi";
import BarChart from "../../components/common/chart/BarChart";

ChartJS.register(ArcElement, Tooltip, Legend);
export const data = {
  labels: ["Apple", "Knorr", "Shoop", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [0, 1, 5, 8, 9, 15],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

function Dashboard() {
    
    const [selectedDate, setSelectedDate] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [totalSalesAmount, setTotalSalesAmount] = useState(0);
    const [totalOrdersPlaced, setTotalOrdersPlaced] = useState(0);
    const [saleSources, setSaleSources] = useState(0);
    const [totalOrders, setTotalOrders] = useState(0);
    const [keyInsights, setKeyInsights] = useState();
    
  // Function to format the date and time as "yyyy-MM-ddTHH:mm"
  const formatDateTime = (date) => {
      const pad = (n) => (n < 10 ? `0${n}` : n);
      const year = date.getFullYear();
      const month = pad(date.getMonth() + 1);
      const day = pad(date.getDate());
      const hours = pad(date.getHours());
      const minutes = pad(date.getMinutes());
      return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  useEffect(() => {
    const now = new Date();
    
    const startOfDay = new Date(now.setHours(0, 0, 0, 0));
    setFromDate(formatDateTime(startOfDay));
    
    setToDate(formatDateTime(new Date()));
  }, []);
  
    const handleFromDateChange = (e) => {
        const selectedFromDate = e.target.value;
        
        if (new Date(selectedFromDate) > new Date(toDate)) {
            alert("Invalid 'From' Date cannot be more late than the 'To' date.");
            setFromDate(toDate);
        } else {
            setFromDate(selectedFromDate);
        }
    };

    const handleToDateChange = (e) => {
        const selectedToDate = e.target.value;
        
        if (new Date(selectedToDate) < new Date(fromDate)) {
          alert("Invalid 'To' date cannot be earlier than the 'From' date.");
          setToDate(fromDate);
        } else {
          setToDate(selectedToDate);
        }
    };

    const handleRefetch = () => {
        fetchOrderSummary();
    };

  const fetchOrderSummary = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/orderDashboard?from=${fromDate.toString()}&to=${toDate.toString()}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

        const { totalCustomerCashCollected , totalOrdersPlaced  } = data; // Default values in case of undefined
        setTotalSalesAmount(totalCustomerCashCollected);
        setTotalOrdersPlaced(totalOrdersPlaced);
    } catch (error) {
        console.error('Error fetching order summary:', error);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/ordersBySaleSources?from=${fromDate.toString()}&to=${toDate.toString()}`, {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
          },
      });

      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const sourceNames = data.map(product => product.salesSource);
      setSaleSources(sourceNames);
      const setSelected = data.map(item => item.totalCustomerCashCollected);
      setSelectedDate(setSelected);
      const setOrdersTotal = data.map(item => item.totalOrdersPlaced);
      setTotalOrders(setOrdersTotal);

    } catch (error) {
        console.error('Error fetching order summary:', error);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/ordersByStatus?from=${fromDate.toString()}&to=${toDate.toString()}`, {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
          },
      });

      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setKeyInsights(data)
    } catch (error) {
        console.error('Error fetching order insights:', error);
    }
  };


  useEffect(() => {
    fetchOrderSummary()
  }, []);

  return (
    <>
        <div className="grid grid-cols-1 col-span-12 lg:col-span-10 gap-4 md:grid-cols-3 lg:grid-cols-4 p-2">
            <span className="grid grid-cols-1 col-span-12 lg:col-span-10 gap-6 md:grid-cols-3 lg:grid-cols-4">
                <div className="flex flex-col gap-2">
                    <label htmlFor="date-from" className="text-sm font-medium text-gray-90">From</label>
                    <input
                    type="datetime-local"
                    name="date-from"
                    value={fromDate || ""}
                    onChange={handleFromDateChange}
                    max={formatDateTime(new Date())} // Set max to the current date and time
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 min-w-[150px] focus:border-primary-600 p-2.5"
                    onKeyDown={(e) => e.preventDefault()} // Disable typing
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <label htmlFor="date-to" className="text-sm font-medium text-gray-90">To</label>
                    <input
                    type="datetime-local"
                    name="date-to"
                    value={toDate || ""}
                    onChange={handleToDateChange}
                    max={formatDateTime(new Date())} // Set max to the current date and time
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 min-w-[150px] focus:border-primary-600 p-2.5"
                    onKeyDown={(e) => e.preventDefault()} // Disable typing
                    />
                </div>
            </span>
            <div className="w-full items-center flex gap-2">
                <button onClick={handleRefetch} className="border-2 bg-green-100 rounded border-black p-2 flex items-center">
                    <HiRefresh className="mr-1" /> Refetch
                </button>
                {/* <b>Last Fetched:</b> */}
            </div>
            <div className="grid grid-cols-1 col-span-12 lg:col-span-10 gap-6 md:grid-cols-3 lg:grid-cols-4">
                <article className="flex flex-col gap-4 rounded-lg border border-gray-100 bg-white p-6">
                    <div className="inline-flex gap-2 self-end rounded bg-green-100 p-1 text-green-600">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                            />
                        </svg>
                        <span className="text-xs font-medium">27.81%</span>
                    </div>
                    <div>
                        <strong className="block text-sm font-medium text-gray-500">Total Orders Captured</strong>
                        <p>
                            <span className="text-2xl font-medium text-gray-900">JOD{totalSalesAmount}</span>
                        </p>
                    </div>
                </article>
                <article className="flex flex-col gap-4 rounded-lg border border-gray-100 bg-white p-6">
                    <div className="inline-flex gap-2 self-end rounded bg-green-100 p-1 text-green-600">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                            />
                        </svg>
                        <span className="text-xs font-medium">27.81%</span>
                    </div>
                    <div>
                        <strong className="block text-sm font-medium text-gray-500">Total Orders Placed</strong>
                        <p>
                            <span className="text-2xl font-medium text-gray-900">{totalOrdersPlaced}</span>
                        </p>
                    </div>
                </article>
            </div>
            <div className="flex flex-col md:flex-row justify-center bg-white rounded-lg py-8 col-span-full p-4">
                <div className="w-full md:w-1/2 mb-4 md:mb-0">
                    <BarChart classes={saleSources} data={selectedDate} width="100%" title={'Sales Per Source'} />
                </div>
                <div className="w-full md:w-1/2">
                    <BarChart classes={saleSources} data={totalOrders} width="100%" title={'Orders Per Source'} />
                </div>
            </div>
            <div className="grid grid-cols-1 col-span-12 lg:col-span-10 gap-6 md:grid-cols-3 lg:grid-cols-4">
                <article className="flex flex-col gap-4 rounded-lg border border-gray-100 bg-white p-6">
                    <div>
                        <strong className="block text-sm font-medium text-gray-500">Total Out For Delivery Orders </strong>
                        <p>
                            <span className="text-2xl font-medium text-gray-900">{keyInsights?.totalOutForDelivery}</span>
                        </p>
                    </div>
                </article>
                <article className="flex flex-col gap-4 rounded-lg border border-gray-100 bg-white p-6">
                    <div>
                        <strong className="block text-sm font-medium text-gray-500">Total Ready For Delivery Orders </strong>
                        <p>
                            <span className="text-2xl font-medium text-gray-900">{keyInsights?.totalReadyForDelivery}</span>
                        </p>
                    </div>
                </article>
                <article className="flex flex-col gap-4 rounded-lg border border-gray-100 bg-white p-6">
                    <div>
                        <strong className="block text-sm font-medium text-gray-500">Total Pending Orders Captured</strong>
                        <p>
                            <span className="text-2xl font-medium text-gray-900">{keyInsights?.totalPendingOrders}</span>
                        </p>
                    </div>
                </article>
                <article className="flex flex-col gap-4 rounded-lg border border-gray-100 bg-white p-6">
                    <div>
                        <strong className="block text-sm font-medium text-gray-500">Total Fulfilled Orders </strong>
                        <p>
                            <span className="text-2xl font-medium text-gray-900">{keyInsights?.totalFulfilledOrders}</span>
                        </p>
                    </div>
                </article>
                <article className="flex flex-col gap-4 rounded-lg border border-gray-100 bg-white p-6">
                    <div>
                        <strong className="block text-sm font-medium text-gray-500">Total Cancelled Orders </strong>
                        <p>
                            <span className="text-2xl font-medium text-gray-900">{keyInsights?.totalCancelledOrders}</span>
                        </p>
                    </div>
                </article>
                <article className="flex flex-col gap-4 rounded-lg border border-gray-100 bg-white p-6">
                    <div>
                        <strong className="block text-sm font-medium text-gray-500">Total Refund Orders </strong>
                        <p>
                            <span className="text-2xl font-medium text-gray-900">{keyInsights?.totalRefundOrders}</span>
                        </p>
                    </div>
                </article>
                <article className="flex flex-col gap-4 rounded-lg border border-gray-100 bg-white p-6">
                    <div>
                        <strong className="block text-sm font-medium text-gray-500">Total new Orders </strong>
                        <p>
                            <span className="text-2xl font-medium text-gray-900">{keyInsights?.totalNewOrders}</span>
                        </p>
                    </div>
                </article>
            </div>

            {/* <div>
                <Doughnut data={data} />
            </div> */}
        </div>
    </>
);

}

export default Dashboard;
