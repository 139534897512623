import SalesContainer from "../containers/sales/SalesContainer"

export const Sales = () => {

    return(
        <>
        <SalesContainer/>
        </>
    )
}
