import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EditPurchaseModal } from "./components/EditPurchaseModal";
import { PasswordPopUp } from "../../components/common/passwordPopUp";
import { PurchaseResponseModal } from "./components/HandlePurchaseResponsModal";
import { currencyMapper } from "../../utils/currency";

export const SinglePurchaseDetailsContainer = () => {
  const { id } = useParams();
  const [loadingQrs, setLoadingQrs] = useState();
  const [loading, setLoading] = useState(false);
  const [purchaseDetails, setPurchaseDetails] = useState(null);
  const [showFocDetails, setShowFocDetails] = useState([]);
  const [showCostDetails, setShowCostDetails] = useState([]);
  const [focCategories, setFocCategories] = useState();
  const [expensesCategories, setExpensesCategories] = useState();
  const [selectedFocProduct, setSelectedFocProduct]=useState()
  const [checkCredentials, setCheckCredentials]=useState(false)
  const [passwordModalOpen, setPasswordModalOpen]=useState(false)
  const [received, setReceived]=useState(false)
  const [resModalOpen, setResModalOpen] = useState(false)
  const [payload,setPayload]=useState()

  const handleFocDetails = (idx, operation) => {
    if (operation === 'show') {
      setShowFocDetails((prev) => [...prev, idx])
    }
    else if (operation === "hide") {
      setShowFocDetails((prev)=>prev.filter(item => item !== idx))
    }
  }
  const handleCostDetails = (idx, operation) => {
    if (operation === 'show') {
      setShowCostDetails((prev) => [...prev, idx])
    }
    else if (operation === "hide") {
      setShowCostDetails((prev)=>prev.filter(item => item !== idx))
    }
  }

  const fetchPurchaseDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/purchase/get/${id}`);
      const data = await response.json();
      setPurchaseDetails(data);
    } catch (e) {
      console.log(e);
      alert("Something went wrong, please contact the technical team.");
    } finally {
      setLoading(false);
    }
  };
  const fetchFocCategories = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/focCategories`);
      const data = await response.json();
      setFocCategories(data);
    } catch (e) {
      console.log(e);
      alert("Something went wrong, please contact the technical team.");
    } finally {
      setLoading(false);
    }
  };

  const fetchExpensesCategories = async ()=>{
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/goodsExpenses`);
      const data = await response.json();
      setExpensesCategories(data);
    } catch (e) {
      console.log(e);
      alert("Something went wrong, please contact the technical team.");
    } finally {
      setLoading(false);
    }
  }

  const handleGenerateQrs = async (productID) => {
    setLoadingQrs(true);
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/productPieces/generateQr/${id}?productID=${productID}`);
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'productPiecesQRCodes.zip'; // Set the filename for the download
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      alert("Error generating QRs, Please contact the technical team")
    } finally {
      setLoadingQrs(false)
    }
  }

  const calculateTotalFoc = (idx) =>  purchaseDetails.products[idx].foc.reduce((sum, itm) => sum + itm.quantity, 0)
  
  useEffect(() => {
    fetchPurchaseDetails();
    fetchFocCategories();
    fetchExpensesCategories();
  }, [id]);

  const handleReceivedButton = ()=>{
    setCheckCredentials(!checkCredentials)
    setPasswordModalOpen(!passwordModalOpen)
  }
  const [modalOpen, setModalOpen] = useState(false)
  const currentPurchaseCurrency = currencyMapper[purchaseDetails?.storeID?.country] || 'JOD'

  if (loading) return (
    <div className="h-screen w-screen flex justify-center items-center">
      <h1>Please Wait...</h1>
    </div>
  );

  if (!purchaseDetails) return (
    <div className="h-screen w-screen flex justify-center items-center">
      <h1>No purchase details found.</h1>
    </div>
  );

  return (
    <>
    {checkCredentials && <PasswordPopUp setReceived={setReceived} passwordModalOpen={passwordModalOpen} setPasswordModalOpen={setPasswordModalOpen} id={id}/>}
      {modalOpen && <EditPurchaseModal focCategories={focCategories} expensesCategories={expensesCategories} purchase={purchaseDetails} modalOpen={modalOpen} product={selectedFocProduct} setProduct={setSelectedFocProduct} purchaseID={id} setModalOpen={setModalOpen} setPayload={setPayload} setResModalOpen={setResModalOpen}/>}
      {resModalOpen && <PurchaseResponseModal resModalOpen={resModalOpen} setResModalOpen={setResModalOpen} product={selectedFocProduct} payload={payload}/>}
    <div className=" w-full lg:col-span-10 col-span-12">
      <div className="px-6">
        <div className="flex w-full">
        <div className="flex justify-between items-center mb-4 w-4/5 flex-wrap">
          <h2 className="text-xl font-bold">Purchase Date: {new Date(purchaseDetails.createdAt).toLocaleDateString()}</h2>
          <h2 className="text-xl font-bold">Total Cost: {currentPurchaseCurrency } {purchaseDetails.totalPurchaseAmount.toFixed(2)}</h2>
        </div>
        {!received && purchaseDetails.status !== "received" &&<button onClick={handleReceivedButton} className="ml-12 items-center w-1/6 bg-cyan-600 text-white p-[10px] rounded-lg font-semibold">Mark as Received</button>}
        </div>
        <h2 className="text-xl font-bold mb-4">Store: {purchaseDetails.storeID?.name}</h2>
      <div className="flex flex-wrap gap-4">
        {purchaseDetails.products.map((product, index) => (
          <div key={index} className="border p-4 rounded-xl shadow bg-white md:w-[300px] w-[340px]">
            <span className="flex justify-between items-center">
            <h3 className="text-lg font-bold mb-2">{product.productID?.name}</h3>
            <div className="">
            {product.productID?.image ? (
                        <img
                          src={product.productID?.image}
                          alt="Uploaded"
                          className="w-24 h-24 object-cover"
                        />
                      ) : (
                        <span>No image</span>
                )}
            </div>
            </span>
            <div className="grid grid-cols-1 gap-2">
              <div>
                <span className="font-semibold">Quantity:</span> {product.quantity}
              </div>
              <div className="border-2 border-gray-500 p-2">
                <span className="font-semibold mr-[15px]">Total FOC: {calculateTotalFoc(index)}</span> 
                {!showFocDetails.includes(index) && <span className="underline text-blue-400 cursor-pointer" onClick={()=>handleFocDetails(index, "show")}>Details</span>}
                {showFocDetails.includes(index) && <span className="underline text-blue-400 cursor-pointer" onClick={() => handleFocDetails(index, "hide")}>Hide Details</span>}
                {showFocDetails.includes(index) && (
                <div>
                  {product.foc.map(focItm => (
                    <>
                      <h2 className="font-bold">Category:<span className="font-normal">{focItm.focCategoryID.name}</span></h2>
                      <h2 className="font-bold">Quantity:<span className="font-normal">{focItm.quantity}</span></h2>
                    </>
                  ))}
                </div>
              )}
              </div>
              <div>
                <span className="font-semibold">Received quantity:</span> {product.quantity - calculateTotalFoc(index)}
                <br/>
                <span className="font-semibold">Average Cost:</span> {Number(product.avgCost).toFixed(2)} {currentPurchaseCurrency}
              </div>
              <div className="border-2 border-gray-500 p-2">
                <span className="font-semibold m-[5px]">Total Cost: {product.totalPrice.toFixed(2)} {currentPurchaseCurrency}</span>
                {!showCostDetails.includes(index) && <span className="underline text-blue-400 cursor-pointer" onClick={()=>handleCostDetails(index, "show")}>Details</span>}
                {showCostDetails.includes(index) && <span className="underline text-blue-400 cursor-pointer" onClick={() => handleCostDetails(index, "hide")}>Hide Details</span>}
                {showCostDetails.includes(index) && <div className="mt-2">
                  <h4 className="font-semibold">Expenses:</h4>
                  {product.expenses.map((expense, expIndex) => (
                    <div key={expIndex} className="ml-2">
                      <div>
                        <span className="font-semibold">Type:</span> {expense.expenseID.name}
                      </div>
                      <div>
                        <span className="font-semibold">Cost:</span> JOD {expense.cost.toFixed(2)}
                      </div>
                    </div>
                  ))}
              </div>}
              </div>
                  <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 text-xs rounded justify-self-end mt-4"
                  onClick={() => handleGenerateQrs(product.productID._id)}
                  disabled={loadingQrs}
                >
                    {loadingQrs ? "Please wait..." : `Generate (${product.quantity - calculateTotalFoc(index)}) QRs`}
                </button>
                <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 text-xs rounded justify-self-end mt-4"
                onClick={() => {
                  setSelectedFocProduct(() => product)
                  setModalOpen(true)
                }}
                >
                    {"Edit"}
                </button>
            </div>
          </div>
        ))}
      </div>
      </div>
      </div>
      </>
  );
};
