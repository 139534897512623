import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../AuthContext";
import AddProduct from "./components/AddProduct";
import { saveAs } from 'file-saver';
import { MdEdit } from "react-icons/md";

function ProductsContainer() {
  const [showProductModal, setshowProductModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [products, setAllProducts] = useState([]);
  const [singleProduct, setSingleProduct] = useState();
  const [updatePage, setUpdatePage] = useState(true);
  const [sales, setSaleSources] = useState(true);

  const authContext = useContext(AuthContext);

  useEffect(() => {
    fetchProductsData();
    fetchSaleSources();
  }, [updatePage]);


  // Fetching Data of All Products
  const fetchProductsData = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/get/${authContext.user}`)
      .then((response) => response.json())
      .then((data) => {
        setAllProducts(data);
      })
      .catch((err) => console.log(err));
  };

  // Fetching Data of All Products
  const fetchSaleSources = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/salesources`)
      .then((response) => response.json())
      .then((data) => {
        setSaleSources(data);
      })
      .catch((err) => console.log(err));
  };

  const addSaleModalSetting = () => {
    setshowProductModal(!showProductModal);
    fetchProductsData();
  };

  const openModalSetting = (element) => {
    setOpenUpdateModal(!openUpdateModal);
    setSingleProduct(element)
    fetchProductsData();
  };

  // Handle Page Update
  const handlePageUpdate = () => {
    setUpdatePage(!updatePage);
  };

  const exportExcel = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/export-products`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      saveAs(blob, 'products.csv');
    } catch (error) {
      console.error('Error exporting Excel file:', error);
    }
  };

  return (
    <div className="col-span-12 lg:col-span-10  flex justify-center">
      <div className=" flex flex-col gap-5 w-11/12">
        {showProductModal && (
          <AddProduct
            addProductModalSetting={addSaleModalSetting}
            products={products}
            handlePageUpdate={handlePageUpdate}
            sales={sales}
          />
        )}
        {openUpdateModal && (
          <AddProduct
            addProductModalSetting={openModalSetting}
            products={products}
            handlePageUpdate={handlePageUpdate}
            sales={sales}
            singleProduct={singleProduct}
          />
        )}
        {/* Table  */}
        <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 ">
          <div className="flex justify-between pt-5 pb-3 px-3">
            <div className="flex gap-4 justify-center items-center ">
              <span className="font-bold">Products</span>
            </div>
            <div className="flex gap-4">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 text-xs  rounded"
                onClick={addSaleModalSetting}
              >
                {/* <Link to="/inventory/add-product">Add Product</Link> */}
                Add Product
              </button>
              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold p-2 text-xs rounded"
                onClick={exportExcel}
              >
                Export Excel
              </button>
            </div>
          </div>
          <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
            <thead>
              <tr>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Image                
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Product Name
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Description
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Project Name
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Total Sales Amount (JOD)
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {products.map((element, index) => {
                return (
                  <tr key={element._id}>
                    <td className="whitespace-nowrap px-4 py-2">
                      {element.image ? (
                        <img
                          src={element.image}
                          alt="Uploaded"
                          className="w-24 h-24 object-cover"
                        />
                      ) : (
                        <span>No image</span>
                      )}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2  text-gray-900">
                      {element.name}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {element.description}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {element.project}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      <ul>
                        {element.sellingPrices.map((price, index) => (
                          <li key={index}>
                            Source ID: {price.sourceId}, Price: {price.price}
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <MdEdit className="cursor-pointer" color="grey" size={30} onClick={() => openModalSetting(element)}/>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ProductsContainer;