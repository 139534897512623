// Enum for Order Status
export const orderStatusEnum = {
    pending: { label: "Pending", color: "#f0ad4e" }, 
    readyForDelivery: { label: "Ready for Delivery", color: "#5bc0de" }, 
    outForDelivery: { label: "Out for Delivery", color: "#0275d8" }, 
    fulfilled: { label: "Fulfilled", color: "#5cb85c" }, 
    cancelled: { label: "Cancelled", color: "#d9534f" }
};

// Enum for Payment Status
export const paymentStatusEnum = {
    pending: { label: "Pending", color: "#f0ad4e" }, 
    paid: { label: "Paid", color: "#5cb85c" }, 
    cashCollected: { label: "Cash Collected", color: "#5bc0de" }, 
    pendingPaymentConfirmation: { label: "Pending Payment Confirmation", color: "#0275d8" }, 
    uncollected: { label: "Uncollected", color: "#d9534f" }
};
