import { MdDelete } from "react-icons/md";
import { InputField } from "../../../../components/common/InputFields/InputField";
import { SearchableDropdown } from "../../../../components/common/dropdowns/SearchableDropdown";

export const ProductItem = ({ product, productIndex, handleProductChange, handleDelete, options, saleCurrency, storeID }) => {
    const renderStockMessage =(product, requestedQuantity = 0)=> {
        if (!product.availableStock || product.availableStock.length === 0) {
            return null;
        }
        
        const totalAvailableStock = product.availableStock.find(stock=>stock.storeID===storeID).quantity || 0
        
        const isStockInsufficient = requestedQuantity > totalAvailableStock;
        return (
            <p style={{ color: isStockInsufficient ? 'red' : 'black' }}>
            Available Stock: {totalAvailableStock} {isStockInsufficient && `(Requested: ${requestedQuantity})`}
            </p>
        );
        }  
    return(
    <div className="w-full">
      <div className="flex justify-between items-center">
        <h1>
          <b>
            Product ({productIndex + 1}) - ( {( (product?.price|| 0) * product?.quantity ) - (product?.discount || 0) } {saleCurrency})
          </b>
        </h1>
        <button
          type="button"
          className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm p-2 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 mt-4 inline-flex items-center justify-between"
          onClick={() => handleDelete(productIndex)}
        >
          <MdDelete /> Product
        </button>
      </div>
      <div className="flex gap-2">
        <SearchableDropdown
          label="Product Name"
          required
          value={product.productID}
          onChange={(e) => handleProductChange(productIndex, 'product', e)}
          options={options}
          placeholder="Select Product"
        />
        <InputField
          label="Quantity"
          name="quantity"
          value={product?.quantity}
          onChange={(e) => handleProductChange(productIndex, 'quantity', e.target.value)}
          type="number"
          placeholder="0 - 999"
          required
        />
        <InputField
          label="Discount"
          name="discount"
          value={product?.discount}
          onChange={(e) => {
            const value = Math.min(e.target.value, product.quantity * product.price);
            handleProductChange(productIndex, 'discount', value);
          }}
          type="number"
          max = {product.quantity * product.price}
          placeholder={saleCurrency}
        />
      </div>
      <p>{renderStockMessage(product, product.quantity)}</p>
      <hr className="mt-6 h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
    </div>
  );}