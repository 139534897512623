import React, { useState, useEffect } from "react";
import Select from "react-select";
import Cities from './cities'; // Adjust path as needed

// Convert city names to format required by react-select

export const CitySelect = ({ onChange, cityValue ,country}) => {
  const [selectedCity, setSelectedCity] = useState(null);
  
  const cityOptions = country?.value ? Cities[country?.value].map(city => ({
    value: city,
    label: city
  })) : []
  
  console.log(cityValue);
  useEffect(()=>{
    if(cityOptions && !cityOptions.includes(selectedCity?.value)){
      setSelectedCity(null)
    }
  },[country])
  
  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
    if (onChange) onChange(selectedOption); // Call the onChange prop with the selected option
  };

  return (
    <Select
      options={cityOptions}
      value={cityValue?.value ? cityValue : selectedCity } // Use internal state or prop value
      onChange={handleCityChange}
      placeholder={"Select a city"}
    />
  );
};

export default CitySelect;