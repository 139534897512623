
import { TransferStockContainer } from "../containers/stockTransfer/StockTransferContainer";

export const TransferStock= ()=>{
  return (
    <TransferStockContainer/>
  )   
};


