import React from "react";
import { Link, useLocation } from "react-router-dom";

const NavLinks = ({ to, imgSrc, imgAlt, text, icon }) => {
    const location = useLocation();
    const isActive = location.pathname === to;
  return (
    <Link
      to={to}
      className={`flex items-center gap-2 rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700 ${
        isActive ? "bg-blue-500 text-white hover:!bg-blue-500 hover:!text-white" : ""
      }`}
    >
      {imgSrc && <img alt={imgAlt} src={imgSrc} />}
      {icon && icon}
      <span className="text-sm font-medium">{text}</span>
    </Link>
  );
};

export default NavLinks;
