import { useNavigate } from "react-router-dom";
import CustomModal from "../../../components/common/Modal"

export const ConfirmTransferModal = ({ modalOpen, setModalOpen, transferID, scanned }) => {
  const navigate = useNavigate();

  const handleConfirmTransferOrder = async () => {
    const body = {
      transferID,
      scannedItems: scanned.map(item => item._id)
    }
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/stockTransfers/confirmTransfer`, {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json",
        },
      }
      );
      const data = await res.json();
      if (!res.ok) throw data;
      alert('Stock Transfer Completed')
      navigate("/stock-transfers");
    } catch (e) {
      console.error(e)
      alert(e.message || "Something went wrong!");
    }
  }
  return (
    <CustomModal modalOpen={modalOpen} setModalOpen={setModalOpen} title={"Confirm Transfer"} >
      <div className="flex flex-col items-center justify-center text-center">
        <p>By confirming, the transfer status will be "Complete"</p>
        <div className="flex items-center justify-center mt-4 w-full">
          <button className="rounded p-2 bg-red-500 text-white" onClick={()=>setModalOpen(false)}>Cancel</button>
          <button className="rounded p-2 bg-green-500 text-white ml-3" onClick={()=>handleConfirmTransferOrder()}>Confirm</button>
        </div>
      </div>
    </CustomModal>
  )
}