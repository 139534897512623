import React, { useState, useEffect } from "react";

function UploadImage({ onImageUpload, initialImageURL, maxImageSize}) {
  const [fileName, setFileName] = useState("");
  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    if (initialImageURL && typeof initialImageURL === 'string') {
      setImageURL(initialImageURL); // Set initial image URL if provided
      setFileName(""); // Clear file name if an initial image URL is provided
    }
  }, [initialImageURL]);

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      return; 
    }

    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"];
    if (!allowedTypes.includes(file.type)) {
      alert("Please select a valid image file (png, jpeg, jpg, webp).");
      return;
    }

    // Check file size
    if (maxImageSize && file.size > maxImageSize) {
      alert(`File size exceeds the limit of ${maxImageSize / 1024 / 1024} MB.`);
      return;
    }

    const imgName = file.name;
    const url = URL.createObjectURL(file)
    setImageURL(url);
    setFileName(imgName)
    onImageUpload(file, imgName);
  };


  
  const handleRemoveImage = () => {
    onImageUpload("");
    setFileName("");
    setImageURL("");
  };



  return (
    <div>
      <label
        htmlFor="fileInput"
        className="inline-block rounded-md shadow-sm py-2 px-4 bg-gray-100 text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <svg
          className="w-6 h-6 inline-block mr-2"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 16V21H21V16H3ZM5 18H19V16H5V18ZM3 6H21V14H3V6ZM5 10H19V8H5V10Z"
            fill="currentColor"
          />
        </svg>
        <span className="inline-block">
          {fileName || "Choose file"}
        </span>
      </label>
      <input
        type="file"
        id="fileInput"
        className="hidden"
        accept=".png, .jpeg, .jpg, .webp"
        onChange={handleFileInputChange}
        required
      />
      {imageURL && (
        <div className="mt-2">
          <img
            src={imageURL}
            alt="Uploaded preview"
            className="w-32 h-32 object-cover"
          />
          <button
            type="button"
            onClick={handleRemoveImage}
            className="ml-2 text-red-500 hover:text-red-700"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
}

export default UploadImage;
