import { useState } from "react";
import CustomModal from "../../components/common/Modal";
import QrScanner from "../../components/common/QrScanner";

export const SearchScanQR = ({ modalOpen, setModalOpen, setFilters, setLocalFilters }) => {
  const [showScanner, setShowScanner] = useState(true);

  const handleScannedProduct = (scannedItem) => {
    setModalOpen(false);
    setFilters((prev)=>({product:'', id:scannedItem._id}));
    setLocalFilters((prev)=>({product:'', id:scannedItem._id}));
  }

  return (
    <CustomModal modalOpen={modalOpen} setModalOpen={setModalOpen} title={"Scan Item"}>
      <QrScanner onScanFn={handleScannedProduct} showScanner={showScanner} setShowScanner={setShowScanner} />
    </CustomModal>
  )
}