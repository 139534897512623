import React, { useEffect, useState } from "react";
import { UpdatePaymentStatusModal } from "./UpdatePaymentStatusModal";
import { FaCheckDouble } from "react-icons/fa";
import { MarkAsPaidModal } from "./MarkAsPaidModal";
import { Link } from "react-router-dom";
import { HiRefresh } from "react-icons/hi";
import { currencyMapper } from "../../../utils/currency";

export const FulfilledTable = ({ fetchOrders, orders, selectedStore }) => {
  const [updatePaymentStatusOpen, setUpdatePaymentStatusOpen] = useState(false);
  const [paidModalOpen, setPaidModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [fetchNow, setFetchNow] = useState(false);
  const [selectedOrderType, setSelectedOrderType] = useState("");
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 1000000;
  const [selectAll, setSelectAll] = useState(false); // New state for "Select All"

  const { salesData = [], totalPages = 1 } = orders;

  const paymentFilters = [
    { value: "", label: "All" },
    { value: "pending", label: "Pending" },
    { value: "pendingPaymentConfirmation", label: "Pending Confirmation" },
    { value: "uncollected", label: "Uncollected" },
    { value: "cashCollected", label: "Cash Collected" },
    { value: "paid", label: "Paid" },
    { value: "refunded", label: "Refunded" },

  ];

  const paymentStatusMapper = {
    pendingPaymentConfirmation: "Pending Conf.",
    pending: "Pending",
    paid: "Paid",
    uncollected: "Uncollected",
    cashCollected:"Cash Col.",
    refunded:'Refunded'
  }

  useEffect(() => {
      setLoading(true);
      fetchOrders("fulfilled", {
        orderType: selectedOrderType,
        paymentStatus: selectedPaymentStatus,
        limit: itemsPerPage,
        page: currentPage,
      }).finally(() => setLoading(false));
  }, [selectedOrderType, selectedPaymentStatus, currentPage, fetchNow]);

  useEffect(() => {
    if (shouldRefetch) {
      reset()
    }
    return ()=> setShouldRefetch(false)
  },[shouldRefetch, setShouldRefetch])
  
  useEffect(() => {
    reset()
    setFetchNow(prev=>!prev)
  },[selectedStore])
  
  const reset=()=>{
    setSelectedOrderType("");
    setSelectedPaymentStatus("");
    setCurrentPage(1);
    setSelectedItems([])
    setSelectedOrders([])
    setSelectAll(false)
}  
  const handleCheckboxChange = (e, order) => {
    if (e.target.checked) {
      setSelectedItems((prev) => [...prev, order._id]);
      setSelectedOrders((prev) => [...prev, order]);
    } else {
      setSelectedItems((prev) => prev.filter((itemId) => itemId !== order._id));
      setSelectedOrders((prev) => prev.filter((ord) => ord._id !== order._id));
      setSelectAll(false); // Uncheck "Select All" if any checkbox is unchecked
    }
  };

  // New handleSelectAll function
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      // If "Select All" is checked, select all items
      const allItemIDs = salesData.map((item) => item._id);
      const allOrders = salesData;
      setSelectedItems(allItemIDs);
      setSelectedOrders(allOrders);
      setSelectAll(true); // Mark "Select All" as checked
    } else {
      // If "Select All" is unchecked, clear all selected items
      setSelectedItems([]);
      setSelectedOrders([]);
      setSelectAll(false);
    }
  };

  const handleOrderTypeChange = (e) => {
    setSelectedOrderType(e.target.value);
    setCurrentPage(1);
    setSelectedItems([]);
    setSelectedOrders([]);
    setSelectAll(false);
    };
  
  const handlePaymentStatusChange = (e) => {    
    setSelectedPaymentStatus(e.target.value);
    setCurrentPage(1);
    setSelectedItems([]);
    setSelectedOrders([]);
    setSelectAll(false);
    };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const updatePaymentStatusAction = () => {
    setUpdatePaymentStatusOpen(true); 
  };

const totalReceivable = selectedItems.length > 0 ? 
  salesData
    .filter((itm) => selectedItems.includes(itm._id))
    .reduce((sum, itm) => {
      if (itm.orderType === 'refund') {
        return sum - (itm.refundDetails?.cashToGiveDelivery || 0);
      } else {
        return sum + (itm.deliveryCashCollected || 0);
      }
    }, 0)
  : 0;


  return (
    <div className="px-2">
      <div className="w-full justify-end items-center flex my-2 px-2">
          <button onClick={() => setShouldRefetch(true)} className="border-2 bg-green-100 rounded border-black p-2">
            <HiRefresh />
          </button>
      </div>
      <div className="flex gap-5 mb-4">
        <div className="flex flex-col items-start justify-center gap-1">
          <label htmlFor="orderType" className="p-1 text-sm font-medium text-gray-90">Order Type</label>
          <select
            value={selectedOrderType}
            name="orderType"
            onChange={handleOrderTypeChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 min-w-[150px] focus:border-primary-600 p-2.5"
            >
            <option value="">All</option>
            <option value="new">New</option>
            <option value="refund">Refund</option>
          </select>
        </div>
        <div className="flex flex-col items-start justify-center gap-1">
          <label htmlFor="PaymentStatus" className="p-1 text-sm font-medium text-gray-90">Payment Status</label>
          <select
            value={selectedPaymentStatus}
            name="PaymentStatus"
            onChange={handlePaymentStatusChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 min-w-[150px] focus:border-primary-600 p-2.5"
            > 
            {paymentFilters.map((filter) => (
              <option key={filter.value} value={filter.value}>
                {filter.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <span className="flex items-center justify-between mb-4">
        <h5 className="mt-2 ml-2 text-[18px] font-semibold">Total: {totalReceivable} {!salesData[0] ? "" : (currencyMapper[salesData[0]?.StoreID?.country] || 'JOD')}</h5>
        <div className="flex items-center">
          <button
            className={`mr-2 ${selectedItems.length === 0 ? 'bg-stone-400' : 'bg-blue-500 hover:bg-blue-400'} border rounded text-white px-2 text-[14px] py-2`}
            onClick={updatePaymentStatusAction}
            disabled={selectedItems.length === 0}
          >
            Update
          </button>
          {selectedPaymentStatus === "pendingPaymentConfirmation" && (
            <button
              className={`${selectedItems.length === 0 ? 'bg-stone-400' : 'bg-green-500 border-blue-500 hover:bg-green-400'} border-2 rounded text-white px-2 text-[16px] py-[9px]`}
              onClick={() => {
                setPaidModalOpen(true);
              }}
              disabled={selectedItems.length === 0}
            >
              <FaCheckDouble />
            </button>
          )}
        </div>
      </span>
      <div className="overflow-x-auto">
        <span>
        <input
          type="checkbox"
          onChange={handleSelectAll}
          checked={selectAll}
          className="m-2 form-checkbox h-5 w-5 text-blue-500 border-blue-500 rounded border-2"
        />
        <b>Select All</b>
        </span>
        <table className="bg-white min-w-full divide-y-2 divide-gray-200 text-sm overflow-x-auto">
          {updatePaymentStatusOpen && (
            <UpdatePaymentStatusModal
              modalOpen={updatePaymentStatusOpen}
              ordrID={selectedItems}
              setModalOpen={setUpdatePaymentStatusOpen}
              setShouldRefetch={setShouldRefetch}
              selectedOrders={selectedOrders}
            />
          )}
          {paidModalOpen && (
            <MarkAsPaidModal
              orderIDs={selectedItems}
              passwordModalOpen={paidModalOpen}
              setPasswordModalOpen={setPaidModalOpen}
              setShouldRefetch={setShouldRefetch}
            />
          )}

          <thead className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
            <tr>
              <td>
                
              </td>
              <td>ID</td>
              <td>Driver Name</td>
              <td className="text-center">Payment</td>
              <td className="text-center">Total Receivable</td>
              <td className="text-start">Type</td>
              <td className="text-center">Actions</td>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {loading ? (
              <tr>
                <td colSpan="7" className="text-center py-4">Loading...</td>
              </tr>
            ) : salesData.length > 0 ? (
              salesData.map((itm, idx) => (
                <tr key={idx} className="hover:bg-gray-200 transition-all cursor-pointer">
                  <td className="pl-2">
                    {itm.orderType !== 'refund' && <input
                      type="checkbox"
                      value={itm._id}
                      onChange={(e) => handleCheckboxChange(e, itm)}
                      className="form-checkbox h-5 w-5 text-blue-500 border-blue-500 rounded border-2"
                      checked={selectedItems.includes(itm._id)}
                    />}
                  </td>
                  <td>{itm?.manualOrderId || itm?.orderID}</td>
                  <td>{itm.deliveryPerson}</td>
                  <td className="text-center">{paymentStatusMapper[itm.paymentStatus]}</td>
                  <td className="text-center">{-itm.refundDetails?.cashToGiveDelivery || itm.deliveryCashCollected} {currencyMapper[itm?.StoreID?.country] || 'JOD'}</td>
                  <td className="capitalize">
                    <span className={`${itm.orderType === 'refund' ? "bg-yellow-300 font-semibold" : ""} p-1 rounded`}>
                      {itm.orderType}
                    </span>
                  </td>
                  <td className="flex items-center justify-center">
                    <Link to={`/${itm.orderType === 'refund' ? 'salesRefundDetails' : 'salesOrderDetails'}/${itm._id}`}>
                      <button className="bg-blue-500 border-blue-500 border-2 rounded text-white p-2 hover:bg-white hover:text-blue-500">
                        View
                      </button>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center py-4">No results found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end items-center mt-2 space-x-4">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className="bg-white shadow-md text-gray-800 font-bold py-1 px-3 rounded-md disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-white hover:bg-gray-100"
        >
          {"<"}
        </button>
        <span className="text-gray-700">
          Page {totalPages > 0 ? currentPage : 0} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="bg-white shadow-md text-gray-800 font-bold py-1 px-3 rounded-md disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-white hover:bg-gray-100"
        >
          {">"}
        </button>
      </div>
    </div>
  );
};
