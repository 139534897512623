import { useEffect, useState } from "react";
import CustomModal from "../../../components/common/Modal"
import { RefundOrderItemsTable } from "../../sales/components/RefundOrderItemsTable";
import { RefundItemModal } from "../../sales/components/RefundItemModal";


export const UpdateRefundStatusModal = ({
  modalOpen,
  setModalOpen,
  order,
  setShouldRefetch,
}) => {

  const statusMapper = {
    fulfilled: "Fulfilled",
    deliveryPostponed: "Delivery Postponed",
    cancelled:"Cancelled"
  }

  const deliveryTimeModes = {
    anytime: 'Any Time of the day',
    timeRange: 'From-To',
    specificTime:'Specific Time'
  }
  const [scannerOpen, setScannerOpen] = useState(false);
  const [scannedItems, setScannedItems] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [deliveryTimeMode, setDeliveryTimeMode]=useState('anytime')
  const [data, setData] = useState({
    productPiecesIDs:[],
    Status: undefined,
    preferredDeliveryDate: "",
    preferredDeliveryTime: {anytime:true},
    notes:"",
    resolvingNote:"",
    cancellationReason:""
  });
  
  useEffect(() => {
    if (order) { 
      if(scannedItems.length > 0) setData(prev => ({...prev,productPiecesIDs:scannedItems.map(itm=>itm._id)}))
      const totalRequestedQty = order.products.reduce((sum, itm) => sum + itm.quantity, 0);
      if (scannedItems.length === totalRequestedQty) setIsDone(true);
      else if (isDone) setIsDone(false);
    }
  },[scannedItems, isDone, order])

  const handleClosing = () =>{
    setModalOpen(false)
    setData({
      productPiecesIDs:[],
      Status: undefined,
      preferredDeliveryDate: "",
      preferredDeliveryTime: {anytime:true},
      notes:"",
      resolvingNote:"",
      cancellationReason:""
    });
    setScannedItems([])
  }
  const markAsOutForDelivery = async (e) => {
    e.preventDefault();
    const dataClone = JSON.parse(JSON.stringify(data));

    if (!dataClone.Status) return alert("Please select the order status");
    if (dataClone.Status === "deliveryPostponed") {
      if (deliveryTimeMode === 'specificTime' && !dataClone.preferredDeliveryTime.time) {
        return alert("Please specify the specific time");
      }
    
      if (deliveryTimeMode === 'timeRange') {
        if (!dataClone.preferredDeliveryTime.from) {
          return alert("Please specify the time range start time");
        }
    
        if (!dataClone.preferredDeliveryTime.to) {
          return alert("Please specify the time range end time");
        }
      }
    }
    const user = localStorage.getItem('user');
      let parsedUser
      if (user) {
        parsedUser = JSON.parse(user);
        console.log(parsedUser, 'parsedUser');
      } else {
        console.log('No user found in local storage');
      }
    if (dataClone.Status !== "deliveryPostponed") {
      delete dataClone.preferredDeliveryDate
      delete dataClone.preferredDeliveryTime
    };
    if(!dataClone.resolvingNote.length) delete dataClone.resolvingNote
    if(!dataClone.notes.length) delete dataClone.notes
    if (dataClone.Status !== 'cancelled') delete dataClone.cancellationReason

    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/refund/resolveOutForDelivery`, {
        method: "PATCH",
        body:JSON.stringify({...dataClone,orderID:order._id, newUserID: parsedUser._id}),
        headers: {
          "Content-Type":"application/json"
        }
      });
      const result = await res.json()
      if (!res.ok) throw (result.message || 'An Error Occurred');
      alert(`Order is ${statusMapper[dataClone.Status]}`);
      setShouldRefetch((prev) => !prev);
      handleClosing()      
    } catch (e) {
      console.log(e);
      alert(e);
    }
  }
    
  return (
    <CustomModal modalOpen={modalOpen} setModalOpen={setModalOpen} title={"Update Status"} lgWidth={650} mdWidth={500}>
      <div className="flex flex-col items-start justify-center">
        <h2 className="text-xl mb-4">Status</h2>
        <form className="flex flex-col w-full items-start justify-center">
          <div className="flex flex-col w-full justify-center items-start">
            <div className=" w-full flex justify-between items-center pr-2 mb-2" onClick={()=>setData((prev)=>({...data, Status:"fulfilled"}))}>
              <div className="">
              <input  type="radio" value={"fulfilled"} defaultChecked={data.Status === "fulfilled"} name="Status" id="fulfilled" className="mr-3"/>
              <label htmlFor="fulfilled">{statusMapper.fulfilled}</label>
              </div>
              <button 
                type="button"
                className="py-1 px-2 bg-[#14ae5c] text-white text-xs rounded-lg font-semibold hover:brightness-[1.2] disabled:bg-gray-300 disabled:cursor-not-allowed disabled:brightness-100" 
                disabled={data.Status !== "fulfilled" || isDone}
                onClick={()=>setScannerOpen(!scannerOpen)}
              >
                Scan
              </button>
            </div>
            {data.Status === "fulfilled" && (
              <div className="flex flex-col items-start justify-center w-full ml-3">
                {scannerOpen && <RefundItemModal modalOpen={scannerOpen} products={order.products} originalProducts = {order.originalOrderId.products} scannedItems={scannedItems} setModalOpen={setScannerOpen} setScannedItems={setScannedItems} />}
                <RefundOrderItemsTable products={order.products} scanned={scannedItems} setScanned={setScannedItems} /> 
              </div> 
            )}
            <div className="flex justify-start items-center mb-2" onClick={()=>setData((prev)=>({...prev, Status:"deliveryPostponed"}))}>
              <input  type="radio" value={"deliveryPostponed"} defaultChecked={data.Status === "deliveryPostponed"} name="Status" id="deliveryPostponed" className="mr-3"/>
              <label htmlFor="deliveryPostponed">{statusMapper.deliveryPostponed}</label>
            </div>

            {data.Status === "deliveryPostponed" && (
              <>
                <div className="w-full flex flex-col col-span-12 items-start justify-center ml-3 mb-3">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  New Delivery Date
                </label>
                <input
                  type="date"
                  name="preferredDeliveryDate"
                  value={data.preferredDeliveryDate}
                  onChange={(e) =>
                    setData((prev)=>({...prev, preferredDeliveryDate:e.target.value}))
                  }
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                />
                </div>
                
                <div className="w-full flex flex-col col-span-12 items-start justify-center ml-3 mb-2">
                <label className="block mb-2 text-sm font-medium text-gray-900">New Delivery Time</label>

                  {Object.keys(deliveryTimeModes).map((val, idx) => (
                    <div className="flex items-center w-100" key={idx}>
                    <input
                      id="time-check"
                      name="time-mode"
                      type="radio"
                      defaultChecked={deliveryTimeMode === val}
                        onClick={() => {
                          setDeliveryTimeMode(val);
                          if (val === 'anytime') setData(prev => ({ ...prev, preferredDeliveryTime: { anytime: true } }))
                          if(val==='timeRange') setData(prev => ({ ...prev, preferredDeliveryTime: { from:"", to:"" } }))
                          if(val==='specificTime') setData(prev => ({ ...prev, preferredDeliveryTime: { time:"" } }))
                        }}
                      />
                    <label className="ml-2" htmlFor="time-check">{deliveryTimeModes[val]}</label>
                      </div>
                  ))}
                {deliveryTimeMode ==='specificTime' && <div className="col-span-12">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Preferred Delivery Time
                  </label>
                  <input
                    type="time"
                    name="specificTime"
                    value={data.preferredDeliveryTime.time}
                    onChange={(e) =>
                      setData(prev => ({ ...prev, preferredDeliveryTime: { time: e.target.value } }))
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  />
                </div>}
                {deliveryTimeMode ==='timeRange' && <div className="col-span-12">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    From
                  </label>
                  <input
                    type="time"
                    name="from"
                    value={data.preferredDeliveryTime.from}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    onChange={(e)=>setData(prev => ({ ...prev, preferredDeliveryTime: { ...prev.preferredDeliveryTime, from:e.target.value } }))}
                  />
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    To
                  </label>
                  <input
                    type="time"
                    name="to"
                    value={data.preferredDeliveryTime.to}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    onChange={(e)=>setData(prev => ({ ...prev, preferredDeliveryTime: { ...prev.preferredDeliveryTime, to:e.target.value } }))}
                  />
                </div>}          
                </div>
              </>
            )}
            <div className="flex justify-start items-center" onClick={()=>setData((prev)=>({...prev, Status:"cancelled"}))}>
              <input  type="radio" value={"cancelled"} defaultChecked={data.Status === "cancelled"} name="Status" id="cancelled" className="mr-3"/>
              <label htmlFor="cancelled">{statusMapper.cancelled}</label>
            </div>
          </div>
          {data.Status === "cancelled" && <div className="flex w-full items-start justify-center mt-4 flex-col">
            <label>Cancellation Reason</label>
            <textarea value={data.cancellationReason} required={data.Status === "cancelled"? true: ""} onChange={(e)=>setData(prev=>({...prev, cancellationReason:e.target.value}))}  className="w-full rounded"/>
          </div>}
          <div className="mt-4 h-px my-4 bg-gray-200 border-t-1 border-black dark:bg-gray-700 w-full" ></div>
          <div className="flex w-full items-start justify-center mt-4 flex-col">
            <label>Notes</label>
            <textarea value={data.resolvingNote} onChange={(e)=>setData(prev=>({...prev, resolvingNote:e.target.value}))}  className="w-full rounded"/>
          </div>
        <div className="w-full flex justify-between items-center mt-4">
          <button className="rounded-lg bg-red-500 hover:bg-red-300 px-6 py-1 text-white" type="button" onClick={handleClosing}>
            Cancel
          </button>
          <button className="rounded-lg bg-green-500 hover:bg-green-300 px-6 py-1 text-white" type="submit" onClick={markAsOutForDelivery}>
            Submit
          </button>
        </div>
        </form>
      </div>

    </CustomModal>
  )
}