export const handlePrintClick = async (selectedItems) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/ordersLabels`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderIDs: [...selectedItems] }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch PDF');
      }
  
      const pdfBuffer = await response.arrayBuffer(); 
      const blob = new Blob([pdfBuffer], { type: 'application/pdf' }); 
      const url = window.URL.createObjectURL(blob); 
      window.open(url, '_blank');
      
    } catch (error) {
      console.error('Error:', error);
    }
  };