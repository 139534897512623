import { useRef, useState } from "react";
import * as XLSX from 'xlsx';
import { Dropdown } from "../../components/common/dropdowns/Dropdown";
import ProductItem from "./components/PurchaseProduct";
export default function AddPurchaseDetails({
  products,
  authContext,
  stores,
  expenses
}) {
  const [purchase, setPurchase] = useState({
    userID: authContext.user,
    storeID: "",
    products: [{ productID: "", quantity: "", expenses: [] }],
  });
  const cancelButtonRef = useRef(null);

  // Handling Input Change for purchase details
  const handleInputChange = (key, value) => {
    setPurchase({ ...purchase, [key]: value });
  };

  // Add new product section
  const addProduct = () => {
    setPurchase({
      ...purchase,
      products: [
        ...purchase.products,
        { productID: "", quantity: "", expenses: [] }
      ]
    });
  };

  // POST Data
  const addSale = () => {
    const purchaseDuplicate = JSON.parse(JSON.stringify(purchase));
    purchaseDuplicate.products.forEach(itm => delete itm.name);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/purchase/add`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(purchaseDuplicate),
    })
      .then((result) => {
        alert("Purchase ADDED");
      })
      .catch((err) => console.log(err));
  };

  // Handle file upload and parse the Excel file
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = "PO";
      const sheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(sheet);

      const newProducts = [];
      json.forEach(row => {
        const { productID, expenseID, expenseAmount,quantity } = row;
        let product = newProducts.find(p => p.productID === productID);
        if (!product) {
          product = { productID, quantity, expenses: [] }; // Assuming quantity is 1 for simplicity
          newProducts.push(product);
        }
        product.expenses.push({ expenseID, cost: expenseAmount });
      });
      
      setPurchase(prev => ({
        ...prev,
        products: [...newProducts]
      }));
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <>
      <form action="#">
        <div className="grid gap-4 mb-4 sm:grid-cols-2">
          <Dropdown
            label="Store"
            value={purchase.storeID}
            onChange={(e) => handleInputChange("storeID", e.target.value)}
            options={stores}
            placeholder="Select Store"
          />
        </div>
        <div className="grid grid-cols-12 gap-2">
          {(!purchase.storeID || !purchase.storeID.length) && <p className="text-red-500 font-bold">Select a store first</p>}
          {purchase.storeID && purchase.storeID.length && purchase.products.map((product, productIndex) => (
            <ProductItem
              product={product}
              productIndex={productIndex}
              products={products}
              expenses={expenses}
              purchase={purchase}
              setPurchase={setPurchase}
              store={stores.find(itm=>itm._id === purchase.storeID)}
            />
          ))}
        </div>
        <button
          type="button"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-4"
          onClick={addProduct}
        >
          Add Product
        </button>
      </form>
      <input
        type="file"
        accept=".xlsx"
        onChange={handleFileUpload}
        className="mb-4"
      />
      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
          onClick={addSale}
        >
          Add
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          ref={cancelButtonRef}
        >
          Cancel
        </button>
      </div>
    </>
  );
}