import { useState } from "react";
import { CountrySelect } from "../../../components/common/countrySelect/CountrySelect";
import CitySelect from "../../../components/common/citySelect/CitySelect";
import { InputField } from "../../../components/common/InputFields/InputField";
import { AddressInput } from "../../../components/common/InputFields/AddressInput";
import { DeliveryTimeSelection } from "./DeliveryTImeComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import PhoneNumberInput from "../../../components/common/phoneInput/PhoneInput";

export const LeftSide = ({order, setOrder, setDateError, setTimeError, id, userInfo, setUserInfo, saleGeneralInfo, setSaleGeneralInfo, deliveryTimeMode, setDeliveryTimeMode}) =>{

    const [customerID, setCustomerID] = useState();
    const [newInsertedAddress, setNewInsertedAddress] = useState("");
    const [isNewAddress, setIsNewAddress] = useState(true);
    const [phoneNumWarning,setPhoneNumWarning]=useState(false);
    
    const getUserByPhone = async (e) => {
    e.preventDefault();
    if (!userInfo.phone || phoneNumWarning) return alert("Please enter a valid phone number to check!");
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customer?phone=${userInfo.phone}`);
        const { customer } = await response.json();
        setUserInfo((prev) => ({
        ...prev,
        fullName: customer?.fullName || '',
        addresses: customer?.addresses || [],
        country: customer?.country || order?.country,
        city: customer?.city || order?.city,
        instagram: customer?.instagram || ""
        }));
        setCustomerID(()=>customer?._id || undefined)
    } catch (e) {
        alert("something went wrong trying to check the user by phone, Please contact the tech team");
    }
    };
    
    const handleRadioChange = (value) => {
        setSaleGeneralInfo((prev)=>({...prev, deliveryAddress:value}));
    };

    const handleAddressChange = (index, value) => {
    const updatedAddresses = [...userInfo.addresses];
    updatedAddresses[index] = value;
    setSaleGeneralInfo((prev) => ({
        ...prev,
        deliveryAddress: value
    }));
    };
    const handleCountryChange = (selectedOption) => {
        setOrder((prevOrder) => ({
          ...prevOrder,
          country: selectedOption,
          city:{}
        }));    
        setUserInfo((prev)=>({
          ...prev,
          country: selectedOption,
          city:{}
        }))        
    };
    
    const handleCityChange = (selectedOption) => {
    setOrder((prevOrder) => ({
        ...prevOrder,
        city: selectedOption
    }));    
    setUserInfo((prev)=>({
        ...prev,
        city: selectedOption
    }))
    };
    const handleInputChange = (setter, name, value) => {
        if (name === "phone") {
            if (value.length < 12) {
                setPhoneNumWarning(true);
            } else {
                setPhoneNumWarning(false);
            }
    
            if (value.length > 12) {
                return;
            }
        }
        setter((prev) => ({ ...prev, [name]: value }));
    };
    const resetCustomerID = ()=>{
        setCustomerID(undefined);
        setUserInfo((prev) => ({
          fullName: "",
          phone: "",
          addresses: [],
          country:{},
          city:{}
        }))
    }

    const handleDateChange = (date) => {
        const today = new Date();
        if (date < today.setHours(0, 0, 0, 0)) {
          setDateError(true); 
        } else {
          setDateError(false); 
        }
        handleInputChange(setSaleGeneralInfo, 'preferredDeliveryDate', date);
      };
    
    const isToday = (date) => {
    const today = new Date();
    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    );
    };
    
    const isTomorrow = (date) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return (
        date.getDate() === tomorrow.getDate() &&
        date.getMonth() === tomorrow.getMonth() &&
        date.getFullYear() === tomorrow.getFullYear()
    );
    };

    const getDisplayDate = () => {
        const { preferredDeliveryDate } = saleGeneralInfo;
        
        if (!preferredDeliveryDate) return null;

        const orderDate = new Date (preferredDeliveryDate)
        orderDate.setHours(0,0,0,0)
        
        const today = new Date();
        today.setHours(0, 0, 0, 0); 
        
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        
        if (orderDate.getTime() === today.getTime()) {
          return "Today";
        }else if (orderDate.getTime() === tomorrow.getTime()) {
          return "Tomorrow";
        } else return null
    };
    
    return(
        <>
        <div className="lg:col-span-3 col-span-12">
            <h3 className="font-bold mb-3">Customer</h3>
            <div className="grid grid-cols-12 gap-2 mb-2">
              <div className="col-span-10">
                <PhoneNumberInput
                    value={userInfo.phone}
                    label={'Phone'}
                    required
                    onChange={(value) =>{
                            setUserInfo((prev) => ({ ...prev, phone: value }))
                            if (customerID) {
                                resetCustomerID();
                            }
                        }
                    }
                    setPhoneNumWarning={setPhoneNumWarning}
                />
              </div>
              <div className="col-span-2 self-end">
                <button
                  onClick={getUserByPhone}
                  className="bg-blue-400 text-white px-2 py-2 rounded cursor-pointer self-end">
                  Check
                </button>
              </div>
              {phoneNumWarning&&<p className=" mb-2 text-red-700 col-span-12 font-semibold">Number should be 12 digits</p>}
            </div>
            <div className="grid gap-4 mb-4 grid-cols-12">
                <div className="col-span-12 mr-0">
                <InputField label="Full Name"
                    type="text"
                    required
                    name="fullName"
                    value={userInfo.fullName}
                    onChange={(e) => handleInputChange(setUserInfo, e.target.name, e.target.value)}
                    placeholder="John Doe"
                    disabled={id ? true : false} 
                />
                </div>
                <div className="col-span-12 mr-0">
                <InputField label="Instagram"
                    type="text"
                    required
                    name="instagram"
                    value={userInfo.instagram}
                    onChange={(e) => handleInputChange(setUserInfo, e.target.name, e.target.value)}
                    placeholder="John-Doe" 
                    disabled={id ? true : false} 
                />
                </div>
                {userInfo.addresses.map((itm, idx) => (
                        <AddressInput
                        key={idx}
                        index={idx}
                        value={itm}
                        selectedAddress={saleGeneralInfo.deliveryAddress}
                        handleAddressChange={handleAddressChange}
                        handleRadioChange={handleRadioChange}
                        setIsNewAddress={setIsNewAddress}
                        />
                ))}
                <AddressInput isNew = {true}
                    value={newInsertedAddress}
                    selectedAddress={saleGeneralInfo.deliveryAddress}
                    handleRadioChange={handleRadioChange}
                    required={userInfo.addresses.length===0}
                    onInputChange={(value) => {
                    if (isNewAddress) {
                        handleInputChange(setSaleGeneralInfo, 'deliveryAddress', value);
                    }
                    setNewInsertedAddress(value);
                    }}
                />
                <div className="col-span-12">
                    <CountrySelect onChange={handleCountryChange} value={userInfo.country}/>
                </div>
                <div className="col-span-12">
                    <CitySelect onChange={handleCityChange} cityValue={userInfo?.city} country={userInfo.country}/>
                </div>
                <div className="col-span-12">
                    <label className="block mb-2 text-sm font-medium text-gray-900">Preferred Delivery Date</label>
                    <DatePicker
                    selected={saleGeneralInfo.preferredDeliveryDate }
                    className="rounded !w-full"
                    showIcon
                    icon={<FaCalendarAlt />}
                    name="preferredDeliveryDate"
                    onChange={handleDateChange}
                    dateFormat={"dd/MM/yyyy"}
                    placeholderText="dd/mm/yyyy"
                    shouldCloseOnSelect
                    />
                </div>
                {getDisplayDate() && <div className="col-span-12">
                    <span className="px-2 py-[3px]  rounded-md text-white font-semibold bg-green-400">{getDisplayDate()}</span>
                </div>}
                <DeliveryTimeSelection deliveryTimeMode={deliveryTimeMode}
                    setDeliveryTimeMode={setDeliveryTimeMode}
                    setSaleGeneralInfo={setSaleGeneralInfo}
                    saleGeneralInfo={saleGeneralInfo}
                    updateNestedState={handleInputChange}
                    setTimeError= {setTimeError}
                />
            </div>
          </div>
        </>
    )
}