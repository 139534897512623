
export const SalesOrderItemsTable = ({ order, scanned, setScanned }) => {
  const calculateScanned = (id) => scanned.filter(itm=>itm.productID._id === id).length

  const getScannedItemsForProduct = (prID) => scanned.filter(itm => itm.productID._id === prID);

  const handleRemoveScannedItem = (itmID) => {
    setScanned((prev)=>prev.filter(itm => itm._id !== itmID));
  }
  console.log(order.products, 'order.products')
  return (
    <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
    <thead className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
      <tr>
        <td>Image</td>
        <td>Product</td>
        <td>Qty</td>
      </tr>
    </thead>
    <tbody className="divide-y divide-gray-200">
      {order.products.map((itm, idx) => {
        return (
          <>
            <tr>
              <td className="whitespace-nowrap px-4 py-2">
                    {itm.productID.image ? (
                      <img
                        src={itm.productID.image}
                        alt="Uploaded"
                        className="w-24 h-24 object-cover"
                      />
                    ) : (
                      <span>No image</span>
                    )}
              </td>
              <td>{itm.name}</td>
              <td className="text-center flex flex-col justify-start">{calculateScanned(itm.productID._id)}/{itm.quantity}
              </td>
            </tr>
            <br />
            {getScannedItemsForProduct(itm.productID._id).map(itm => (
                <div className="flex justify-between pl-3">
                <p>{itm._id}</p>
                {order.Status === "pending" && <span className="text-red-500 text-[30px] cursor-pointer" onClick={()=>handleRemoveScannedItem(itm._id)}>-</span>}
                </div>
              ))}
          </>
        )
      })}
    </tbody>
  </table>
  )
}