const Cities = 
  {
    'JO': [
      "Ajloun",
      "Amman",
      "Aqaba",
      "Irbid",
      "Jerash",
      "Karak",
      "Madaba",
      "Ma'an",
      "Mafraq",
      "Salt",
      "Tafila",
      "Zarqa"
    ]
 ,
    'IQ': [
      "Al Diwaniyah",
      "Amarah",
      "Baghdad",
      "Baqubah",
      "Basra",
      "Dihok",
      "Erbil",
      "Hillah",
      "Karbala",
      "Kirkuk",
      "Kut",
      "Mosul",
      "Najaf",
      "Nasiriyah",
      "Ramadi",
      "Samawah",
      "Sulaymaniyah",
      "Tikrit"
    ]
  ,
    'PS': [
      "Ashdod",
      "Ashkelon",
      "Bat Yam",
      "Beersheba",
      "Beit Shemesh",
      "Bethlehem",
      "Bnei Brak",
      "Deir Al-Balah",
      "Gaza",
      "Haifa",
      "Hadera",
      "Hebron",
      "Herzliya",
      "Holon",
      "Jenin",
      "Jericho",
      "Jerusalem",
      "Kfar Saba",
      "Khan Yunis",
      "Lod",
      "Mansoura",
      "Modi'in-Maccabim-Re'ut",
      "Nablus",
      "Netanya",
      "North Gaza",
      "Petah Tikva",
      "Qalqiliya",
      "Ramallah",
      "Ramat Gan",
      "Rafah",
      "Rehovot",
      "Rishon LeZion",
      "Salfit",
      "Tel Aviv",
      "Tubas",
      "Tulkarm"
    ]
  ,
    'EG': [
      "Alexandria",
      "Arish",
      "Aswan",
      "Asyut",
      "Banha",
      "Beni Suef",
      "Cairo",
      "Damanhur",
      "Damietta",
      "Faiyum",
      "Giza",
      "Hurghada",
      "Ismailia",
      "Kafr El Sheikh",
      "Kharga",
      "Luxor",
      "Mansoura",
      "Marsa Matruh",
      "Minya",
      "Port Said",
      "Qena",
      "Shibin El Kom",
      "Sohag",
      "Suez",
      "Tanta",
      "Zagazig"
    ]
  }
;


export default Cities