import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { currencyMapper } from "../utils/currency";

function PurchaseDetails() {
  const [purchase, setAllPurchaseData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const itemsPerPage = 10;

  useEffect(() => {
    fetchPurchaseData();
  }, [currentPage]);

  const fetchPurchaseData = (searchName = "") => {
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/purchase?name=${searchName}&limit=${itemsPerPage}&page=${currentPage}`
    )
      .then((response) => response.json())
      .then((data) => {
        setAllPurchaseData(data.purchases);
        const total = data.total || 0; // Handle the case where total is 0 or undefined
        setTotalPages(total > 0 ? Math.ceil(total / itemsPerPage) : 0);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setCurrentPage(1); 
      fetchPurchaseData(searchTerm);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="col-span-12 lg:col-span-10  flex justify-center">
      <div className="flex flex-col gap-5 w-11/12">
        {/* Search Input */}
        <div className="mb-4">
          <label htmlFor="search" className="block text-md font-medium text-gray-700">
            Search By Product Name
          </label>
          <input
            type="text"
            id="search"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Type product name..."
            className="mt-1 block p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>

        {/* Table */}
        <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 ">
          <div className="flex justify-between pt-5 pb-3 px-3">
            <div className="flex gap-4 justify-center items-center ">
              <span className="font-bold">Purchase Details</span>
            </div>
            <div className="flex gap-4">
              <Link to="/purchase-details/add">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 text-xs  rounded"
                >
                  Add Purchase
                </button>
              </Link>
            </div>
          </div>
          <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
            <thead>
              <tr>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Done by
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Store
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  # of Products inc.
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Purchase Date
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Total Cost
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {loading ? (
                <tr>
                  <td colSpan="5" className="text-center py-4">
                    Loading...
                  </td>
                </tr>
              ) : purchase?.length > 0 ? (
                purchase.map((element) => (
                  <tr
                    key={element._id}
                    className="hover:cursor-pointer hover:bg-gray-200 w-full"
                    title="Click to view full details"
                    onClick={() => navigate(`/purchase-details/${element._id}`)}
                  >
                    <td className="whitespace-nowrap px-4 py-2  text-gray-900">
                      {element.userID?.email}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2  text-gray-900">
                      {element.storeID?.name}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {element.productCount}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {new Date(element.createdAt).toLocaleDateString('en-GB', { timeZone: 'Asia/Amman' }) === 
                      new Date().toLocaleDateString('en-GB', { timeZone: 'Asia/Amman' })
                        ? "Today"
                        : new Date(element.createdAt).toLocaleDateString('en-GB', { timeZone: 'Asia/Amman' })}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {currencyMapper[element?.storeID.country] || 'JOD'} {element.totalCost}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center py-4">
                    No results found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* Pagination Controls */}
        <div className="flex justify-end items-center mt-2 space-x-4">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1 || totalPages === 0}
            className="bg-white shadow-md text-gray-800 font-bold py-1 px-3 rounded-md disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-white hover:bg-gray-100"
          >
             {'<'}
          </button>
          <span className="text-gray-700 ">
            Page {totalPages > 0 ? currentPage : 0} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages || totalPages === 0}
            className="bg-white shadow-md text-gray-800 font-bold py-1 px-3 rounded-md disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-white hover:bg-gray-100"
          >
            {'>'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default PurchaseDetails;
