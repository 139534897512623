import React from "react";
import { PasswordLayer } from "../../../components/PasswordLayer";
import CustomModal from "../../../components/common/Modal";
export const MarkAsPaidModal = ({
  orderIDs, passwordModalOpen, setPasswordModalOpen, setShouldRefetch
}) => {
    const hanldeUpdateStatus =() =>{
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/updatePaymentStatus`, {
            method: "PATCH",
            headers: {
              "Content-type": "application/json",
          },
            body:JSON.stringify({paymentStatus:"paid", orderIDs:orderIDs})
          })
            .then((result) => {
              alert("Order is Paid");
              setPasswordModalOpen(false);
              setShouldRefetch(prev=>!prev)
            })
            .catch((err) => console.log(err));
    }

  return (
    <CustomModal modalOpen={passwordModalOpen} setModalOpen={setPasswordModalOpen} title={'Mark As Paid'}>
        <PasswordLayer setUnlocked={hanldeUpdateStatus} action={'markAsPaid'}/>
    </CustomModal>
  )
}