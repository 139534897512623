import { useEffect, useState } from "react";
import { Dropdown } from "../../../../components/common/dropdowns/Dropdown";
import { ProductItem } from "./ProductItem";

export const MiddleSide = ({saleCurrency, order, setOrder, stores, products, originalOrderProducts, selectedSourceId, setSelectedSourceId, saleSources, saleGeneralInfo, setSaleGeneralInfo}) =>{
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [hasChoosenSource, setHasChoosenSource] = useState(false);
    const [hasChoosenStore, setHasChoosenStore] = useState(false);
    const [selectedProductIds, setSelectedProductIds] = useState([]);
    let filteredProducts = [];
    const filteredStoreProducts = selectedProducts?.filter(p => 
      p.availableStock.some(stock => stock.storeID === saleGeneralInfo.StoreID)
    ); 

    
    const fetchProductsBySourceId = async (sourceId) => {
        try {
          setSelectedProductIds([]) //clear the selectedIds on any source/store change
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/get/bysource/${sourceId}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const products = await response.json();
          const transformedProducts = products.map(product => {
            product?.sellingPrices.forEach((ele) => {
              if (ele.sourceId === sourceId) {
                const obj = { ...ele };
                delete product.sellingPrices;
                product = { ...product, sellingPrices: obj };
              }
            });
            return product;
          });
    
          setSelectedProducts(() => (transformedProducts));
          return products;
        } catch (error) {
          console.error('Error fetching products:', error);
          return [];
        }
    };
    
    const handleSellingPriceChange = (field, value) => {
        if(hasChoosenSource){
          setOrder((prevProduct) => ({
            ...prevProduct,
            products: [],
            [field]: value,
          }));
        }
        setSelectedSourceId(() => value);
        if(!hasChoosenSource) setHasChoosenSource(true)
        products.forEach(ele => {
          if (ele.sellingPrices.length) {
            ele.sellingPrices.forEach(product => {
              if (product.sourceId === value) {
                const obj = { ...ele };
                delete obj.sellingPrices;
                filteredProducts.push({ ...obj, sellingPrice: product });
              } 
            });
          }
        });
        setSelectedProducts(() => ([...filteredProducts]));
    };

    const handleProductChange = (index, key, value) => {
      const currentOrderProducts = order.products;
      let orderProduct = currentOrderProducts[index];
      const selectedProduct = selectedProducts.find(itm => itm._id === value);
      const originalProduct = originalOrderProducts && originalOrderProducts?.find(itm => itm.productID === value);
      
      if (key === 'product') {
          orderProduct = {
          ...orderProduct,
          productID: value,
          name: selectedProduct.name,
          price: originalProduct ? originalProduct?.price : selectedProduct.sellingPrices?.price || 0,
          availableStock: selectedProduct.availableStock, // Add this line to attach stock data to the product
          discount: 0,
          isNew: false
          };
          currentOrderProducts[index] = orderProduct;
          setOrder({ ...order, products: currentOrderProducts });
          setSelectedProductIds(currentOrderProducts.map(product => product.productID).filter(id => id));
      }
      
      if (key === 'quantity') {
          const updatedProducts = [...order.products];
          updatedProducts[index] = { ...updatedProducts[index], quantity: value=value>=0 ?Number(value) : 0, discount: 0};
          setOrder({ ...order, products: updatedProducts });
      }

      if (key === 'discount') {
        const updatedProducts = [...order.products];
        updatedProducts[index] = { ...updatedProducts[index], discount: value=value>=0 ?Number(value) : 0};
        setOrder({ ...order, products: updatedProducts });
      }
    };

    const addProduct = () => {
    setOrder({
        ...order,
        products: [
        ...order.products,
        { productID: "", quantity: 0, isNew: true }
        ]
    });
    };

    const handleStoreChange = (e) =>{
      if(hasChoosenStore){
        setOrder((prevProduct) => ({
          ...prevProduct,
          products: []
        }));
      }
      if(!hasChoosenStore) setHasChoosenStore(true)
      setSaleGeneralInfo((prev) => ({ ...prev, StoreID: e.target.value }))
    };

    const deleteProduct = (productIndex) => {
        const updatedProducts = [...order.products];
        updatedProducts.splice(productIndex, 1);
        setOrder({ ...order, products: updatedProducts });
        setSelectedProductIds(updatedProducts.map(product => product.productID).filter(id => id));
    };

    useEffect(() => {
        if (selectedSourceId) {
          fetchProductsBySourceId(selectedSourceId);
        }
      }, [selectedSourceId]); 
           
    return(
        <>
        <div className="col-span-12 lg:col-span-5 gap-2 grid grid-cols-12 border-t-2 border-black lg:border-t-0 lg:border-l-2 lg:pl-3">
            <h3 className="font-bold col-span-12">Order</h3>
            <div className="col-span-6 mr-0">
              <Dropdown
                label="Source"
                required
                value={selectedSourceId}
                onChange={(e) => handleSellingPriceChange('sourceId', e.target.value)}
                options={saleSources}
                placeholder="Select Source"
                />
            </div>
            <div className="col-span-6">
              <Dropdown
                label="Store"
                required
                value={saleGeneralInfo.StoreID}
                onChange={handleStoreChange}
                options={stores}
                placeholder="Select Store"
                />
            </div>
            <div className="col-span-12">
              {order?.products.map((product, productIndex) => (
                <ProductItem
                    saleCurrency={saleCurrency}
                    key={productIndex}
                    product={product}
                    storeID={saleGeneralInfo.StoreID}
                    productIndex={productIndex}
                    handleProductChange={handleProductChange}
                    handleDelete={deleteProduct}
                    options={product.isNew
                      ? filteredStoreProducts.filter(f => !selectedProductIds.includes(f._id) && f.availableStock?.length >= 1)
                      : filteredStoreProducts}
                />
                ))}
            </div>
            <div className="w-full flex justify-end col-span-12 h-14">
                  <button
                    type="button"
                    className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-4 mt-2 px-2"
                    onClick={addProduct}
                    >
                    Add Product
                </button>
            </div>
          </div>
        </>
    )
}