import React from "react";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import AuthContext from "./AuthContext";
import ProtectedWrapper from "./ProtectedWrapper";
import { useEffect, useState } from "react";
import { PasswordProtectedWrapper } from "./wrappers/PasswordProtectedRoute";
import { routes } from "./router/routes";
import 'react-responsive-modal/styles.css';

const App = () => {
  const [user, setUser] = useState("");
  const [loader, setLoader] = useState(true);
  let myLoginUser = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (myLoginUser) {
      setUser(myLoginUser._id);
      setLoader(false);
      // console.log("inside effect", myLoginUser)
    } else {
      setUser("");
      setLoader(false);
    }
  }, [myLoginUser]);

  const signin = (newUser, callback) => {
    setUser(newUser);
    callback();
  };

  const signout = () => {
    setUser(null);
    localStorage.removeItem("user");
  };

  let value = { user, signin, signout };

  if (loader)
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1>LOADING...</h1>
      </div>
    );

  return (
    <AuthContext.Provider value={value}>
      <BrowserRouter>
      <Routes>
    {routes.public.map((route, index) => (
      <Route key={index} path={route.path} element={route.element} />
    ))}
    <Route
      path="/"
      element={
        <ProtectedWrapper>
          <Layout />
        </ProtectedWrapper>
      }
    >
      {routes.protected.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            route.password ? (
              <PasswordProtectedWrapper path={route.path}>
                {route.element}
              </PasswordProtectedWrapper>
            ) : (
              route.element
            )
          }
        />
      ))}
    </Route>
  </Routes>
      </BrowserRouter>
    </AuthContext.Provider>
  );
};

export default App;
