import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { TransferDetailsTable } from "../containers/stockTransfer/components/TransferDetailsTable";
import { ConfirmTransferModal } from "../containers/stockTransfer/components/ConfirmTransferModal";
import { PickTransferItemModal } from "../containers/stockTransfer/components/PickTransferItemModal";
export const StockTransferDetails = () => {
  const navigate = useNavigate();
  const [isDone, setIsDone] = useState(false);
  const { id } = useParams();
  const [transfer, setTransfer] = useState();
  const [scannerOpen, setScannerOpen] = useState(false);
  const [scannedItems, setScannedItems] = useState([]);
  const [confirmTransferModal, setConfirmTransferModal] = useState(false);    
  const fetchStockTransferDetails = async () => {
    try {
      const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/stockTransfers/get/${id}`);
      const transferDetails = await data.json();
      setTransfer(transferDetails);
      
      const allPiecesIDs = [];      
      for (const prod of transferDetails.products) {
        prod.productPieces.forEach(itm => allPiecesIDs.push(itm.productPieceID));
      }
      
      setScannedItems(allPiecesIDs);
    } catch (e) {
      alert("Something went wrong! Please contact the technical team")
    }
  }
  
  useEffect(() => {
    if (transfer) { 
      const totalRequestedQty = transfer.products.reduce((sum, itm) => sum + itm.quantity, 0);
      if (scannedItems.length === totalRequestedQty) setIsDone(true);
      else if (isDone) setIsDone(false);
    }
  },[scannedItems, isDone, transfer])


  useEffect(() => {
    fetchStockTransferDetails();
  }, [])
  
  if (!transfer) return (
    <div className="col-span-12 lg:col-span-10 flex justify-center items-center">
      <h2>Loading...</h2>
    </div>
  )

  return (
    <div className="col-span-12 lg:col-span-10 px-2">
      <div className="flex flex-col w-full">
        <div className="flex justify-between items-center py-2 w-full">
          <h2 className="font-bold inline-flex items-center">
              <IoMdArrowBack className="cursor-pointer" size={20} onClick={()=>navigate(-1)} />
          </h2>
          {transfer.status === "pending" ? 
            <div className="flex items-center justify-start">
                <button disabled={!isDone} className={`${isDone ? "bg-green-500 text-white" : "bg-gray-300 cursor-not-allowed"} rounded px-2 py-1`} onClick={() => setConfirmTransferModal(true)}>Confirm Picking</button>
                <button
                  onClick={()=>setScannerOpen(true)}
                  className={`${!isDone ? "bg-blue-500 text-white" : "bg-gray-300 cursor-not-allowed"} rounded px-2 py-1 ml-3`}
                >
                    Scan
                </button>
            </div>
            :
            <button className={`bg-red-500 text-white rounded px-2 py-1 cursor-not-allowed`}>Make Pending(Soon)</button>
            }
        </div>
        <div className="grid grid-cols-1 bg-gray-200 rounded-md p-6 max-w-[325px]">
            <p className="capitalize"><b>From: </b>{transfer?.fromStoreID?.name}</p>
            <p className="capitalize"><b>To: </b>{transfer?.toStoreID?.name}</p>
            <p className="capitalize"><b>Status: </b>{transfer.status}</p>

        </div>
        <div className="mt-5 bg-white">
          <TransferDetailsTable scanned={scannedItems} fromName={transfer?.fromStoreID?.name} toName={transfer?.toStoreID?.name} transfer={transfer}  /> 
        </div>
      </div>
      {scannerOpen && <PickTransferItemModal modalOpen={scannerOpen} products={transfer.products} scannedItems={scannedItems} setModalOpen={setScannerOpen} setScannedItems={setScannedItems} fromStore={transfer.fromStoreID} />}
      {confirmTransferModal && <ConfirmTransferModal modalOpen={confirmTransferModal} setModalOpen={setConfirmTransferModal} transferID={id} scanned={scannedItems}/>}
    </div>
  )
}