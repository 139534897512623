import { FaTrash } from "react-icons/fa";
import { Dropdown } from "../../../components/common/dropdowns/Dropdown";
import { InputField } from "../../../components/common/InputFields/InputField";

const TransferTable = ({ products, requestedTransfer, setRequestedTransfer, from, to }) => {
  return (
    <table className="bg-white min-w-full text-sm md:text-lg overflow-x-auto">
      <thead className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
        <tr className=" break-words whitespace-normal">
          <td className="text-center p-2">Product</td>
          <td className="text-center p-2">Quantity</td>
          <td className="text-center p-2">Available Stock {from.name && `(${from.name})`}</td>
          <td className="text-center p-2">Available Stock {to.name && `(${to.name})`}</td>
          <td className="text-center p-2"></td>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {requestedTransfer.map((prod, idx) => {
          const fromQty = products.find(p => p._id === prod.productID._id)?.availableStock.find(s => s.storeID === from._id)?.quantity || 0;
          const toQty = products.find(p => p._id === prod.productID._id)?.availableStock.find(s => s.storeID === to._id)?.quantity || 0;
          return (
            <tr key={idx}>
              <td className="text-center p-2">
                <Dropdown
                  value={prod.productID._id}
                  placeholder="Select Product"
                  onChange={e => setRequestedTransfer(prev => prev.map((item, i) => (i === idx ? { ...item, productID: { _id: e.target.value } } : item)))}
                  options={products}
                  disabledOptions={requestedTransfer.map(transfer => transfer.productID._id).filter(id => id !== prod.productID._id)}
                />
              </td>
              <td className="text-center p-2">
                <InputField
                  type="number"
                  name="quantity"
                  value={prod.quantity}
                  onChange={(e) => {
                    let value = Number(e.target.value);
                    if (!isNaN(value) && value >= 0) {
                      value = value > fromQty ? fromQty : value;
                      setRequestedTransfer(prev => prev.map((item, i) => (i === idx ? { ...item, quantity: value } : item)));
                    }
                  }}
                  min={0}
                  max={fromQty}
                  placeholder="Enter Amount"
                  classes="max-w-[150px] mx-auto"
                />
              </td>
              <td className="text-center p-2">{fromQty}</td>
              <td className="text-center p-2">{toQty}</td>
              <td className="text-center p-2">
                <button className="text-red-500" onClick={() => setRequestedTransfer(prev => prev.filter((_, i) => i !== idx))}>
                  <FaTrash />
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TransferTable;
