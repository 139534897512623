import SalesContainer from "../containers/sales/SalesContainer"

export const SalesAdmin = () => {

    return(
        <>
        <SalesContainer isAdmin={true} />
        </>
    )
}
