import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { PAGES_CREDENTIALS } from "../constants/pagesCredentials";

export const PasswordLayer = ({
  setUnlocked,action
}) => {
  const location = useLocation();
  let currentPath = location.pathname.replace('/', '');
  const [showLoginError, setShowLoginError] = useState(false);
  if(action){
    currentPath = action
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const username = e.target.username.value;
    const password = e.target.password.value;
    const canLogin = checkIfCanLogin({ username, password });
    console.log(canLogin)
    if (canLogin) setUnlocked(true);
  }

  const checkIfCanLogin = ({ username, password }) => {
    console.log({currentPath})
    if (PAGES_CREDENTIALS[currentPath].username === username && password === PAGES_CREDENTIALS[currentPath].password) {
      return true;
    }
    setShowLoginError(true)
    return false;
  }

  return (
    <div className=" w-full col-span-12 lg:col-span-10 flex justify-center items-center">
      <div className="form-container w-[320px] h-[400px] border-px border-green-500">
        <form onSubmit={handleSubmit} className="flex flex-col gap-[5px]">
          <label>Username</label>
          <input type="text" name="username" placeholder="Username" />
          <label>Password</label>
          <input type="password" name="password" placeholder="Password" />
          <button className="rounded bg-blue-500 p-2 text-white" type="submit">Login</button>
          {showLoginError && <p className="text-red-500">Wrong Credentials !</p>}
        </form>
      </div>
    </div>
  )
}