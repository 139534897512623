import dayjs from "dayjs";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { LeftSide } from "./components/LeftSide";
import { MiddleSide } from "./components/middleSide/MiddleSide";
import { RightSide } from "./components/RightSide";
import { currencyMapper } from "../../utils/currency";

export default function AddNewOrder({
  addSaleModalSetting,
  products,
  authContext,
  expenses,
  saleSources,
  stores
}) {
  const { id } = useParams();
  const [order, setOrder] = useState({
    userID: authContext.user,
    products: [{ productID: "", quantity: 0, price: 0, name: '', discount:0 }],
    expenses: [],
    deliveryAmount: null,
    notes:"",
    country:{},
    city:{},
    sourceId:""
    });
  const [userInfo, setUserInfo] = useState({ fullName: '', addresses: [], phone: '', country: {}, city:{}, instagram:"" });
  const [saleGeneralInfo, setSaleGeneralInfo] = useState({
    deliveryAddress: "",
    preferredDeliveryDate: "",
    preferredDeliveryTime: {anytime:true},
    StoreID:""
  });
  const [deliveryTimeMode, setDeliveryTimeMode]=useState('anytime')
  const [selectedSourceId, setSelectedSourceId] = useState('');
  const [originalOrderProducts, setOriginalOrderProducts] = useState('');
  const cancelButtonRef = useRef(null);
  const [customerID, setCustomerID] = useState();
  const [errors,setErrors]=useState();
  const [dateError, setDateError] = useState(false); 
  const [timeError, setTimeError] = useState(null); 
  const [noteError, setNoteError] = useState(false); 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const Subtotal = order.products.reduce((acc, product) => {
    const productTotal = (product.price * product.quantity) - product.discount || 0;
    return acc + productTotal;
  }, 0);
  
  const TotalSaleAmount = Subtotal + +order.deliveryAmount;
  useEffect(()=>{
    if(id) fetchOrderByID();
  },[id])

  useEffect(() => {
    let validationErrors = [];
  
    order.products.forEach((product, index) => {

      if (selectedSourceId && !product.productID) {
        validationErrors.push(
          `-Product adding hasn't been completed for product (${index + 1}). Please edit or remove the product.`
        );
      }
  
      if (product.quantity === 0 && product.price > 0) {
        validationErrors.push(
          `-The product "${product.name}" has no quantity. Please change the quantity or remove the product.`
        );
      }
    });
  
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors(null); 
    }
  }, [order]); 
  
  const fetchOrderByID = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/get/${id}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      let order = await response.json()
      const expenses = order.expenses.map((ele)=>{
        return {...ele, _id:ele.expenseID}
      })
      const products = order.products.map((ele) => {
        return {
          name: ele?.productID?.name || ele?.name,
          productID: ele?.productID?._id|| ele?._id,
          price: ele.price,
          quantity: ele.quantity,
          discount: ele.discount,
          sellingPrices: (ele?.productID?.sellingPrices) ? [...ele?.productID?.sellingPrices] : []
        };
      });
      // delete order.products
      let preferredDeliveryTime = { anytime: true };
      if (order.preferredDeliveryTime.time) {
        preferredDeliveryTime = order.preferredDeliveryTime;
        setDeliveryTimeMode('specificTime')
      }
      if (order.preferredDeliveryTime.timeRange) {
        preferredDeliveryTime = order.preferredDeliveryTime.timeRange;
        setDeliveryTimeMode('timeRange')
      }
      setSelectedSourceId(()=> order.sourceId)
      setSaleGeneralInfo(()=>({...saleGeneralInfo, preferredDeliveryDate:dayjs(order.preferredDeliveryDate).format('YYYY-MM-DD'), StoreID:order.StoreID?._id, deliveryAddress:order.deliveryAddress, preferredDeliveryTime}));
      order = {...order, products: [...products]}
      setOrder(() => ({
        ...order,
        expenses,
      }));
      setOriginalOrderProducts(()=>[...products])
      setCustomerID(()=>(order?.customerID._id))
      setUserInfo(order?.customerID)
    } catch (error) {
      console.error('Error fetching products:', error);
      return [];
    }
  };  
  // POST Data
  const addSale = () => {
    let TotalSaleAmount = 0;
    order.products = order.products.map(({ isNew, ...rest }) => rest); // remove the isNew property before calling the api
    let zeroQuantityProducts = [];
    order.products.forEach((ele) => {
      if (ele.quantity === 0) {
        zeroQuantityProducts.push(ele.name);
      }
      TotalSaleAmount += (ele.price * ele.quantity);
      delete ele.availableStock;
    });
      if (!order.notes) setNoteError(true)
      if (zeroQuantityProducts.length > 0) {
        let errorMessage = "The following products cannot have a quantity of zero. Please adjust the quantity before submitting the sale:\n";
        zeroQuantityProducts.forEach((product) => {
            errorMessage += `  - ${product}\n`;
        });
        alert(errorMessage);
        return; 
    }
    const user = localStorage.getItem('user');
    let parsedUser
    if (user) {
      parsedUser = JSON.parse(user);
      console.log(parsedUser, 'parsedUser');
    } else {
      console.log('No user found in local storage');
    }
    if(userInfo.phone.length < 12) return alert("Phone number must start with a country code and must be a valid number") 
    if(userInfo.instagram === "") delete userInfo.instagram
    order.expenses.forEach(ele => TotalSaleAmount += (ele.amount));
    const fullInfo = { ...order, ...saleGeneralInfo, TotalSaleAmount, sourceId:selectedSourceId, newUserID:parsedUser._id };
    if (customerID) 
      { fullInfo.customerID = customerID;
        delete userInfo.fullName
        delete userInfo.instagram
        fullInfo.userInfo = {...userInfo};
      }
    else fullInfo.userInfo = {...userInfo};
    if (dateError) return alert('Error: The selected delivery date cannot be in the past. Please choose a valid date.')
    if (timeError) return alert(timeError)
    if (order.deliveryAmount === null || order.deliveryAmount === "") {
      return alert("Delivery amount is required. Set it to 0 if there's no charge.");
    }
    if (!order.notes) return alert ("Please add a note or simply type 'No Notes' if there are no additional details for this sale order.")
    setIsSubmitting(true);  
    if(!id){
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/add`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(fullInfo),
      })
      .then(async (result) => {
        if (!result.ok) {
          const data = await result.json();
          let errorMessage = data.message || 'Error occurred';
      
          if (data.details) {
            // Check and format products with zero stock
            if (data.details.zeroStockProducts && data.details.zeroStockProducts.length > 0) {
              errorMessage += "\n\nProducts with zero stock:\n";
              data.details.zeroStockProducts.forEach(product => {
                errorMessage += `  Name: ${product.name}\n  Available: ${product.available}\n  Requested: ${product.requested}\n`;
              });
            }
      
            // Check and format products with insufficient stock
            if (data.details.insufficientStockProducts && data.details.insufficientStockProducts.length > 0) {
              errorMessage += "\n\nProducts with insufficient stock:\n";
              data.details.insufficientStockProducts.forEach(product => {
                errorMessage += `  Name: ${product.name}\n  Available: ${product.available}\n  Requested: ${product.requested}\n`;
              });
            }
          }
          alert(errorMessage);
          return;
        }
        alert("Order ADDED");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        alert(`Error: ${err.message}`);  // Display error in alert
      })
      .finally(() => {
        setIsSubmitting(false);
      });
    }
    else{
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/update/order`, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(fullInfo),
      })
      .then(async (result) => {
        if (!result.ok) {
          const data = await result.json();
          let errorMessage = data.message || 'Error occurred';
      
          if (data.details) {
            // Check and format products with zero stock
            if (data.details.zeroStockProducts && data.details.zeroStockProducts.length > 0) {
              errorMessage += "\n\nProducts with zero stock:\n";
              data.details.zeroStockProducts.forEach(product => {
                errorMessage += `  Name: ${product.name}\n  Available: ${product.available}\n  Requested: ${product.requested}\n`;
              });
            }
            // Check and format products with insufficient stock
            if (data.details.insufficientStockProducts && data.details.insufficientStockProducts.length > 0) {
              errorMessage += "\n\nProducts with insufficient stock:\n";
              data.details.insufficientStockProducts.forEach(product => {
                errorMessage += `  Name: ${product.name}\n  Available: ${product.available}\n  Requested: ${product.requested}\n`;
              });
            }
          }
          alert(errorMessage);
          return;
        }
        alert("Order Updated");
      })
      .catch((err) => {
        console.log(err);
        alert(`Error: ${err.message}`);  // Display error in alert
      })
      .finally(() => {
        setIsSubmitting(false);
      });
    }
  };

  const handleRadioChange = (value) => {
    setSaleGeneralInfo((prev)=>({...prev, deliveryAddress:value}));
  };
  
  const selectedStoreObj = stores.find(itm => itm._id === saleGeneralInfo.StoreID);
  const saleCurrency = currencyMapper[selectedStoreObj?.country];

  return (
    <>
      <form action="#">
        <div className="grid grid-cols-12 gap-8 p-2">
          <LeftSide order={order} setOrder={setOrder} setDateError={setDateError} setTimeError ={setTimeError} id={id} userInfo={userInfo} setUserInfo={setUserInfo} handleRadioChange={handleRadioChange} saleGeneralInfo={saleGeneralInfo} setSaleGeneralInfo={setSaleGeneralInfo} deliveryTimeMode={deliveryTimeMode} setDeliveryTimeMode={setDeliveryTimeMode} />
          <MiddleSide order={order} setOrder={setOrder} stores={stores} products={products} originalOrderProducts={originalOrderProducts} selectedSourceId={selectedSourceId} setSelectedSourceId={setSelectedSourceId} saleSources={saleSources} setSaleGeneralInfo={setSaleGeneralInfo} saleGeneralInfo={saleGeneralInfo} saleCurrency={saleCurrency}/>
          <RightSide order={order} expenses={expenses}  setOrder={setOrder} noteError={noteError} setNoteError={setNoteError}/>
        </div>
      </form>
      <div className="flex flex-col justify-center items-start gap-3 px-6 pt-6 pb-3 border-t-2 mt-3 border-gray-600">
        <span className="text-lg">
         <span className="font-bold">Subtotal:</span> {Subtotal} {saleCurrency}
        </span>
      
        <span className="text-lg">
          <span className="font-bold">Delivery Amount:</span> {order.deliveryAmount} {saleCurrency}
        </span>
      
        <span className="text-lg">
          <span className="font-bold">Total Sale Amount:</span> {TotalSaleAmount} {saleCurrency}
        </span>
        {errors && (
          <div>
            {errors.map((error, index) => (
              <p key={index} className="text-red-500 text-sm">
                {error}
              </p>
            ))}
          </div>
        )}
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border-t-2 mt-3 border-gray-600">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto disabled:cursor-not-allowed disabled:bg-gray-300"
          onClick={addSale}
          disabled={errors || isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => addSaleModalSetting()}
          ref={cancelButtonRef}
        >
          Cancel
        </button>
      </div>
    </>
  );
}